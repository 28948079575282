<template>
  <v-container fluid class="pa-6">
    <v-row class="pl-6" style="display: block">
      <p class="headline font-weight-bold black--text mb-0">
        Cours collectifs en replay
        <span class="grey--text font-weight-regular"
          >({{ filteredWebinars.length }})</span
        >
      </p>
      <v-subheader class="pl-0">
        Aller plus loin sur votre projet grâce à nos cours collectifs animés et
        enregistés pour vous 📺
      </v-subheader>
    </v-row>
    <v-row class="px-3 pt-3 pb-5">
      <!-- <v-col cols="6"> -->

      <v-chip-group
      :show-arrows="$vuetify.breakpoint.xs"
        v-model="filters"
        active-class="blue--text text--accent-4"
        class="font-weight-medium"
      >
        <v-chip
          class="mx-1"
          :value="['Marketing', 'Entrepreneuriat', 'SEO', 'Communication', 'Code', 'Questions/Réponses']"
          :class="allCatClass"
        >
          <v-icon small left :color="allColor">mdi-eye</v-icon>
          Tous
        </v-chip>
        <v-chip class="mx-1" value="Questions/Réponses"> Questions/Réponses </v-chip>
        <v-chip class="mx-1" value="Entrepreneuriat"> Entrepreneuriat </v-chip>
        <v-chip class="mx-1" value="Marketing"> Marketing </v-chip>
        <v-chip class="mx-1" value="SEO"> SEO </v-chip>
        <v-chip class="mx-1" value="Communication"> Communication </v-chip>
        <v-chip class="mx-1" value="Code"> Code </v-chip>
      </v-chip-group>

      <!-- </v-col> -->
      <!-- <v-col>
        <v-text-field
          v-model="search"
          hide-details
          prepend-inner-icon="mdi-magnify"
          outlined
          background-color="white"
          color="blue accent-4"
          label="Chercher un webinar"
        ></v-text-field>
      </v-col> -->
    </v-row>
    <v-row justify="start">
      <v-col
        cols="12" md="4"
        v-for="(webinar, index) in filteredWebinars"
        :key="index"
        class="d-flex flex-column my-2"
      >
        <v-skeleton-loader
          type="card"
          :loading="loading"
          class="flex d-flex flex-column"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              class="pa-2 flex d-flex flex-column dashboard-card"
              :to="{ name: 'webinar', params: { id: webinar.id } }"
              flat
            >
              <v-img :src="webinar.picture" max-height="200" class="rounded">
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute>
                    <v-btn
                      fab
                      :to="{ name: 'webinar', params: { id: webinar.id } }"
                      color="blue accent-4"
                      ><v-icon>mdi-play</v-icon></v-btn
                    >
                  </v-overlay>
                </v-fade-transition>
              </v-img>

              <v-card-subtitle class="font-weight-medium black--text pl-1">
                {{ webinar.title }}
              </v-card-subtitle>
              <v-row class="my-1" justify="start" align="start">
                <v-col class="pr-0">
                  <v-chip
                    small
                    style="background: white; color: #88a6c9"
                    color="#88a6c9"
                  >
                    <v-icon left small>mdi-calendar-month</v-icon>
                    {{
                      webinar.date.toDate().toLocaleDateString("fr-FR", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    }}</v-chip
                  >
                </v-col>

                <v-col class="px-0">
                  <v-chip
                    small
                    style="background: white; color: #88a6c9"
                    color="#88a6c9"
                  >
                    <v-icon left small>mdi-timer-outline</v-icon
                    >{{ webinar.length }}</v-chip
                  >
                </v-col>
              </v-row>
              <v-row class="pt-4 pl-2">
                <v-col class="caption new-grey--text" align-self="center">
                  <v-avatar size="30" style="border:2px solid black;">
                    <v-img
                      :src="webinar.animator.pic"
                      v-if="webinar.animator.pic != ''"
                    ></v-img>
                    <v-icon v-else>mdi-account-circle</v-icon>
                  </v-avatar>
                  <span class="pl-2">{{ webinar.animator.name }}</span>
                </v-col>
                <v-col align-self="center">
                  <v-chip outlined small
                    ><v-icon left small>{{
                      getWebinarIcon(webinar.filter)
                    }}</v-icon
                    >{{ webinar.category }}</v-chip
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-hover>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Webinars",
  data() {
    return {
      loading: true,
      filters: ["Marketing", "Entrepreneuriat", "SEO", "Communication", "Code", "Questions/Réponses"],
      search: "",
      initialFilters: [0, 1, 2],
    };
  },
  computed: {
    ...mapState(["webinars"]),
    filteredWebinars: function () {
      return this.webinars.filter((w) => this.filters.includes(w.category));
    },
    allColor: function () {
      let bool =
        this.filters.includes("Questions/Réponses") &&
        this.filters.includes("Marketing") &&
        this.filters.includes("Entrepreneuriat") &&
        this.filters.includes("SEO") &&
        this.filters.includes("Communication") &&
        this.filters.includes("Code");
      if (bool) {
        return "blue accent-4";
      } else {
        return "";
      }
    },
    allCatClass: function () {
      let bool =
         this.filters.includes("Questions/Réponses") &&
        this.filters.includes("Marketing") &&
        this.filters.includes("Entrepreneuriat") &&
        this.filters.includes("SEO") &&
        this.filters.includes("Communication") &&
        this.filters.includes("Code");
      if (bool) {
        return "blue--text text--accent-4 v-chip--active";
      } else {
        return "";
      }
    },
  },
  methods: {
    getWebinarIcon(id) {
      if (id === 1) {
        return "mdi-magnify";
      } else if (id === 2) {
        return "mdi-bullhorn";
      } else if (id === 4) {
        return "mdi-code-tags";
      } else if (id === 0) {
        return "mdi-comment-outline";
      }
      else if (id === 3) {
        return "mdi-briefcase";
      }
      else if (id === 5) {
        return "mdi-frequently-asked-questions";
      }
    },
    toggle() {
      if (this.filters != [0, 1, 2, 3, 4]) {
        this.filters = [0, 1, 2, 3, 4];
      } else {
        this.filters = [];
      }
    },
    chipColor(cat) {
      if (cat === "SEO") {
        return "green accent-4";
      } else if (cat === "Communication") {
        return "orange accent-4";
      } else if (cat === "Marketing") {
        return "blue accent-4";
      } else if (cat === "Code") {
        return "#FCD004";
      }
    },
  },
  mounted() {
    this.$store.dispatch("setWebinars");
    setTimeout(() => {
      this.loading = false;
    }, 1200);
  },
};
</script>

<style>
</style>