var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('LabBar',{attrs:{"user_data":_vm.user_data,"user":_vm.user,"project":_vm.project,"pages":_vm.pages,"currentPage":_vm.currentPage,"popup":_vm.popup,"loadingChanges":_vm.loadingChanges,"startLoading":_vm.startLoading,"endLoading":_vm.endLoading,"expandParent":_vm.expand},on:{"childToParent":_vm.onChildClick,"gridToParent":_vm.gridFromChild,"univToParent":_vm.univFromChild,"expandToParent":_vm.expandFromChild,"drawerToParent":_vm.drawerfromChild}}),(!_vm.expand && _vm.drawer == 2)?_c('LabSideLeft',{attrs:{"blocks":_vm.blocks,"loadingBlocks":_vm.loadingBlocks,"currentPage":_vm.currentPage,"rows":_vm.rows},on:{"scrollToParent":_vm.scrollFromChild}}):(!_vm.expand && _vm.drawer == 1)?_c('LabSideArchi',{attrs:{"pages":_vm.pages,"user":_vm.user,"project":_vm.project,"popup":_vm.popup,"currentPage":_vm.currentPage}}):(!_vm.expand && _vm.drawer == 3)?_c('LabSideInstall'):_vm._e(),_c('LabSideRight'),_c('v-container',{staticClass:"pa-0 mt-12 v-step-start",style:([
      _vm.expand
        ? {
            maxWidth: _vm.expandedWidth,
            backgroundColor: _vm.builderColor,
            marginLeft: _vm.expandedMarginLeft,
            marginRight: _vm.expandedMarginRight,
            minHeight: _vm.buiderHeight,
          }
        : {
            maxWidth: _vm.reducedWidth,
            backgroundColor: _vm.builderColor,
            marginLeft: _vm.reducedMarginLeft,
            marginRight: _vm.reducedMarginRight,
            minHeight: _vm.buiderHeight,
          } ])},[_c('v-snackbar',{attrs:{"multi-line":"","bottom":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue accent-4","text":""},on:{"click":function($event){_vm.savedChild = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}]),model:{value:(_vm.savedChild),callback:function ($$v) {_vm.savedChild=$$v},expression:"savedChild"}},[_vm._v(" Vos changements ont bien été pris en compte ! 🎉 ")]),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"min-width":"755","height":"150","width":"auto","type":"card"}},[(!_vm.realMode)?_c('div',{staticClass:"row"},[_c('img',{staticStyle:{"width":"98%","height":"100%"},attrs:{"src":"/projectSetup/menu_1.png"}})]):_c('firstMenu',{attrs:{"pages":_vm.pages,"step":_vm.step,"currentPage":_vm.currentPage,"startLoading":_vm.startLoading,"endLoading":_vm.endLoading,"loadPage":_vm.loadPage}})],1),_c('v-container',{staticClass:"mt-10",staticStyle:{"min-height":"500px"}},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"height":"300","width":"auto","type":"card","min-width":"600"}},[_c('draggable',{attrs:{"list":_vm.rows,"handle":".handle"}},_vm._l((_vm.rows),function(row,parent_index){return _c('div',{key:parent_index,staticClass:"remove_link row row-drag mt-2"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('div',{staticClass:"col s20 px-3 pt-3 pb-0 col-drag"},[_c('v-card',{staticClass:"\n                    v-step-3\n                    v-step-5\n                    v-step-7\n                    v-step-9\n                    v-step-11\n                    v-step-13\n                    v-step-15\n                    v-step-18\n                  ",staticStyle:{"z-index":"0"},attrs:{"height":"auto","width":"auto","flat":"","id":_vm.rowId(parent_index)}},[(row.item.src)?_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":row.item.src}}):_vm._e(),_c('v-fade-transition',[(hover || _vm.gridActive)?_c('v-overlay',{staticStyle:{"border":"1px solid #4d92f7","cursor":"default"},attrs:{"absolute":"","color":"transparent"}},[_c('i',{staticClass:"drag-row material-icons handle"},[_vm._v("gamepad")]),_c('i',{staticClass:"up-row material-icons",on:{"click":function($event){return _vm.upRow(parent_index)}}},[_vm._v("arrow_upward")]),_c('i',{staticClass:"down-row material-icons",on:{"click":function($event){return _vm.downRow(parent_index)}}},[_vm._v("arrow_downward")]),_c('i',{staticClass:"material-icons duplicate-row",on:{"click":function($event){return _vm.duplicateRow(parent_index)}}},[_vm._v("content_copy")]),_c('i',{staticClass:"delete-row material-icons",on:{"click":function($event){return _vm.deleteRow(parent_index)}}},[_vm._v("delete")])]):_vm._e()],1)],1)],1)]}}],null,true)})],1)}),0)],1)],1),_c('v-skeleton-loader',{attrs:{"loading":_vm.loading,"height":"150","width":"auto","min-width":"755","type":"card"}},[(!_vm.realMode)?_c('div',{staticClass:"row align-center justify-center ma-0"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"/projectSetup/footer_2.png"}})]):_c('secondFooter',{attrs:{"project":_vm.project,"pages":_vm.pages,"step":_vm.step,"currentPage":_vm.currentPage,"startLoading":_vm.startLoading,"endLoading":_vm.endLoading,"loadPage":_vm.loadPage}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }