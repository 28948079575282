<template>
    <v-container class="pa-0 ma-0" style="max-width:100%;">
        <v-card flat>
            <v-img src="/form-free-training.png" class="wavy"></v-img>
        </v-card>
        <v-container style="max-width:50%" class="pa-2" v-if="step === 1">
             <v-card class="px-4">
                <v-card-title>
                    Vous avez fini votre formation gratuite
                </v-card-title>
                <v-card-text class="body-1">
                    Merci beaucoup ! Nous espérons que cette formation vous a aidé à lancer ce projet qui vous tient à coeur ! 
                </v-card-text>
            </v-card>
        

        <v-container class="my-4 py-4 px-12">
            <v-form>
                <v-row justify="start" class="pb-0 mb-0">
                    <p class="question">Cette formation vous a-t-elle aidé à commencer votre projet ? </p>
                </v-row>
                 <v-row justify="center" class="py-0 my-0">
       
                    <v-rating
                    class="input-field pa-1"
          v-model="rating_1"
          color="yellow darken-3"
          background-color="grey darken-1"
          empty-icon="$ratingFull"
          half-increments
          hover
          small
        ></v-rating>
                </v-row>

                <v-row justify="start" class="pb-0 mt-2 mb-0">
                    <p class="question">Pensez-vous avoir toutes les clés pour créer votre site par vous-même ?</p>
                </v-row>
                 <v-row justify="center" class="py-0 mb-2 mt-0">
       
                    <v-rating
                    class="input-field pa-1"
          v-model="rating_2"
          color="yellow darken-3"
          background-color="grey darken-1"
          empty-icon="$ratingFull"
          half-increments
          hover
          small
        ></v-rating>
                </v-row>


            <v-row justify="start" class="pb-0 my-2">
                    <p class="question">Auriez-vous besoin d'un accompagnement supplémentaire ? </p>
                </v-row>
                 <v-row justify="start" class="py-0 my-0">
       
                   <v-radio-group v-model="radio_1" style="width:50%">
      <v-radio
        label="Oui et oui ! Je me suis inscrit.e !"
        class="chip-test red-chip"
        value="Oui et oui ! Je me suis inscrit.e !"
        color="green"
      ></v-radio>
      <v-radio
        label="Ouii !"
        value="Ouii !"
        class="chip-test"
        color="green lighten-2"
      ></v-radio>
       <v-radio
        label="Oui mais je ne pense pas avoir le budget pour"
        value="Oui mais je ne pense pas avoir le budget pour"
        class="chip-test"
        color="orange lighten-2"
      ></v-radio>
       <v-radio
        label="Non, j'ai tout ce qu'il me faut !"
        value="Non, j'ai tout ce qu'il me faut !"
        class="chip-test"
        color="orange"
      ></v-radio>
    </v-radio-group>
                </v-row>

                <v-row justify="start" class="pb-0 my-2">
                    <p class="question">Aidez-nous à nous améliorer !</p>
                </v-row>
                <v-row justify="start">
                    <p>Comment pouvons-nous nous améliorer ? </p>
                </v-row>
                 <v-row justify="start">
                <v-textarea solo v-model="comment">
                        
                    </v-textarea>
                 </v-row>
                 <v-row justify="start" class="pb-0 my-2">
                     <v-text-field label="Prénom" solo v-model="name"></v-text-field>
                 </v-row>
                 <v-row justify="start" class="pb-0 my-2">
                     <v-text-field label="E-mail" solo v-model="email" type="email" :rules="emailRules"></v-text-field>
                 </v-row>
                    <v-row justify="start" class="pb-0 my-2">
                    <p class="question">Souhaitez-vous qu'un coach vous contacte pour faire le point sur votre projet ?</p>
                </v-row>
                  <v-row justify="start" class="pb-0 my-2">
                        
                   <v-radio-group v-model="coaching" style="width:50%">
      <v-radio
        label="Oui, avec plaisir !"
        class="chip-test red-chip"
        value="Oui, avec plaisir !"
        color="green"
      ></v-radio>
      <v-radio
        label="Non merci :)"
        value="Non merci :)"
        class="chip-test"
        color="red lighten-2"
      ></v-radio>
    </v-radio-group>
                  </v-row>

                     <v-row justify="start" class="pb-0 my-2" v-if="coaching == 'Oui, avec plaisir !'">
                    <p class="question">À quel numéro pouvons-nous vous joindre ?</p>
                </v-row>

                <v-row justify="start" v-if="coaching == 'Oui, avec plaisir !'">
                    <v-text-field v-model="tel" solo type="tel" label="Téléphone">

                    </v-text-field>
                </v-row>

                <v-row class="mt-12">
                    <v-btn color="#5192FA" dark @click="saveResults" :loading="loading">
                        Valider
                    </v-btn>
                </v-row>
            </v-form>
        </v-container>

        </v-container>

            <v-container v-else style="max-width:50%" class="pa-2">
            <v-card class="px-4">
                <v-card-title>
                    Merci pour vos réponses !
                </v-card-title>
                <v-card-text class="body-1 text-justify">
                    Merci beaucoup pour toutes vos réponses ! Elles nous aideront à améliorer notre Académie en ligne et à réaliser la solution la plus complète possible !
                </v-card-text>
            </v-card>

            <v-row justify="start" class="mt-12">
                <p class="headline font-weight-bold">Vous avez apprécié votre formation WebMyDay ? <v-icon color="yellow">mdi-star</v-icon><v-icon color="yellow">mdi-star</v-icon><v-icon color="yellow">mdi-star</v-icon><v-icon color="yellow">mdi-star</v-icon><v-icon color="yellow">mdi-star</v-icon></p>
            </v-row>
            <v-row>
                <p class="body-1">Un petit commentaire sur l'une de ces plateformes nous aiderait grandement ! Merci de votre soutien 🤗</p>
            </v-row>
            <v-row justify="center">
                <v-col>
                    <a href="https://fr.trustpilot.com/evaluate/webmyday.io" target="blank" title="Lien vers Trustpilot">
                    <v-img src="/trustpilot.jpeg" class="hover-img"></v-img>
                    </a>
                </v-col>
                 <v-col>
                     <a href="https://g.page/webmyday_io/review?gm" target="blank" title="Lien vers Google Reviews">
                    <v-img src="/google_reviews.png" class="hover-img"></v-img>
                     </a>
                </v-col>
            </v-row>
        </v-container>
         
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    name: "FormFreeTraining",
    data(){
    return {
    step: 1,
    emailRules: [
        (v) => !!v || "Veuillez rentrer une adresse e-mail valide !",
        (v) =>
          /.+@.+\..+/.test(v) || "Veuillez rentrer une adresse e-mail valide !",
      ],
    loading: false,
    rating_1: 3,
    rating_2: 3,
    radio_1: null,
    comment: "",
    name: "",
    email: "",
    coaching: "",
    tel: ""
    }
    },
    methods: {
       saveResults(){
           this.loading = true;
           console.log(
             {
                name: this.name,
                email: this.email,
                rating_1: this.rating_1,
                rating_2: this.rating_2,
                radio_1: this.radio_1,
                comment: this.comment,
                tel: this.tel,
                coaching: this.coaching
              }
           )
           axios({
              method: "post",
              url: `https://us-central1-webmyday-ba4d9.cloudfunctions.net/formEndOfFreeTraining?name=${this.name}&email=${this.email}&rating_1=${this.rating_1}&rating_2=${this.rating_2}&radio_1=${this.radio_1}&comment=${this.comment}&tel=${this.tel}&coaching=${this.coaching}`,
              data: {
                name: this.name,
                email: this.email,
                rating_1: this.rating_1,
                rating_2: this.rating_2,
                radio_1: this.radio_1,
                comment: this.comment,
                tel: this.tel,
                coaching: this.coaching
              },
              headers: { "Content-Type": "multipart/form-data" },
            })
            .catch((err) => console.log(err))
                setTimeout(() => {
                this.loading = false;
                this.step += 1;  
                }, 2200);
                
        
       }
    },
}
</script>

<style>
.input-field {
    background: white;
    border: 2px  #bfbfbfaa solid;
    width: 100%;
}

.input-field:hover {
    border: 2px #BFBFBF solid;
}

.question {
    font-size: 17px;
    font-weight: 600;
    color: #464646;
    word-break: keep-all;
}

.chip-test label {
    border-radius: 9999px;
    font-size: 14px;
    padding-left: 5px;
}


</style>