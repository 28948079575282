<template>
  <div>
    <v-card>
      <v-navigation-drawer
        permanent
        fixed
        hide-overlay
        color="white"
        light
        left

        width="45%"
        style="margin-top: 65px"
      >
        <v-list nav class="left-align py-0" dense style="height: 100%">
          <v-row
            justify="start"
            align-content="start"
            align="start"
            style="height: 100%"
          >
            <v-tabs
              vertical
              background-color="white"
              light
              style="height: 100%"
              class="lab-tabs"
              active-class="active-tabs-lab"
            >
              <!-- Les classiques -->
              <v-container>
                <div
                  style="display: inline-flex"
                  class="font-weight-black black--text"
                >
                  <v-icon small color="black">mdi-star</v-icon>
                  <p class="body-4 px-4 my-1">LES CLASSIQUES</p>
                </div>

                <v-tab
                  class="font-weight-black tabs-lab v-step-1"
                  style="font-size: 10px"
                  >En-têtes de page</v-tab
                >
                <v-tab
                  class="font-weight-black tabs-lab texts-tab v-step-4"
                  style="font-size: 10px"
                  >Textes</v-tab
                >
                <v-tab
                  class="font-weight-black tabs-lab images-tab v-step-6"
                  style="font-size: 10px"
                  >Médias</v-tab
                >
                <v-tab
                  class="font-weight-black tabs-lab"
                  style="font-size: 10px"
                  >Textes et médias</v-tab
                >
                <v-tab
                  class="font-weight-bold tabs-lab bouttons-tab v-step-8"
                  style="font-size: 10px"
                  >Boutons</v-tab
                >
              </v-container>

              <v-divider></v-divider>

              <!-- Les thématiques -->
              <v-container>
                <div
                  style="display: inline-flex"
                  class="font-weight-black black--text"
                >
                  <p class="body-4 px-4 my-1 center-align">LES THÉMATIQUES</p>
                </div>

                <v-tab
                  class="font-weight-black tabs-lab avis-tab v-step-10"
                  style="font-size: 10px"
                  >Avis client</v-tab
                >

                <v-tab
                  class="font-weight-black tabs-lab"
                  style="font-size: 10px"
                  >Blog</v-tab
                >

                <v-tab
                  class="font-weight-black tabs-lab faq-tab v-step-12"
                  style="font-size: 10px"
                  >FAQ</v-tab
                >

                <v-tab
                  class="font-weight-black tabs-lab"
                  style="font-size: 10px"
                  >Partenaires</v-tab
                >

                <v-tab
                  class="font-weight-black tabs-lab"
                  style="font-size: 10px"
                  >Équipe</v-tab
                >

                <v-tab
                  class="font-weight-black tabs-lab"
                  style="font-size: 10px"
                  >Tableaux de prix</v-tab
                >

                <v-tab
                  class="font-weight-black tabs-lab"
                  style="font-size: 10px"
                  >Produits</v-tab
                >

                <v-tab
                  class="font-weight-black tabs-lab"
                  style="font-size: 10px"
                  >Inscriptions</v-tab
                >
                <v-tab
                  class="font-weight-black tabs-lab contact-tab v-step-14"
                  style="font-size: 10px"
                  >Contact</v-tab
                >
              </v-container>

              <v-tab-item class="test-tab-item">
                <v-card
                  style="height: 100%"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      En-têtes de page
                    </p>
                    <p class="sub-cat-caption">
                      Le bloc "en-tête de page" se place tout en haut de vos
                      pages
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.headers"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 v-step-2 block-card dashboard-card"
                                height="140"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                  :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      Textes
                    </p>
                    <p class="sub-cat-caption">
                      Placez un bloc "texte" pour y intégrer du texte sur votre
                      page !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.texts"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                  :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      Médias
                    </p>
                    <p class="sub-cat-caption">
                      Placez un bloc "média" pour y intégrer une image ou une
                      vidéo !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.medias"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                  :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      Texte et médias
                    </p>
                    <p class="sub-cat-caption">
                      Ajoutez un visuel et du texte ensemble !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.textAndMedias"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                  :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      Boutons
                    </p>
                    <p class="sub-cat-caption">
                      Ajoutez des boutons pour guider vos visiteurs à découvrir
                      votre site !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.buttons"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                  :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      Avis client
                    </p>
                    <p class="sub-cat-caption">
                      Mettez en avant les avis que vos clients laissent sur
                      votre site !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.testimony"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                  :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      Blog
                    </p>
                    <p class="sub-cat-caption">
                      Ajoutez un bloc "blog" si vous souhaitez faire apparaître
                      un onglet blog sur votre site !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.blog"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                  :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      FAQ
                    </p>
                    <p class="sub-cat-caption">
                      Répondez aux questions récurrentes de vos visiteurs grâce
                      à une FAQ !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.faq"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                  :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      Partenaires
                    </p>
                    <p class="sub-cat-caption">
                      Mettez en avant vos partenaires sur votre site !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.partnerships"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                  :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      Equipe
                    </p>
                    <p class="sub-cat-caption">
                      Mettez en avant les membres de votre équipe !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.team"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top ccolor="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                  :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      Tableau de prix
                    </p>
                    <p class="sub-cat-caption">
                      Affichez vos tarifs dans un tableau de prix !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.pricing"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                  :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      Produits
                    </p>
                    <p class="sub-cat-caption">
                      Vous créez un site e-commerce ? Ajoutez des blocs
                      "produits" pour afficher vos produits !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.products"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                  :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      Inscriptions
                    </p>
                    <p class="sub-cat-caption">
                      Ajoutez un bloc "inscription" pour inviter vos visiteurs à
                      s'inscrire à nos nouveautés !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.newsletter"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                                :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>

              <v-tab-item class="test-tab-item">
                <v-card
                  height="auto"
                  class="draggable-blocks container"
                  color="#f4f7fa"
                  flat
                  min-height="900"
                >
                  <div class="container center-align pb-0 mb-0">
                    <p class="sub-cat-title font-weight-black center-align">
                      Contact
                    </p>
                    <p class="sub-cat-caption">
                      Ajoutez un formulaire de contact pour permettre à vos
                      visiteurs de vous contacter !
                    </p>
                  </div>
                  <v-container>
                    <v-row>
                      <v-col
                        md="6"
                        xl="4"
                        v-for="(block, index) in blocks.contact"
                        :key="index"
                        style="margin-bottom: 15px"
                      >
                        <v-tooltip top color="black">
                          <template v-slot:activator="{ on }">
                            <v-skeleton-loader
                              :loading="loadingBlocks"
                              height="100"
                              width="220"
                              type="card"
                            >
                              <v-card
                                v-on="on"
                                class="pa-5 dashboard-card"
                                height="120"
                                hover
                                @click="
                                  AddRow({
                                    id: block.id,
                                    src: block.src,
                                    type: block.type,
                                    txt: block.txt,
                                  })
                                "
                              >
                                <img
                               :src="block.src"
                                  style="max-width: 100%"
                                  v-if="block"
                                  class="block-image"
                                />
                              </v-card>
                            </v-skeleton-loader>
                          </template>
                          <span class="tooltip-text" v-if="block.txt">{{
                            block.txt
                          }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-row>
        </v-list>
      </v-navigation-drawer>

      <v-dialog v-model="popupChoosePage" width="600">
        <v-card>
          <v-card-title class="headline" style="background-color: #f4f7fa">
            Oups, choisissez une maquette à travailler ! 👀
            <v-icon
              style="position: absolute; right: 17px"
              @click="popupChoosePage = false"
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text class="px-6 py-4 mt-2 text-justify"
            >Oups, vous n'avez <strong>pas sélectionné</strong> de maquette à
            travailler ! Pour ajouter un bloc dans votre maquette, veuillez
            choisir la <strong>maquette que vous souhaitez travailler</strong> à
            partir du menu déroulant dans la barre de navigation juste au dessus
            ⬆</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="popupChoosePage = false" color="blue accent-4"
              >Fermer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LabSideLeft",
  props: ["blocks", "loadingBlocks", "currentPage", "rows"],
  data() {
    return {
      loading: false,
      popupChoosePage: false,
    };
  },
  computed: {
    blockCardsCols: function () {
      let windowSize = window.screen.width;
      if (windowSize < 1400) {
        return 6;
      } else {
        return 4;
      }
    },
  },
  methods: {
    ...mapActions(["addRow"]),
    // getImage(item) {
    //   return require(item.src);
    // },
    AddRow(row) {
      if (this.currentPage.slug) {
        let lastRowIndex = this.rows.length;
        this.undone = [];
        this.addRow(row);
        setTimeout(() => {
          this.$emit("scrollToParent", lastRowIndex);
        }, 100);

        // location.hash = `#row-${lastRowIndex}`
      } else {
        this.popupChoosePage = true;
      }
    },
  },
};
</script>

<style scoped>
.active-tabs-lab {
  color: #2862ff !important;
}

.blocks-cat {
  color: #2862ff !important;
}

.sub-cat-title {
  font-size: 28px;
  color: black;
}

.sub-cat-caption {
  font-size: 14px;
}

.tooltip-text {
  font-size: 12px;
  font-weight: 400;
}

.tabs-lab {
  height: 35px !important;
}

.draggable-blocks {
  height: 100% !important;
}

@media only screen and (min-width: 1600px) {
  .block-image {
    max-width: 200px !important;
  }
}
</style>

<style>
.v-window__container {
  height: 100% !important;
}

.test-tab-item {
  height: 100%;
}
</style>