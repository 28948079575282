var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticStyle:{"box-shadow":"0 12px 50px 2px #13507c24"},attrs:{"color":"white","fixed":"","left":"","app":""}},[_c('v-btn',{staticClass:"font-weight-medium mr-10",attrs:{"text":"","color":"black"},on:{"click":_vm.exitBuilder}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v("Retour ")],1),_c('v-chip',{staticClass:"ml-10 dashboard-card pa-6",staticStyle:{"background":"white"},attrs:{"label":""}},[_c('v-switch',{staticClass:"pt-6 v-step-17",attrs:{"color":"blue accent-4","loading":_vm.loadingUniv},on:{"change":_vm.emitUnivToParent},model:{value:(_vm.switchMode),callback:function ($$v) {_vm.switchMode=$$v},expression:"switchMode"}}),_vm._v(" "+_vm._s(_vm.Switch)+" "),_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","right":"","color":"#88a6c9"}},on),[_vm._v("mdi-help-circle")])]}}])},[_c('span',[_vm._v("Alternez entre le mode édition et le mode réel pour mieux visualiser vos maquettes")])])],1),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","min-width":"280","max-height":"460","open-on-hover":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.project && _vm.currentPage)?_c('v-btn',_vm._g({staticClass:"dashboard-card black--text",staticStyle:{"background":"white","min-width":"280px"}},on),[(_vm.currentPage.name)?_c('span',{staticClass:"font-weight-medium",staticStyle:{"text-transform":"capitalize","color":"#255cee"}},[_vm._v(_vm._s(_vm.currentPage.name))]):_c('span',{staticClass:"font-weight-medium",staticStyle:{"text-transform":"capitalize"}},[_vm._v("Mes maquettes")]),_c('v-icon',{attrs:{"size":"18","right":""}},[_vm._v("mdi-menu-down")])],1):_vm._e()]}}])},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-subheader',{staticClass:"font-weight-black black--text text-uppercase"},[_vm._v(" Mes maquettes ")]),(_vm.pages.length === 0)?_c('div',{staticClass:"container center-align"},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"caption grey--text pl-2"},[_vm._v(" Aucune maquette créée pour le moment ! ")])]),_c('div',{staticClass:"row justify-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"font-weight-black",attrs:{"depressed":"","icon":""},on:{"click":_vm.showPopUp,"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},on),[_c('v-icon',{attrs:{"color":_vm.editIconColor}},[_vm._v("mdi-plus-box-multiple")])],1)]}}],null,false,1665686292)},[_c('span',[_vm._v("Créer une nouvelle maquette")])])],1)]):_vm._e(),_vm._l((_vm.pages),function(page,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-title',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},style:([
            page.slug == _vm.currentPage.slug
              ? { color: _vm.currentPageColor }
              : { color: _vm.pagesColor } ]),on:{"click":function($event){return _vm.loadPage(page.slug)}}},[_vm._v(_vm._s(page.name))]),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deletePopUp(page)}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-delete")])],1)],1)],1)})],2)],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2 pa-0 dashboard-card",staticStyle:{"background":"white","min-height":"36px"},attrs:{"small":""},on:{"click":_vm.showPopUp}},on),[_c('v-icon',[_vm._v("mdi-plus-box-multiple")])],1)]}}])},[_c('span',[_vm._v("Créer une nouvelle maquette")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"mx-2 pa-0 dashboard-card",staticStyle:{"background":"white","min-height":"36px"},attrs:{"loading":_vm.loadingChanges,"small":"","disabled":!_vm.currentPage.slug || _vm.pages.length === 0},on:{"click":_vm.saveChanges}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)]}}])},[(!_vm.currentPage.slug || _vm.pages.length === 0)?_c('span',[_vm._v("Veuillez sélectionner une maquette pour pouvoir la sauvegarder !")]):_c('span',[_vm._v("Sauvegarder")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"mx-2 pa-0 dashboard-card",staticStyle:{"background":"white","min-height":"36px"},attrs:{"small":"","loading":_vm.loadingDownload,"disabled":!_vm.currentPage.slug || _vm.pages.length === 0},on:{"click":_vm.setJsonContent}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)]}}])},[(!_vm.currentPage.slug || _vm.pages.length === 0)?_c('span',[_vm._v("Veuillez sélectionner une maquette pour pouvoir la télécharger !")]):_c('span',[_vm._v("Télécharger")])]),_c('PopupNewPage',{attrs:{"pages":_vm.pages,"user":_vm.user,"project":_vm.project,"popup":_vm.popup,"page":_vm.page,"type":_vm.popupType,"currentPage":_vm.currentPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }