<template>
  <v-container fluid class="pa-6">
    <v-row class="pl-6" style="display: block">
      <p class="headline font-weight-bold black--text mb-0">
        Tâches à réaliser
      </p>
      <v-subheader class="pl-0">
        Organisez au mieux vos différentes tâches et restez à jour 😎
      </v-subheader>
    </v-row>
    <v-row justify="space-between" class="px-4">
      <v-col cols="12" md="4" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card
            min-height="300"
            class="dashboard-card flex d-flex flex-column"
          >
            <v-card-title class="justify-center body-1"
              >🔴 A faire
              <span class="grey--text font-weight-regular pl-1"
                >({{ tasksToDo.length }})</span
              ></v-card-title
            >
            <v-card-text>
              <v-card
                v-for="task in tasksToDo"
                :key="task.id"
                class="list-group-item ma-2 border-secondary"
                flat
              >
                <v-row justify="end" style="max-height: 80px" class="mb-0 pr-0">
                  <v-col cols="5" class="px-0 mr-0">
                    <v-select
                      :items="statuses"
                      v-model="task.state"
                      class="body-1 status-select"
                      color="blue accent-4"
                      item-color="blue accent-4"
                      dense
                      @change="updateTasks(task.id, task.state)"
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip
                          small
                          label
                          style="background: white"
                          class="status-chip"
                          ><v-icon :color="statusColor(item.value)" small left
                            >mdi-circle</v-icon
                          >
                          {{ item.text }}
                          <v-icon small right>mdi-chevron-down</v-icon></v-chip
                        >
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-card-title
                  class="text-body-2 font-weight-medium black--text py-0"
                  style="word-break: keep-all"
                  >{{ task.title }}
                </v-card-title>
                <!-- <v-card-text class="caption">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                </v-card-text> -->
              </v-card>
            </v-card-text>
          </v-card>
        </v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="4" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card
            min-height="300"
            class="dashboard-card flex d-flex flex-column"
          >
            <v-card-title class="justify-center body-1"
              >🟠 En cours
              <span class="grey--text font-weight-regular pl-1"
                >({{ tasksDoing.length }})</span
              ></v-card-title
            >
            <v-card-text>
              <v-card
                v-for="task in tasksDoing"
                :key="task.id"
                class="list-group-item ma-2 border-secondary"
                flat
              >
                <v-row justify="end" style="max-height: 80px" class="mb-0 pr-2">
                  <v-col cols="5" class="px-0 mr-0">
                    <v-select
                      :items="statuses"
                      v-model="task.state"
                      class="body-1 status-select"
                      color="blue accent-4"
                      item-color="blue accent-4"
                      dense
                      @change="updateTasks(task.id, task.state)"
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip
                          small
                          label
                          style="background: white"
                          class="status-chip"
                          ><v-icon :color="statusColor(item.value)" small left
                            >mdi-circle</v-icon
                          >
                          {{ item.text }}
                          <v-icon small right>mdi-chevron-down</v-icon></v-chip
                        >
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-card-title
                  class="text-body-2 font-weight-medium black--text py-0"
                  style="word-break: keep-all"
                  >{{ task.title }}
                </v-card-title>
                <!-- <v-card-text class="caption">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                </v-card-text> -->
              </v-card>
            </v-card-text>
          </v-card>
        </v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="4" class="d-flex flex-column">
        <v-skeleton-loader
          type="card"
          :loading="dataLoading"
          class="flex d-flex flex-column"
        >
          <v-card
            min-height="300"
            class="dashboard-card flex d-flex flex-column"
          >
            <v-card-title class="justify-center body-1"
              >✅ Fait
              <span class="grey--text font-weight-regular pl-1"
                >({{ tasksDone.length }})</span
              >
            </v-card-title>
            <v-card-text>
              <v-card
                v-for="task in tasksDone"
                :key="task.id"
                class="list-group-item ma-2 border-secondary"
                flat
              >
                <v-row justify="end" style="max-height: 80px" class="mb-0 pr-2">
                  <v-col cols="4" class="px-0 mr-0">
                    <v-select
                      :items="statuses"
                      v-model="task.state"
                      class="body-1 status-select"
                      color="blue accent-4"
                      item-color="blue accent-4"
                      dense
                      @change="updateTasks(task.id, task.state)"
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip
                          small
                          label
                          style="background: white"
                          class="status-chip"
                          ><v-icon :color="statusColor(item.value)" small left
                            >mdi-circle</v-icon
                          >
                          {{ item.text }}
                          <v-icon small right>mdi-chevron-down</v-icon></v-chip
                        >
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-card-title
                  class="text-body-2 font-weight-medium black--text py-0"
                  style="word-break: keep-all"
                  >{{ task.title }}
                </v-card-title>
                <!-- <v-card-text class="caption">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                </v-card-text> -->
              </v-card>
            </v-card-text>
          </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" multi-line bottom>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue accent-4"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProjectManagement",
  data() {
    return {
      snackbarText: "",
      snackbar: false,
      dataLoading: true,
      list1: [
        { name: "Tâche 1", id: 1 },
        { name: "Tâche 2", id: 2 },
        { name: "Tâche 3", id: 3 },
        { name: "Tâche 4", id: 4 },
      ],
      list2: [
        { name: "Tâche 5", id: 5 },
        { name: "Tâche 6", id: 6 },
        { name: "Tâche 7", id: 7 },
      ],
      statuses: [
        { text: "A faire", value: 1 },
        { text: "En cours", value: 2 },
        { text: "Fait", value: 3 },
      ],
    };
  },
  computed: {
    ...mapState(["user_data", "project", "coaches", "tasks"]),
    coach: function () {
      return this.coaches.find(
        (coach) => coach.id === this.user_data.project.coach
      );
    },
    tasksToDo: function () {
      return this.tasks.filter((task) => task.state === 1);
    },
    tasksDone: function () {
      return this.tasks.filter((task) => task.state === 3);
    },
    tasksDoing: function () {
      return this.tasks.filter((task) => task.state === 2);
    },
  },
  methods: {
    statusColor(state) {
      if (state === 1) {
        return "error";
      } else if (state === 2) {
        return "amber darken-2";
      } else {
        return "green accent-2";
      }
    },
    updateTasks(id, state) {
      this.snackbarText = "";
      this.snackbar = false;
      this.$store
        .dispatch("updateTasks", {
          id: id,
          state: state,
          course: this.$route.params.course,
        })
        .then(() => {
          if (state === 1) {
            this.snackbarText =
              "Vous venez de marquer cette tâche comme non terminée, au travail 🤓";
            this.snackbar = true;
          } else if (state === 2) {
            this.snackbarText =
              "Super ! Cette tâche est en cours de travail 😁";
            this.snackbar = true;
          } else {
            this.snackbarText =
              "Félicitations ! Vous venez de terminer l'une de vos tâches 😎";
            this.snackbar = true;
          }
        });
    },
    addTasksProp() {
      this.$store.commit("ADD_TASKS_PROP");
    },
    listTitleStyle(done) {
      if (done) {
        return "text-decoration-line-through";
      } else {
        return "";
      }
    },
    score(task) {
      return (
        (task.checklist.filter((li) => li.done).length /
          task.checklist.length) *
        100
      );
    },
  },
  async mounted() {
    await this.$store.dispatch("setTasks", this.$route.params.course);
    setTimeout(() => {
      this.dataLoading = false;
    }, 1500);
  },
};
</script>

<style>
.status-select.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.status-select.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.status-select > div > div > div.v-select__slot > div.v-input__append-inner {
  display: none;
}

.status-chip:hover {
  cursor: pointer;
}
</style>