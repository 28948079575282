<template>
  <v-container style="height:100%;">
    <v-row style="height:100%;" class="py-0 my-0">
      <v-col style="height:100%;background-color:#EDF4FF;">
        <v-row align="center" justify="center">
          <h1 class="font-weight-black" style="font-size:150px;color:#33495D;">404</h1>
        </v-row>
        <v-row align="center" justify="center">
          <h2 class="center-align">Humm... Il semblerait que la page recherchée n'existe pas 🤔</h2>
        </v-row>
        <div class="container center-align">
          <v-btn
            dark
            color="#4D92F7"
            class="font-weight-bold"
            @click="goToHome"
          >Revenir sur mes pas 🏃‍♂️</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound404",
  methods: {
    goToHome() {
      this.$router.push({ name: "dashboard" });
    },
  },
};
</script>

<style>
</style>