<template>
  <v-dialog v-model="popupReAuth" max-width="600" persistent>
    <v-card>
      <v-card-title class="headline" style="background-color: #edf4ff">
        Action requise - opération sensible 🚨
        <v-icon @click="emitPopupReAuth" style="position: absolute; right: 17px"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-container class="pa-4">
        <v-row justify="center" align="center" class="pa-4">
          <p>
            Vous êtes sur le point de d'effectuer une opération sensible et
            irréversible. Pour des raisons de sécurité, veuillez vous
            <strong>ré-authentifier</strong>. Vous pourrez ensuite procéder à
            vos changements.
          </p>
        </v-row>
        <v-row justify="start" align="center" class="px-4 mb-0 pb-0">
          <v-col cols="8">
            <v-text-field
              label="Addresse e-mail"
              filled
              color="blue accent-4"
              v-model="currentEmail"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row justify="start" align="center" class="px-4 mt-0 pt-0">
          <v-col cols="8">
            <v-text-field
              label="Mot de passe"
              filled
              color="blue accent-4"
              :type="type"
              v-model="currentPassword"
              @click:append="hideAndShow"
              :append-icon="iconPassword"
              @keyup.enter="reAuthLogin"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center" align="center" v-if="feedback">
          <p class="red--text caption text-align-center">
            {{ feedback }}
          </p>
        </v-row>
      </v-container>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue accent-4"
          text
          @click="reAuthLogin"
          :loading="loading"
          >Me ré-authentifier</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "firebase";

export default {
  name: "PopupReAuth",
  data() {
    return {
      currentPassword: null,
      currentEmail: null,
      type: "password",
      iconPassword: "mdi-eye",
      feedback: null,
      loading: false,
    };
  },
  props: ["popupReAuth", "securityType"],
  methods: {
    hideAndShow() {
      this.type = this.type === "password" ? "text" : "password";
      if (this.type === "text") {
        this.iconPassword = "mdi-eye-off";
      } else {
        this.iconPassword = "mdi-eye";
      }
    },
    emitPopupReAuth() {
      this.$emit("popupReAuthDialogToParent");
    },
    reAuthLogin() {
      const user = firebase.auth().currentUser;
      const credentials = firebase.auth.EmailAuthProvider.credential(
        this.currentEmail,
        this.currentPassword
      );
      this.loading = true;

      user
        .reauthenticateWithCredential(credentials)
        .then(() => {
          this.feedback = null;
          this.loading = false;
          this.emitPopupReAuth();

          this.$emit("securityDialogToParent", this.securityType);
        })
        .catch((err) => {
          if (
            err.message ==
            "The password is invalid or the user does not have a password;"
          ) {
            this.feedback =
              "Mot de passe ou addresse e-mail invalide, veuillez rééssayer.";
          } else if (
            err.message ==
            "Too many unsuccessful login attempts. Please try again later."
          ) {
            this.feedback =
              "Trop de tentatives successives, veuillez rééssayer dans 5 minutes !";
          } else {
            this.feedback = "Une erreur est survenue, veuillez rééssayer";
          }

          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>