import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import { store } from './store/index';
import { auth } from './firebase/init';
import VueTyperPlugin from 'vue-typer';
import Hotjar from 'vue-hotjar';
import VueTour from 'vue-tour';
import VueApexCharts from 'vue-apexcharts'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

Vue.config.productionTip = false;
Vue.use(VueTyperPlugin);
Vue.use(Hotjar, {
  id: '2061178'
})
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)


let app;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      vuetify,
      store,
      router,
      render: h => h(App)
    }).$mount('#app')
  }
});

