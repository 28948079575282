<template>
  <v-container fluid class="pa-6">
    <v-row class="pl-6" style="display: block">
      <p class="headline font-weight-bold black--text mb-0">Mon compte</p>
      <v-subheader class="pl-0">
        Gérez vos informations personnelles, administratives et de connexion
      </v-subheader>
    </v-row>
    <v-skeleton-loader
      type="card"
      :loading="loading"
      class="fill-height"
      min-height="800"
    >
      <v-card class="fill-height dashboard-card">
        <v-tabs class="fill-height" color="blue accent-4" hide-slider>
          <v-tab style="justify-content: start" class="fill-height"
            ><v-icon left>mdi-card-account-details</v-icon> Mon Profil</v-tab
          >
          <v-tab style="justify-content: start" class="fill-height"
            ><v-icon left>mdi-briefcase</v-icon> Mon Projet</v-tab
          >
          <v-tab style="justify-content: start" class="fill-height"
            ><v-icon left>mdi-file-document-multiple</v-icon>
            Administratif</v-tab
          >
          <v-tab style="justify-content: start" class="fill-height"
            ><v-icon left>mdi-security</v-icon> Sécurité</v-tab
          >

          <v-tab-item class="fill-height mt-6">
            <v-card class="fill-height" flat>
              <v-card-title> Mon Profil </v-card-title>
              <v-card-text>
                <v-row class="mt-3 mb-6">
                  <v-col cols="1">
                    <v-avatar
                      size="80"
                      color="blue accent-4"
                      class="white--text title"
                      v-if="!user_data.profile.picture"
                    >
                      {{ getInitials(user_data.profile.name) }}
                    </v-avatar>
                    <v-avatar size="80" v-else>
                      <v-img :src="user_data.profile.picture"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col align-self="center">
                    <v-btn outlined color="blue accent-4" @click="pickImg"
                      ><v-icon left>mdi-camera</v-icon>
                      <span v-if="user_data.profile.picture"
                        >Modifier ma photo</span
                      ><span v-else>Ajouter une photo</span></v-btn
                    >
                    <input
                      type="file"
                      accept="image/*"
                      hidden="hidden"
                      ref="profile-img"
                      id="profile-img"
                      @change="uploadImg"
                    />
                  </v-col>
                </v-row>

                <v-container fluid>
                  <v-form style="max-width: 70%">
                    <v-row justify="start">
                      <v-text-field
                        label="Nom complet"
                        v-model="user_data.profile.name"
                        filled
                        color="blue accent-4"
                        @change="updateProfile"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row justify="start" class="d-flex flex-column">
                      <v-text-field
                        label="Date de naissance"
                        type="date"
                        filled
                        v-model="user_data.profile.birthdate"
                        color="blue accent-4"
                        class="flex d-flex flex-column"
                        @change="updateProfile"
                      >
                      </v-text-field>
                      <v-text-field
                        label="Lieu de naissance"
                        placeholder="Paris, France"
                        type="address"
                        filled
                        v-model="user_data.profile.birthLocation"
                        prepend-inner-icon="mdi-pin"
                        color="blue accent-4"
                        class="flex d-flex flex-column"
                        @change="updateProfile"
                      >
                      </v-text-field>
                    </v-row>

                    <v-row justify="start">
                      <label for="text"
                        >Genre
                        <v-radio-group
                          row
                          v-model="user_data.profile.gender"
                          @change="updateProfile"
                        >
                          <v-radio
                            label="Homme"
                            value="male"
                            color="blue accent-4"
                          ></v-radio>
                          <v-radio
                            label="Femme"
                            value="female"
                            color="purple accent-4"
                          ></v-radio>
                          <v-radio
                            label="Autre"
                            value="other"
                            color="grey darken-1"
                          ></v-radio>
                        </v-radio-group>
                      </label>
                    </v-row>

                    <v-row justify="start" class="disabled-email">
                      <v-text-field
                        label="Adresse e-mail"
                        type="email"
                        filled
                        v-model="user_data.profile.email"
                        class="pl-2"
                        prepend-inner-icon="mdi-email"
                        color="blue accent-4"
                        disabled
                        @change="updateProfile"
                      >
                      </v-text-field>
                    </v-row>

                    <v-row>
                      <v-text-field
                        label="Téléphone"
                        type="tel"
                        filled
                        v-model="user_data.profile.tel"
                        class="pl-2"
                        prepend-inner-icon="mdi-phone"
                        color="blue accent-4"
                        @change="updateProfile"
                      >
                      </v-text-field>
                    </v-row>

                    <v-row>
                      <v-text-field
                        label="Adresse postale"
                        type="tel"
                        filled
                        v-model="user_data.profile.adress"
                        class="pl-2"
                        prepend-inner-icon="mdi-pin"
                        color="blue accent-4"
                        placeholder="12 rue du test, 75002, Paris"
                        @change="updateProfile"
                      >
                      </v-text-field>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item class="fill-height mt-6">
            <v-card class="fill-height" flat>
              <v-card-title> Mon Projet</v-card-title>

              <v-card-text>
                <v-container fluid>
                  <v-form style="max-width: 70%">
                    <v-row justify="start">
                      <v-text-field
                        label="Nom du projet ou de l'entreprise"
                        type="text"
                        filled
                        v-model="project.name"
                        color="blue accent-4"
                        @change="updateProject"
                        prepend-inner-icon="mdi-briefcase"
                      >
                      </v-text-field>
                    </v-row>

                    <v-row justify="start">
                      <v-combobox
                        label="Mon secteur d'activité"
                        v-model="user_data.project.sector"
                        outlined
                        :items="sectors"
                        @change="updateShortProject"
                        color="blue accent-4"
                        prepend-inner-icon="mdi-account-hard-hat"
                      >
                      </v-combobox>
                    </v-row>

                    <v-row justify="start">
                      <v-text-field
                        label="L'objectif principal de ma formation"
                        type="text"
                        filled
                        v-model="user_data.project.mainGoal"
                        color="blue accent-4"
                        @change="updateShortProject"
                        prepend-inner-icon="mdi-target"
                      >
                      </v-text-field>
                    </v-row>

                    <v-row justify="start">
                      <v-select
                        label="Le temps approximatif consacré à la formation par semaine"
                        type="text"
                        outlined
                        v-model="user_data.project.availability"
                        color="blue accent-4"
                        :items="availabilities"
                        prepend-inner-icon="mdi-timer"
                        @change="updateShortProject"
                        item-color="blue accent-4"
                      >
                      </v-select>
                    </v-row>

                    <v-row justify="start">
                      <v-text-field
                        label="Ma date limite idéale"
                        type="date"
                        filled
                        v-model="user_data.project.deadline"
                        color="blue accent-4"
                        @change="updateShortProject"
                      >
                      </v-text-field>
                    </v-row>
                  </v-form>
                  <!-- <v-divider></v-divider>
                  <v-card-title class="black--text">
                    Mon Site internet
                  </v-card-title>
                  <v-form style="max-width: 70%">
                    <v-row justify="start">
                      <v-select
                        label="Type de site internet"
                        outlined
                        v-model="user_data.project.type"
                        color="blue accent-4"
                        @change="updateShortProject"
                        :items="types"
                        item-color="blue accent-4"
                      >
                      </v-select>
                    </v-row>

                    <v-row justify="start" class="disabled-email">
                      <v-text-field
                        label="URL temporaire de mon site internet"
                        filled
                        v-model="user_data.configuration.url"
                        color="blue accent-4"
                        prepend-inner-icon="mdi-link"
                        disabled
                        v-if="user_data.configuration.isTemporaryDomain"
                      >
                      </v-text-field>
                      <v-text-field
                        label="Lien de mon site internet"
                        filled
                        v-model="project.progress.domain.address"
                        color="blue accent-4"
                        prepend-inner-icon="mdi-link"
                        disabled
                        v-else
                      >
                      </v-text-field>
                    </v-row>
                  </v-form> -->
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item class="fill-height mt-6">
            <v-card class="fill-height" flat>
              <v-card-title> Administratif </v-card-title>

              <v-container>
                 <v-row>
                  <v-col cols="12"  class="mb-4">
                      <v-subheader style="
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            height: 38px;
          "
          class="pl-2 black--text mt-0">
                Entrée en formation
              </v-subheader>
              <v-divider></v-divider>
                  </v-col>
              
              <v-row>
                <v-col>
                  <v-chip :disabled="!userCourseDoc.project.startSheet || userCourseDoc.project.startSheet.url === ''" :href="userCourseDoc.project.startSheet.url" target="_blank"  v-if="userCourseDoc.project.startSheet">
                    <v-icon left>mdi-file-document</v-icon>Attestation d'entrée en formation
                  </v-chip>
                  <v-chip disabled  v-else>
                    <v-icon left>mdi-file-document</v-icon>Attestation d'entrée en formation
                  </v-chip>
                </v-col>
              </v-row>
              </v-row>

            <v-row>
                  <v-col cols="12" class="mb-4">
                      <v-subheader style="
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            height: 38px;
          "
          class="pl-2 black--text mt-0">
                Fin de formation
              </v-subheader>
              <v-divider></v-divider>
                  </v-col>
              
              <v-row>
                <v-col>
                  <v-chip :disabled="!userCourseDoc.project.presenceSheet || userCourseDoc.project.presenceSheet.url === ''" :href="userCourseDoc.project.presenceSheet.url" target="_blank" v-if="userCourseDoc.project.presenceSheet">
                    <v-icon left>mdi-file-document</v-icon>Attestation de fin de formation
                  </v-chip>
                  <v-chip disabled v-else>
                    <v-icon left>mdi-file-document</v-icon>Attestation de fin de formation
                  </v-chip>
                </v-col>
              </v-row>
              </v-row>
              </v-container>
             
            </v-card>
          </v-tab-item>

          <v-tab-item class="fill-height mt-6">
            <v-card class="fill-height" flat>
              <v-card-title> Sécurité </v-card-title>

              <v-card-text>
                <v-container fluid>
                  <v-form style="max-width: 70%">
                    <v-row justify="start">
                      <v-col>
                        <v-text-field
                          label="Adresse e-mail de connexion"
                          type="email"
                          filled
                          v-model="user_data.profile.email"
                          color="blue accent-4"
                          prepend-inner-icon="mdi-email"
                          disabled
                        >
                        </v-text-field>
                      </v-col>
                      <v-col align-self="center">
                        <v-btn
                          outlined
                          color="blue accent-4"
                          @click="openReAuthDialog(1)"
                          ><v-icon left>mdi-pencil</v-icon> Modifier l'adresse
                          e-mail</v-btn
                        >
                      </v-col>
                    </v-row>

                    <v-row justify="start">
                      <v-col>
                        <v-text-field
                          label="Mot de passe"
                          type="password"
                          filled
                          color="blue accent-4"
                          prepend-inner-icon="mdi-lock"
                          disabled
                        >
                        </v-text-field>
                      </v-col>
                      <v-col align-self="center">
                        <v-btn
                          outlined
                          color="blue accent-4"
                          @click="openReAuthDialog(2)"
                          ><v-icon left>mdi-pencil</v-icon> Modifier le mot de
                          passe</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-skeleton-loader>
    <v-overlay :value="showSaved" z-index="100" opacity="0.4">
      <v-icon size="60" color="#74D9C4">mdi-check-circle</v-icon>
    </v-overlay>

    <PopupSecurity
      :user_data="user_data"
      :popupSecurity="popupSecurity"
      v-on:securityDialogToParent="securityDialogFromChild"
      v-on:updateEmailToParent="updateEmail"
      v-on:updatePasswordToParent="updatePassword"
      :type="popupType"
      :emailRules="emailRules"
      :feedback="feedback"
    />

    <PopupReAuth
      :securityType="popupType"
      :popupReAuth="popupReAuth"
      :loadingEmail="loadingEmail"
      v-on:popupReAuthDialogToParent="popupReAuthDialogFromChild"
      v-on:securityDialogToParent="openSecurityDialog"
    />

    <v-snackbar v-model="snackbar" :timeout="3000">
      Changement enregistrés !

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snackbar = false"
          color="green accent-4"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase";
import PopupSecurity from "./components/PopupSecurity";
import PopupReAuth from "./components/PopupReAuth";

export default {
  name: "Account",
  components: {
    PopupSecurity,
    PopupReAuth,
  },
  data() {
    return {
      loading: true,
      types: ["vitrine", "e-commerce", "blog"],
      sectors: [
        "Agriculture",
        "Agriculture raisonnée",
        "Elevage",
        "Ressources minières & hydrocarbures",
        "Agroalimentaire",
        "Métiers de bouche",
        "Alcools",
        "Boulangerie",
        "Boucherie",
        "Produits Marins / Pêche",
        "Primeur",
        "Epicerie",
        "Gastronomie",
        "Vin",
        "Boisson",
        "Restauration",
        "Food-tech",
        "Tabac",
        "Textile",
        "Mode",
        "Bois",
        "Menuiserie",
        "Mobilier",
        "Papier / Carton",
        "Imprimerie / Reproduction",
        "Raffinage / Cokéfaction",
        "Chimie",
        "Pharmaceutique",
        "Plastique",
        "Métallurgie",
        "Matériaux",
        "Artisanat",
        "Poterie",
        "Armement",
        "Outillage",
        "Electronique",
        "Télécommunication",
        "Horlogerie",
        "Joaillerie / Bijouterie",
        "Santé",
        "Electroménager",
        "Machines & Equipement",
        "Industrie",
        "Automobile",
        "Transports",
        "Musique",
        "Sport",
        "Jouets",
        "Papeterie",
        "Réparation",
        "Energies - Gaz - Electricité",
        "Production et distribution de l'eau",
        "Construction",
        "Immobilier",
        "BTP",
        "Génie civil / Travaux Publics",
        "Commerce de gros",
        "Commerce de détail / Distribution",
        "Grande distribution",
        "Multi-commerce",
        "Décoration",
        "Alimentaire",
        "Habillement",
        "Logistique",
        "Manutention",
        "Poste et courrier",
        "Hotellerie",
        "Tourisme",
        "Gites",
        "Hébergements touristiques",
        "Location saisonnière",
        "Débit de boissons",
        "Traiteur",
        "Information et communication",
        "Edition",
        "Presse",
        "Logiciels",
        "Jeux vidéos",
        "SAAS",
        "Audiovisuel",
        "Cinéma",
        "Télévision",
        "Publicité",
        "Radio / Podcast",
        "Médias",
        "Evénementiel",
        "Conseil IT",
        "Programmation / Développement informatique",
        "Data",
        "Services hébergement et données Web",
        "Relations Presses / Publiques",
        "Publicité internet",
        "Assurance",
        "Banque",
        "Finance",
        "Crédit",
        "Investissement",
        "Capital risque",
        "Retraite",
        "Silver économie",
        "Courtage",
        "Comptabilité",
        "Gestion de patrimoine",
        "Juridique",
        "Audit",
        "Conseil fiscal",
        "Conseil de gestion",
        "Conseil ressources humaines",
        "Ressources humaines",
        "Conseil en organisation",
        "Gestion de projet",
        "Architecture",
        "Urbanisme",
        "Paysagisme",
        "Ingénierie",
        "Etudes techniques",
        "R&D - Recherche et développement",
        "Biotechnologies",
        "Recherche académique",
        "Sciences humaines et sociales",
        "Régie publicitaire",
        "Agence publicitaire",
        "Emailing",
        "Marketing",
        "Etudes de marché et sondages",
        "Design",
        "Photographie",
        "Traduction - Interprétation",
        "Sécurité",
        "Activité vétérinaires",
        "Services administratifs",
        "Leasing",
        "Location de matériel",
        "Propriété intellectuelle",
        "Interim",
        "Recrutement",
        "Agence de voyage",
        "Nettoyage",
        "Centres d'appels",
        "Organisation salons professionnels",
        "Services aux entreprises",
        "Administration publique",
        "Défense",
        "Santé - Service publique",
        "Affaires étrangères",
        "Justice",
        "Enseignement / Education",
        "Formation professionnelle",
        "Enseignement des langues",
        "Activités hospitalières",
        "Médecins",
        "Hébergement médicalisé",
        "Action sociale",
        "Aide à domicile",
        "Arts",
        "Activités récréatives",
        "Spectacles",
        "Spectacle vivant",
        "Bibliothèques, archives, musées & autres services culturels",
        "Jeux de hasard et d'argent",
        "Club de sport",
        "Coach sportif",
        "Loisirs",
        "Associatif",
        "Syndicats",
        "Coiffure",
        "Soins de beauté - esthétique",
        "Services funéraires",
        "Cosmétique",
        "Coach en développement personnel",
        "Thérapeute",
        "Naturopathe",
        "Médecine alternative",
        "Yoga",
        "Bien-être",
        "Economie sociale et solidaire",
        "Humanitaire & Développement",
        "Solidarités",
        "Jeunesse",
        "DIY",
        "Couture",
        "Autre",
      ],
      tab: null,
      emailEdit: false,
      emailRules: [
        (v) => !!v || "Veuillez rentrer une adresse e-mail valide",
        (v) =>
          /.+@.+\..+/.test(v) || "Veuillez rentrer une adresse e-mail valide",
      ],
      nameRules: [(v) => !!v || "Ce champs est obligatoire"],
      phoneRules: [
        (v) => !!v || "Veuillez rentrer un numéro de téléphone valide",
        (v) =>
          /^\d{10}$/.test(v) ||
          "Veuillez rentrer un numéro de téléphone valide",
      ],
      passwordRules: [(v) => !!v || "Veuillez rentrer un mot de passe"],
      showSaved: false,
      newPassword: "",
      type: "password",
      pictureDialog: false,
      loadingImage: false,
      popupSecurity: false,
      popupType: 1,
      feedback: null,
      popupReAuth: false,
      loadingEmail: false,
      img: null,
      snackbar: false,
      availabilities: ["Moins de 2h", "Entre 2h à 5h", "Plus de 5h"],
    };
  },
  computed: {
    ...mapState(["user_data", "project", "pages", "userCourseDoc"]),
    profilePictureIcon: function () {
      if (this.user_data.profilePicture) {
        return "mdi-pencil";
      } else {
        return "mdi-plus";
      }
    },
  },
  methods: {
    updateProfile() {
      this.$store.dispatch("updateUserProfile").then(() => {
        this.snackbar = true;
      });
    },
    updateProject() {
      this.$store.dispatch("updateUserProject").then(() => {
        this.snackbar = true;
      });
    },
    updateShortProject() {
      this.$store.dispatch("updateShortUserProject").then(() => {
        this.snackbar = true;
      });
    },
    uploadImg(e) {
      let storageRef = firebase.storage().ref();
      let file = e.target.files[0];

      if (file) {
        let imgRef = storageRef.child(
          `user_${this.user_data.profile.user_id}/profile/${file.name}`
        );

        imgRef.put(file).then((snapshot) => {
          console.log("Uploaded an image");
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.$store
              .dispatch("updateProfilePicture", downloadURL)
              .then(() => {
                this.$store.dispatch("setUserData");
              });
          });
        });
      }
    },
    pickImg() {
      document.querySelector("#profile-img").click();
    },
    getInitials(name) {
      return name
        .split(" ")
        .map((n) => n[0])
        .join("");
    },
    updateRegularFields() {
      this.loading = true;
      this.$store
        .dispatch("updateProfile", {
          projectName: this.project.name,
          name: this.user_data.profile.name,
          tel: this.user_data.tel,
        })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.showSaved = true;
            setTimeout(() => {
              this.showSaved = false;
            }, 2000);
          }, 1000);
        });
    },
    openSecurityDialog(type) {
      console.log(type);
      this.popupType = type;
      this.popupSecurity = true;
    },
    openReAuthDialog(type) {
      this.popupType = type;
      this.popupReAuth = true;
    },
    securityDialogFromChild() {
      this.popupSecurity = false;
    },
    popupReAuthDialogFromChild() {
      this.popupReAuth = false;
    },
    isValidEmail(email) {
      return /.+@.+\..+/.test(email);
    },
    updateEmail(email) {
      if (this.isValidEmail(email)) {
        this.loadingEmail = true;
        this.feedback = null;
        this.$store.dispatch("updateEmail", email).then(() => {
          this.loadingEmail = false;
          this.securityDialogFromChild();
          this.snackbar = true;
        });
      } else {
        this.feedback = "Veuillez rentrer une adresse e-mail valide";
      }
    },
    updatePassword(newPassword) {
      var user = firebase.auth().currentUser;

      user
        .updatePassword(newPassword)
        .then(function () {
          console.log("successfully updated password");
        })
        .catch(function (error) {
          console.log(error.message);
        })
        .then(() => {
          this.securityDialogFromChild();
          this.snackbar = true;
        });
    },
  },
  async created() {
    await this.$store.dispatch("setProject")
    await this.$store.dispatch("setPages");
    await this.$store.dispatch("setUserCourseDoc", this.$route.params.course);
      setTimeout(() => {
        this.loading = false;
        console.log(this.userCourseDoc)
      }, 1200);
 
  },
};
</script>

<style>
.account-tab-active {
  color: #33495d !important;
}

#account-badge {
  cursor: pointer !important;
}

.v-input__icon--prepend-inner > i {
  color: black !important;
}

.disabled-email:hover {
  cursor: not-allowed !important;
}
</style>