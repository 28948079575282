<template>
  <v-container v-if="allDataLoaded" fluid class="pa-6">
    <v-row>
      <v-card class="px-2" flat width="100%">
        <v-row class="px-6 pt-4 mb-0">
          <v-col>
            <v-btn
              text
              small
              class="ml-n5"
              @click="goBackToWebinars"
              :loading="loading"
              ><v-icon left>mdi-chevron-left</v-icon> Retour</v-btn
            >
          </v-col>
        </v-row>
        <v-card-title>
          {{ webinar.title }}
          <v-spacer></v-spacer>
          <v-btn text small v-if="webinar.file" :href="webinar.file" target="_blank"><v-icon left>mdi-download</v-icon>Télécharger la présentation</v-btn>
        </v-card-title>
        <v-card-subtitle
          class="pt-2 new-grey--text caption"
          style="text-transform: capitalize"
        >
          <v-avatar size="30">
            <v-img
              :src="webinar.animator.pic"
              v-if="webinar.animator.pic != ''"
            ></v-img>
            <v-icon v-else>mdi-account-circle</v-icon>
          </v-avatar>
          <span class="pl-2">{{ webinar.animator.name }}</span> |

          <v-chip
            small
            style="background: white; color: #88a6c9"
            color="#88a6c9"
            class="pr-0"
          >
            <v-icon left small>mdi-calendar-month</v-icon>
            {{
              webinar.date.toDate().toLocaleDateString("fr-FR", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}</v-chip
          >
          |
          <v-chip outlined small
            ><v-icon left small>{{ getWebinarIcon(webinar.filter) }}</v-icon
            >{{ webinar.category }}</v-chip
          >
        </v-card-subtitle>
        <div class="video-responsive-2">
          <iframe
            :src="webinar.url"
            frameborder="0"
            allow="autoplay; fullscreen"
            width="560"
            height="315"
            allowfullscreen
          ></iframe>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Webinar",
  data() {
    return {
      allDataLoaded: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(["webinar"]),
  },
  methods: {
    getWebinarIcon(id) {
      if (id === 1) {
        return "mdi-magnify";
      } else if (id === 2) {
        return "mdi-bullhorn";
      } else if (id === 4) {
        return "mdi-code-tags";
      } else if (id === 0) {
        return "mdi-comment-outline";
      }
    },
    goBackToWebinars() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$router.push({
          name: "webinars",
          params: { course: this.$route.params.course },
        });
      }, 1000);
    },
    chipColor(cat) {
      if (cat === "SEO") {
        return "green accent-4";
      } else if (cat === "Communication") {
        return "orange accent-4";
      } else if (cat === "Marketing") {
        return "blue accent-4";
      }
    },
  },
  mounted() {
    this.$store
      .dispatch("setWebinar", { id: this.$route.params.id })
      .then(() => {
        this.allDataLoaded = true;
      });
  },
};
</script>

<style>
.video-responsive-2 {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive-2 > iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 8px;
}
</style>