<template>
  <v-dialog v-model="popup" width="500" persistent :retain-focus="false">
    <v-card v-if="type === 1">
      <v-card-title class="headline" style="background-color: #edf4ff">
        Créer une maquette
        <v-icon @click="togglePopUp" style="position: absolute; right: 17px"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="px-6 py-4 mt-2">
        <v-text-field
          outlined
          rounded
          color="blue accent-4"
          label="titre de votre maquette"
          :placeholder="samplePage"
          v-model="newPage"
          @keyup.enter="addPage"
        ></v-text-field>
      </v-card-text>
      <div class="container center-align" v-if="feedback">
        <p class="caption red--text">{{ feedback }}</p>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="blue accent-4" text @click="addPage" :loading="loading"
          >Créer</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card v-else-if="type == 2">
      <v-card-title class="headline" style="background-color: #edf4ff">
        Modifier la maquette
        <span class="font-italic ml-2">{{ page.name }}</span>

        <v-icon @click="togglePopUp" style="position: absolute; right: 17px"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="px-6 py-4 mt-2">
        <v-text-field
          outlined
          rounded
          color="blue accent-4"
          label="titre de votre maquette"
          :placeholder="currentPage.name"
          v-model="newPage"
          @keyup.enter="editPage"
        ></v-text-field>
      </v-card-text>
      <div class="container center-align" v-if="feedback">
        <p class="caption red--text">{{ feedback }}</p>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue accent-4" text @click="editPage" :loading="loading"
          >Valider</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card v-else>
      <v-card-title class="headline" style="background-color: #edf4ff">
        Supprimer la maquette
        <span class="font-italic ml-2">{{ currentPage.name }}</span>
        <v-icon @click="togglePopUp" style="position: absolute; right: 17px"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="px-6 py-4 mt-2">
        Souhaitez-vous réellement <strong>supprimer</strong> la maquette
        {{ currentPage.name }} ?
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#F4697A" text @click="deletePage" :loading="loading"
          >Supprimer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db } from "@/firebase/init";
import slugify from "slugify";

export default {
  name: "PopupNewPage",
  props: ["pages", "user", "project", "popup", "page", "type", "currentPage"],
  data() {
    return {
      newPage: null,
      dummyPages: [
        "Accueil",
        "Équipe",
        "Contact",
        "Nos offres",
        "Nos services",
        "Notre histoire",
      ],
      feedback: null,
      loading: false,
    };
  },
  computed: {
    samplePage: function () {
      return this.dummyPages[
        Math.floor(Math.random() * this.dummyPages.length)
      ];
    },
  },
  methods: {
    togglePopUp() {
      this.$store.commit("togglePopUp");
    },
    addPage() {
      if (this.newPage) {
        let pageSlug = slugify(this.newPage, {
          replacement: "-",
          remove: /[*+~.()'"!:@]/g,
          lower: true,
        });

        const isUnique = (page) => page.slug !== pageSlug;
        const allreadyExists = (page) => page.slug === pageSlug;

        if (this.pages.every(isUnique) && this.newPage) {
          this.loading = true;

          db.collection(
            `users/${this.user.uid}/project/${this.project.slug}/pages`
          )
            .doc()
            .set({
              name: this.newPage,
              rows: [],
              type: "page",
              keywords: [],
              metaDescription: "",
              metaTitle: "",
              slug: pageSlug,
              showInMenu: true,
              showInFooter: true,
              toBeInstalled: true,
              index: this.pages.length,
            })
            .then(() => {
              this.$store.dispatch("setPages").then(() => {
                this.$store.dispatch("setCurrentPage", pageSlug).then(() => {
                  this.$store.dispatch("setRows").then(() => {
                    this.newPage = null;
                    this.loading = false;
                    this.feedback = null;
                    this.$store.commit("togglePopUp");
                  });
                });
              });
            });
        } else if (this.pages.some(allreadyExists) && this.newPage) {
          this.feedback = `Attention, la page ${this.newPage} existe déjà, veuillez choisir un autre nom !`;
          this.newPage = null;
        }
      } else {
        this.feedback = "Veuillez choisir un nom valide pour votre page";
      }
    },
    editPage() {
      if (this.newPage) {
        let pageSlug = slugify(this.newPage, {
          replacement: "-",
          remove: /[*+~.()'"!:@]/g,
          lower: true,
        });

        let filteredPages = this.pages;
        filteredPages.filter((page) => {
          return page !== this.currentPage;
        });

        const isUnique = (page) => page.slug !== pageSlug;
        const allreadyExists = (page) => page.slug === pageSlug;

        if (filteredPages.every(isUnique)) {
          this.feedback = null;
          this.loading = true;
          db.collection(
            `users/${this.user.uid}/project/${this.project.slug}/pages/`
          )
            .where("slug", "==", this.currentPage.slug)
            .get()
            .then((query) => {
              const document = query.docs[0];

              document.ref
                .update({
                  name: this.newPage,
                  slug: pageSlug,
                })
                .then(() => {
                  this.$store.dispatch("setPages").then(() => {
                    this.newPage = null;
                    this.loading = false;
                    this.togglePopUp();
                  });
                });
            });
        } else if (filteredPages.some(allreadyExists)) {
          this.feedback = `Attention, la page ${this.newPage} existe déjà, veuillez choisir un autre nom !`;
          this.newPage = null;
        }
      } else {
        this.togglePopUp();
        this.feedback = null;
      }
    },
    deletePage() {
      this.loading = true;
      db.collection(
        `users/${this.user.uid}/project/${this.project.slug}/pages/`
      )
        .where("slug", "==", this.currentPage.slug)
        .get()
        .then((query) => {
          const document = query.docs[0];
          document.ref.delete().then(() => {
            this.$store.dispatch("setPages").then(() => {
              this.$store
                .dispatch("setCurrentPage", false)
                .then(() => {
                  this.confirmPageToDelete = null;
                  this.loading = false;
                  this.feedback = null;
                  this.togglePopUp();
                });
            });
          });
        });
    },
    onChangeUpdatePageInMenu(slug) {
      this.emitLoadingPagesToParent(true);
      this.$store.dispatch("setCurrentPage", slug).then(() => {
        this.$store
          .dispatch("setPageToBeInMenu", this.currentPage.showInMenu)
          .then(() => {
            this.emitLoadingPagesToParent(false);
            this.$store.dispatch("setCurrentPage", slug);
          });
      });
    },
    emitLoadingPagesToParent(value) {
      this.$emit("loadingPagesToParent", value);
    },
    onChangeUpdatePageInFooter(slug) {
      this.emitLoadingPagesToParent(true);
      this.$store.dispatch("setCurrentPage", slug).then(() => {
        this.$store
          .dispatch("setPageToBeInFooter", this.currentPage.showInFooter)
          .then(() => {
            this.emitLoadingPagesToParent(false);
            this.$store.dispatch("setCurrentPage", slug);
          });
      });
    },
  },
};
</script>

<style >
</style>