<template>
  <div>
    <v-navigation-drawer
      permanent
      fixed
      hide-overlay
      color="#f4f7fa"
      light
      left
      height="100%"
      width="45%"
      style="margin-top: 47px"
    >
      <v-container class="px-8" v-if="currentPage.slug">
        <v-row align="center" justify="center">
          <h4 class="headline font-weight-black">
            Télécharger votre maquette de page {{ currentPage.name }}
          </h4>
        </v-row>

        <v-row align="center" justify="center" class="px-4">
          <p style="text-align: justify">
            Téléchargez vos maquettes en 1 clic puis intégrez-les rapidement sur
            votre site grâce à Divi !
          </p>
        </v-row>

        <v-row align="center" justify="center" class="px-4">
          <v-btn dark color="blue accent-4" @click="setJsonContent" outlined
            >Télécharger <v-icon right>mdi-download</v-icon></v-btn
          >
        </v-row>
      </v-container>

      <v-container v-else class="px-8">
        <v-row align="center" justify="center">
          <h4 class="headline font-weight-black">
            Télécharger votre maquette de page
          </h4>
        </v-row>
        <v-row align="center" justify="center">
          <p style="text-align: justify">
            Veuillez sélectionner une maquette à télécharger depuis le menu
            déroulant juste au-dessus ⬆
          </p>
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { mapState } from "vuex";

export default {
  name: "LabSideInstall",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState([
      "currentPage",
      "project",
      "mainJson",
      "jsonBlocks",
      "rows",
      "user_data",
    ]),
  },
  methods: {
    onChangeUpdate() {
      this.$store.dispatch(
        "setPageToBeInstalled",
        this.currentPage.toBeInstalled
      );
    },
    setJsonContent() {
      var ids = [];
      var metaJson = "";
      var fullMetaJson = "";

      this.rows.forEach((row) => {
        if (row.item) {
          ids.push(row.item.id);
        }
      });

      ids.forEach((id) => {
        metaJson += this.jsonBlocks[`${id}`];
      });

      fullMetaJson = this.mainJson.open + metaJson + this.mainJson.closed;

      var jsFile = {
        context: "et_builder",
        data: {
          232444: fullMetaJson,
        },
        defaults: {},
        images: [],
      };

      var content = JSON.stringify(jsFile);

      var filename = `${this.currentPage.slug}.json`;

      var blob = new Blob([content], {
        type: "application/json",
      });

      saveAs(blob, filename);
    },
    downloadJsonPage() {},
  },
  mounted() {
    this.$store.dispatch("setMainJson").then(() => {
      this.$store.dispatch("setJsonBlocks");
    });
  },
};
</script>

<style scoped>
.v-label.theme--light label {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: black !important;
}
</style>