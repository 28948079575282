var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-6",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pl-6",staticStyle:{"display":"block"}},[_c('p',{staticClass:"headline font-weight-bold black--text mb-0"},[_vm._v(" Tâches à réaliser ")]),_c('v-subheader',{staticClass:"pl-0"},[_vm._v(" Organisez au mieux vos différentes tâches et restez à jour 😎 ")])],1),_c('v-row',{staticClass:"px-4",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"4"}},[_c('v-skeleton-loader',{staticClass:"flex d-flex flex-column",attrs:{"type":"card","loading":_vm.dataLoading}},[_c('v-card',{staticClass:"dashboard-card flex d-flex flex-column",attrs:{"min-height":"300"}},[_c('v-card-title',{staticClass:"justify-center body-1"},[_vm._v("🔴 A faire "),_c('span',{staticClass:"grey--text font-weight-regular pl-1"},[_vm._v("("+_vm._s(_vm.tasksToDo.length)+")")])]),_c('v-card-text',_vm._l((_vm.tasksToDo),function(task){return _c('v-card',{key:task.id,staticClass:"list-group-item ma-2 border-secondary",attrs:{"flat":""}},[_c('v-row',{staticClass:"mb-0 pr-0",staticStyle:{"max-height":"80px"},attrs:{"justify":"end"}},[_c('v-col',{staticClass:"px-0 mr-0",attrs:{"cols":"5"}},[_c('v-select',{staticClass:"body-1 status-select",attrs:{"items":_vm.statuses,"color":"blue accent-4","item-color":"blue accent-4","dense":""},on:{"change":function($event){return _vm.updateTasks(task.id, task.state)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"status-chip",staticStyle:{"background":"white"},attrs:{"small":"","label":""}},[_c('v-icon',{attrs:{"color":_vm.statusColor(item.value),"small":"","left":""}},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(item.text)+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(task.state),callback:function ($$v) {_vm.$set(task, "state", $$v)},expression:"task.state"}})],1)],1),_c('v-card-title',{staticClass:"text-body-2 font-weight-medium black--text py-0",staticStyle:{"word-break":"keep-all"}},[_vm._v(_vm._s(task.title)+" ")])],1)}),1)],1)],1)],1),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"4"}},[_c('v-skeleton-loader',{staticClass:"flex d-flex flex-column",attrs:{"type":"card","loading":_vm.dataLoading}},[_c('v-card',{staticClass:"dashboard-card flex d-flex flex-column",attrs:{"min-height":"300"}},[_c('v-card-title',{staticClass:"justify-center body-1"},[_vm._v("🟠 En cours "),_c('span',{staticClass:"grey--text font-weight-regular pl-1"},[_vm._v("("+_vm._s(_vm.tasksDoing.length)+")")])]),_c('v-card-text',_vm._l((_vm.tasksDoing),function(task){return _c('v-card',{key:task.id,staticClass:"list-group-item ma-2 border-secondary",attrs:{"flat":""}},[_c('v-row',{staticClass:"mb-0 pr-2",staticStyle:{"max-height":"80px"},attrs:{"justify":"end"}},[_c('v-col',{staticClass:"px-0 mr-0",attrs:{"cols":"5"}},[_c('v-select',{staticClass:"body-1 status-select",attrs:{"items":_vm.statuses,"color":"blue accent-4","item-color":"blue accent-4","dense":""},on:{"change":function($event){return _vm.updateTasks(task.id, task.state)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"status-chip",staticStyle:{"background":"white"},attrs:{"small":"","label":""}},[_c('v-icon',{attrs:{"color":_vm.statusColor(item.value),"small":"","left":""}},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(item.text)+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(task.state),callback:function ($$v) {_vm.$set(task, "state", $$v)},expression:"task.state"}})],1)],1),_c('v-card-title',{staticClass:"text-body-2 font-weight-medium black--text py-0",staticStyle:{"word-break":"keep-all"}},[_vm._v(_vm._s(task.title)+" ")])],1)}),1)],1)],1)],1),_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"4"}},[_c('v-skeleton-loader',{staticClass:"flex d-flex flex-column",attrs:{"type":"card","loading":_vm.dataLoading}},[_c('v-card',{staticClass:"dashboard-card flex d-flex flex-column",attrs:{"min-height":"300"}},[_c('v-card-title',{staticClass:"justify-center body-1"},[_vm._v("✅ Fait "),_c('span',{staticClass:"grey--text font-weight-regular pl-1"},[_vm._v("("+_vm._s(_vm.tasksDone.length)+")")])]),_c('v-card-text',_vm._l((_vm.tasksDone),function(task){return _c('v-card',{key:task.id,staticClass:"list-group-item ma-2 border-secondary",attrs:{"flat":""}},[_c('v-row',{staticClass:"mb-0 pr-2",staticStyle:{"max-height":"80px"},attrs:{"justify":"end"}},[_c('v-col',{staticClass:"px-0 mr-0",attrs:{"cols":"4"}},[_c('v-select',{staticClass:"body-1 status-select",attrs:{"items":_vm.statuses,"color":"blue accent-4","item-color":"blue accent-4","dense":""},on:{"change":function($event){return _vm.updateTasks(task.id, task.state)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"status-chip",staticStyle:{"background":"white"},attrs:{"small":"","label":""}},[_c('v-icon',{attrs:{"color":_vm.statusColor(item.value),"small":"","left":""}},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(item.text)+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(task.state),callback:function ($$v) {_vm.$set(task, "state", $$v)},expression:"task.state"}})],1)],1),_c('v-card-title',{staticClass:"text-body-2 font-weight-medium black--text py-0",staticStyle:{"word-break":"keep-all"}},[_vm._v(_vm._s(task.title)+" ")])],1)}),1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"multi-line":"","bottom":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue accent-4","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }