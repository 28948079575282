<template>
  <v-footer color="grey lighten-2" padless>
    <v-row justify="center" no-gutters class="mb-0" style="width: 100%">
      <v-list
        v-for="(page, index) in pages"
        :key="index"
        class="grey lighten-2"
      >
        <v-list-item link @click="loadPage(page.slug)" v-if="page.showInFooter">
          <v-list-item-title
            :style="[
              page.slug == currentPage.slug
                ? { color: currentPageColor }
                : { color: pagesColor },
            ]"
            class="font-weight-bold body-2"
            >{{ page.name }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-row>
    <v-row justify="center" no-gutters class="mb-0">
      <v-btn
        v-for="icon in icons"
        :key="icon"
        class="mx-4"
        icon
        color="#33495D"
      >
        <v-icon size="24px">{{ icon }}</v-icon>
      </v-btn>
      <v-col
        class="py-4 text-center grey lighten-4"
        cols="12"
        style="color: #33495d"
      >
        {{ new Date().getFullYear() }} —
        <strong>{{ project.name }}</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "secondFooter",
  data() {
    return {
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
      pagesColor: "#33495D",
      currentPageColor: "#255cee",
    };
  },
  props: [
    "pictureUrl",
    "pages",
    "step",
    "currentPage",
    "startLoading",
    "endLoading",
    "loadPage",
    "project",
    "projectName",
  ],
};
</script>