var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticStyle:{"background":"#f4f7fa"}},[(
      ![
        'builder',
        'Home',
        'onboarding',
        'project-setup',
        'installation',
        'form-free-training',
        'form-end-of-training',
        'lesson',
        'welcome' ].includes(_vm.$route.name)
    )?_c('mainNav'):_vm._e(),(
      ![
        'builder',
        'Home',
        'onboarding',
        'project-setup',
        'installation',
        'form-free-training',
        'form-end-of-training',
        'lesson',
        'welcome' ].includes(_vm.$route.name)
    )?_c('mainSide'):_vm._e(),_c('v-main',{class:{ blurBack: _vm.sessionExpired },staticStyle:{"padding-top":"20px"}},[_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.sessionExpired),callback:function ($$v) {_vm.sessionExpired=$$v},expression:"sessionExpired"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Session expirée ")]),_c('v-card-text',{staticClass:"pa-6"},[_vm._v(" Votre session a expiré. Veuillez vous reconnecter pour des raisons de sécurité. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue accent-4","text":""},on:{"click":_vm.goToLogin}},[_vm._v(" Me reconnecter ")])],1)],1)],1),_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }