<template>
  <v-container>
    <LabBar
      v-on:childToParent="onChildClick"
      v-on:gridToParent="gridFromChild"
      v-on:univToParent="univFromChild"
      v-on:expandToParent="expandFromChild"
      v-on:drawerToParent="drawerfromChild"
      :user_data="user_data"
      :user="user"
      :project="project"
      :pages="pages"
      :currentPage="currentPage"
      :popup="popup"
      :loadingChanges="loadingChanges"
      :startLoading="startLoading"
      :endLoading="endLoading"
      :expandParent="expand"
    />
    <LabSideLeft
      :blocks="blocks"
      :loadingBlocks="loadingBlocks"
      :currentPage="currentPage"
      :rows="rows"
      v-on:scrollToParent="scrollFromChild"
      v-if="!expand && drawer == 2"
    />
    <LabSideArchi
      v-else-if="!expand && drawer == 1"
      :pages="pages"
      :user="user"
      :project="project"
      :popup="popup"
      :currentPage="currentPage"
    />

    <LabSideInstall v-else-if="!expand && drawer == 3" />
    <LabSideRight />

    <v-container
      :style="[
        expand
          ? {
              maxWidth: expandedWidth,
              backgroundColor: builderColor,
              marginLeft: expandedMarginLeft,
              marginRight: expandedMarginRight,
              minHeight: buiderHeight,
            }
          : {
              maxWidth: reducedWidth,
              backgroundColor: builderColor,
              marginLeft: reducedMarginLeft,
              marginRight: reducedMarginRight,
              minHeight: buiderHeight,
            },
      ]"
      class="pa-0 mt-12 v-step-start"
    >

    <v-snackbar v-model="savedChild"  multi-line bottom>
      Vos changements ont bien été pris en compte ! 🎉

          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue accent-4"
              text
              v-bind="attrs"
              @click="savedChild = false"
            >
              Fermer
            </v-btn>
          </template>
        </v-snackbar>
      <v-skeleton-loader
        :loading="loading"
        min-width="755"
        height="150"
        width="auto"
        type="card"
      >
   
        <div class="row" v-if="!realMode">
          <img
            src="/projectSetup/menu_1.png"
            style="width: 98%; height: 100%"
          />
        </div>

        <firstMenu
          v-else
          :pages="pages"
          :step="step"
          :currentPage="currentPage"
          :startLoading="startLoading"
          :endLoading="endLoading"
          :loadPage="loadPage"
        />
        <!-- <secondMenu
          v-else-if="menu && realMode && menu.id == 2"
          :pages="pages"
          :pictureUrl="project.logo"
          :step="step"
          :currentPage="currentPage"
          :startLoading="startLoading"
          :endLoading="endLoading"
          :loadPage="loadPage"
        />
        <thirdMenuReal
          v-else-if="menu && realMode && menu.id == 3"
          :pages="pages"
          :pictureUrl="project.logo"
          :step="step"
          :currentPage="currentPage"
          :startLoading="startLoading"
          :endLoading="endLoading"
          :loadPage="loadPage"
        /> -->
      </v-skeleton-loader>

      <v-container class="mt-10" style="min-height: 500px">
        <v-skeleton-loader
          :loading="loading"
          height="300"
          width="auto"
          type="card"
          min-width="600"
        >
          <draggable :list="rows" handle=".handle">
            <div
              class="remove_link row row-drag mt-2"
              v-for="(row, parent_index) in rows"
              :key="parent_index"
            >
              <v-hover v-slot:default="{ hover }">
                <div class="col s20 px-3 pt-3 pb-0 col-drag">
                  <v-card
                    height="auto"
                    width="auto"
                    flat
                    style="z-index: 0"
                    class="
                      v-step-3
                      v-step-5
                      v-step-7
                      v-step-9
                      v-step-11
                      v-step-13
                      v-step-15
                      v-step-18
                    "
                    :id="rowId(parent_index)"
                  >
                    <img
                      v-if="row.item.src"
                      :src="row.item.src"
                      style="max-width: 100%"
                    />
                    <v-fade-transition>
                      <v-overlay
                        v-if="hover || gridActive"
                        absolute
                        color="transparent"
                        style="border: 1px solid #4d92f7; cursor: default"
                      >
                        <i class="drag-row material-icons handle">gamepad</i>
                        <i
                          class="up-row material-icons"
                          @click="upRow(parent_index)"
                          >arrow_upward</i
                        >
                        <i
                          class="down-row material-icons"
                          @click="downRow(parent_index)"
                          >arrow_downward</i
                        >

                        <i
                          class="material-icons duplicate-row"
                          @click="duplicateRow(parent_index)"
                          >content_copy</i
                        >
                        <i
                          class="delete-row material-icons"
                          @click="deleteRow(parent_index)"
                          >delete</i
                        >
                      </v-overlay>
                    </v-fade-transition>
                  </v-card>
                </div>
              </v-hover>
            </div>
          </draggable>
        </v-skeleton-loader>
      </v-container>

      <v-skeleton-loader
        :loading="loading"
        height="150"
        width="auto"
        min-width="755"
        type="card"
      >
        <div class="row align-center justify-center ma-0" v-if="!realMode">
          <img
            src="/projectSetup/footer_2.png"
            style="width: 100%; height: 100%"
          />
        </div>

        <!-- <firstFooter
          :project="project"
          :pages="pages"
          :pictureUrl="project.logo"
          :step="step"
          :currentPage="currentPage"
          :startLoading="startLoading"
          :endLoading="endLoading"
          :loadPage="loadPage"
          v-else-if="footer && realMode && footer.id == 1"
        /> -->

        <secondFooter
          :project="project"
          :pages="pages"
          :step="step"
          :currentPage="currentPage"
          :startLoading="startLoading"
          :endLoading="endLoading"
          :loadPage="loadPage"
          v-else
        />

        <!-- <thirdFooter
          :project="project"
          :pages="pages"
          :pictureUrl="project.logo"
          :step="step"
          :currentPage="currentPage"
          :startLoading="startLoading"
          :endLoading="endLoading"
          :loadPage="loadPage"
          v-else-if="footer && realMode && footer.id == 3"
        /> -->
      </v-skeleton-loader>
    </v-container>
    <!-- <v-tour name="myTour" :steps="steps" :options="myTourOptions"  :callbacks="myCallbacks" ></v-tour> -->
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { mapState, mapActions } from "vuex";
import LabBar from "./components/LabBar";
import LabSideLeft from "./components/LabSideLeft";
import LabSideArchi from "./components/LabSideArchi";
import LabSideInstall from "./components/LabSideInstall";
import LabSideRight from "./components/LabSideRight";
import firstMenu from "../projectSetup/components/firstMenu";
// import secondMenu from "../projectSetup/components/secondMenu";
// import thirdMenuReal from "../projectSetup/components/thirdMenuReal";
// import firstFooter from "./components/firstFooter";
import secondFooter from "./components/secondFooter";
// import thirdFooter from "./components/thirdFooter";

export default {
  name: "Builder",
  components: {
    LabBar,
    LabSideLeft,
    LabSideRight,
    LabSideArchi,
    LabSideInstall,
    firstMenu,
    // secondMenu,
    // thirdMenuReal,
    draggable,
    // firstFooter,
    secondFooter,
    // thirdFooter,
  },
  data() {
    return {
      drawer: 2,
      loading: false,
      realMode: false,
      step: 8,
      savedChild: false,
      gridActive: false,
      expand: false,
      expandedWidth: "100%",
      builderColor: "white",
      expandedMarginLeft: "0",
      expandedMarginRight: "0",
      buiderHeight: "760px",
      reducedMarginLeft: "45%",
      reducedMarginRight: "0",
      reducedWidth: "65%",
      expandIconPosition: "fixed",
      expandIconLeft: "0",
      expandIconTop: "0",
      reducedIconLeft: "0",
      reducedIconTop: "0",
      dataLoaded: false,
      hover: false,
    };
  },
  computed: {
    ...mapState([
      "user_data",
      "user",
      "project",
      "pages",
      "currentPage",
      "popup",
      "blocks",
      "rows",
      "loadingBuilder",
      "loadingBlocks",
      "loadingChanges",
    ]),
    expandIcon: function () {
      if (this.expand) {
        return "mdi-fullscreen-exit";
      } else {
        return "mdi-fullscreen";
      }
    },
    expandIconColor: function () {
      if (this.hover || this.expand) {
        return "#4D92F7";
      } else {
        return "rgba(0, 0, 0, 0.6)";
      }
    },
  },
  methods: {
    scrollFromChild(index) {
      let selector = `#row-${index}`;
      let element_to_scroll_to = document.querySelector(selector);

      element_to_scroll_to.scrollIntoView();
    },
    rowId(index) {
      return `row-${index}`;
    },
    myCustomNextStepCallback(currentStep) {
      let realStep = currentStep - 1;

      if (realStep === 0) {
        scrollTo(0, 0);
      }

      if (realStep === 3) {
        document.querySelector(".texts-tab").click();
      } else if (realStep === 5) {
        document.querySelector(".images-tab").click();
      } else if (realStep === 7) {
        document.querySelector(".bouttons-tab").click();
      } else if (realStep === 9) {
        document.querySelector(".avis-tab").click();
      } else if (realStep === 11) {
        document.querySelector(".faq-tab").click();
      } else if (realStep === 13) {
        document.querySelector(".contact-tab").click();
      }
    },
    expandBuilderPage() {
      this.expand = !this.expand;
    },
    onChildClick(value) {
      this.savedChild = value;
    },
    gridFromChild(value) {
      this.gridActive = value;
    },
    univFromChild(value) {
      this.realMode = value;
    },
    expandFromChild(value) {
      this.expand = value;
    },
    drawerfromChild(value) {
      this.drawer = value;
    },
    ...mapActions(["upRow", "downRow", "deleteRow", "duplicateRow"]),
    toggleSaved() {
      this.$store.dispatch("hideNotification");
    },
    startLoading() {
      this.loading = true;
    },
    endLoading() {
      this.loading = false;
    },
    loadPage(slug) {
      if (slug != this.$store.state.currentPage.slug) {
        this.$store.dispatch("saveChanges").then(() => {
          this.startLoading();
          this.$store.dispatch("setCurrentPage", slug).then(() => {
            this.$store.dispatch("setRows").then(() => {
              this.$store.dispatch("filterRows").then(() => {
                setTimeout(() => {
                  this.endLoading();
                }, 800);
              });
            });
          });
        });
      }
    },
  },
  created() {
    this.startLoading();
    this.$store.dispatch("setProject").then(() => {
      this.$store.dispatch("setPages").then(() => {
        this.$store.dispatch("setBlocks", 1).then(() => {
          this.$store.dispatch("setRows").then(() => {
            this.$store.dispatch("setInitialRows").then(() => {
              this.$store.dispatch("filterRows").then(() => {
                this.$store.dispatch("loadingBlocks", false);
                this.endLoading();
                this.dataLoaded = true;

                // this.$tours['myTour'].start();
              });
            });
          });
        });
      });
    });
  },
};
</script>

<style scoped>
.v-overlay {
  display: inline-block !important;
  align-items: flex-start !important;
}

.row-drag {
  border: none;
  position: relative;
  margin-left: 0px !important;
  margin-right: 30px;
  margin-top: 0 !important;
  margin-bottom: 10px !important;
  padding: 10px !important;
  height: auto !important;
  width: 100%;
}

.col-drag:hover {
  border: none !important;
}

.col-drag {
  width: auto !important;
}

.duplicate-row {
  color: grey;
  font-size: 16px;
  cursor: pointer;
  top: 5px;
  right: 20px;
  position: absolute;
}

.duplicate-row:hover {
  color: #74d9c4;
}

.delete-row {
  color: grey;
  font-size: 16px;
  cursor: pointer;
  right: 4px;
  top: 5px;
  position: absolute;
}

.delete-row:hover {
  color: #f4697a;
}

.drag-row {
  color: grey;
  font-size: 22px;
  cursor: move;
  left: 4px;
  top: 5px;
  position: absolute;
  font-weight: 900;
}

.drag-row:hover {
  color: #4d92f7;
}

.up-row {
  color: grey;
  font-size: 22px;
  cursor: pointer;
  left: 28px;
  top: 5px;
  position: absolute;
  font-weight: 900;
}

.up-row:hover {
  color: #4d92f7;
}

.down-row {
  color: grey;
  font-size: 22px;
  cursor: pointer;
  left: 49px;
  top: 5px;
  position: absolute;
  font-weight: 900;
}

.down-row:hover {
  color: #4d92f7;
}
</style>