<template>
  <v-app-bar
    color="white"
    fixed
    left
    app
    style="box-shadow: 0 12px 50px 2px #13507c24"
  >
    <v-btn
      text
      color="black"
      class="font-weight-medium mr-10"
      @click="exitBuilder"
    >
      <v-icon left>mdi-chevron-left</v-icon>Retour
    </v-btn>
    <v-chip label style="background: white" class="ml-10 dashboard-card pa-6">
      <v-switch
        v-model="switchMode"
        class="pt-6 v-step-17"
        color="blue accent-4"
        :loading="loadingUniv"
        @change="emitUnivToParent"
      ></v-switch>
      {{ Switch }}
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on }">
          <v-icon small right v-on="on" color="#88a6c9">mdi-help-circle</v-icon>
        </template>
        <span
          >Alternez entre le mode édition et le mode réel pour mieux visualiser
          vos maquettes</span
        >
      </v-tooltip>
    </v-chip>
    <v-spacer></v-spacer>
    <v-menu
      bottom
      left
      offset-y
      min-width="280"
      max-height="460"
      open-on-hover
      transition="slide-y-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          v-if="project && currentPage"
          class="dashboard-card black--text"
          style="background: white; min-width: 280px"
        >
          <!-- <v-icon left size="20">mdi-file-document-multiple</v-icon> -->
          <span
            v-if="currentPage.name"
            style="text-transform: capitalize; color: #255cee"
            class="font-weight-medium"
            >{{ currentPage.name }}</span
          >
          <span
            v-else
            style="text-transform: capitalize"
            class="font-weight-medium"
            >Mes maquettes</span
          >
          <v-icon size="18" right>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list dense nav>
        <v-subheader class="font-weight-black black--text text-uppercase">
          Mes maquettes
        </v-subheader>
        <div class="container center-align" v-if="pages.length === 0">
          <div class="row">
            <span class="caption grey--text pl-2">
              Aucune maquette créée pour le moment !
            </span>
          </div>
          <div class="row justify-center">
            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  icon
                  class="font-weight-black"
                  v-on="on"
                  @click="showPopUp"
                  @mouseover="hover = true"
                  @mouseleave="hover = false"
                >
                  <v-icon :color="editIconColor">mdi-plus-box-multiple</v-icon>
                </v-btn>
              </template>
              <span>Créer une nouvelle maquette</span>
            </v-tooltip>
          </div>
        </div>

        <v-list-item link v-for="(page, index) in pages" :key="index">
          <v-list-item-title
            class="font-weight-bold"
            style="cursor: pointer"
            :style="[
              page.slug == currentPage.slug
                ? { color: currentPageColor }
                : { color: pagesColor },
            ]"
            @click="loadPage(page.slug)"
            >{{ page.name }}</v-list-item-title
          >
          <!-- <v-list-item-action>
              <v-btn
                icon
                @click="editPopUp(page, page.slug)"

              >
                <v-icon  size="18">mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-action> -->
          <v-list-item-action>
            <v-btn icon @click="deletePopUp(page)">
              <v-icon size="18">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-spacer></v-spacer>

    <v-tooltip bottom color="black">
      <template v-slot:activator="{ on }">
        <v-btn
          style="background: white; min-height: 36px"
          class="mx-2 pa-0 dashboard-card"
          small
          v-on="on"
          @click="showPopUp"
        >
          <v-icon>mdi-plus-box-multiple</v-icon>
        </v-btn>
      </template>
      <span>Créer une nouvelle maquette</span>
    </v-tooltip>

    <v-tooltip bottom color="black">
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-btn
            style="background: white; min-height: 36px"
            class="mx-2 pa-0 dashboard-card"
            @click="saveChanges"
            :loading="loadingChanges"
            small
            :disabled="!currentPage.slug || pages.length === 0"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </div>
      </template>
      <span v-if="!currentPage.slug || pages.length === 0"
        >Veuillez sélectionner une maquette pour pouvoir la sauvegarder !</span
      >
      <span v-else>Sauvegarder</span>
    </v-tooltip>

    <v-tooltip bottom color="black">
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-btn
            small
            style="background: white; min-height: 36px"
            class="mx-2 pa-0 dashboard-card"
            @click="setJsonContent"
            :loading="loadingDownload"
            :disabled="!currentPage.slug || pages.length === 0"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
      </template>

      <span v-if="!currentPage.slug || pages.length === 0"
        >Veuillez sélectionner une maquette pour pouvoir la télécharger !</span
      >
      <span v-else>Télécharger</span>
    </v-tooltip>

    <PopupNewPage
      :pages="pages"
      :user="user"
      :project="project"
      :popup="popup"
      :page="page"
      :type="popupType"
      :currentPage="currentPage"
    />
  </v-app-bar>
</template>

<script>
import PopupNewPage from "./PopupNewPage";
import { saveAs } from "file-saver";
import { mapState } from "vuex";

export default {
  name: "LabBar",
  components: {
    PopupNewPage,
  },
  props: [
    "user_data",
    "user",
    "project",
    "pages",
    "currentPage",
    "popup",
    "loadingChanges",
    "startLoading",
    "endLoading",
    "expandParent",
  ],
  data() {
    return {
      hover: false,
      popupType: 3,
      page: null,
      switchMode: false,
      pagesColor: "rgba(0, 0, 0, 0.6)",
      currentPageColor: "#255CEE",
      childSavedState: false,
      childGridState: false,
      childUnivState: false,
      loadingUniv: false,
      childExpand: false,
      childDrawer: 2,
      navColor: "transparent",
      loadingDownload: false,
    };
  },
  computed: {
    ...mapState(["mainJson", "jsonBlocks", "rows"]),
    Switch: function () {
      if (this.switchMode) {
        return "Mode réel";
      } else {
        return "Mode édition";
      }
    },
    deleteIconColor: function () {
      if (this.hover) {
        return "#F4697A";
      } else {
        return "grey lighten-1";
      }
    },
    editIconColor: function () {
      if (this.hover) {
        return "blue accent-4";
      } else {
        return "grey lighten-1";
      }
    },
    saveIconColor: function () {
      if (this.hover) {
        return "green accent-4";
      } else {
        return "rgba(0, 0, 0, 0.6)";
      }
    },
    progressIconColor: function () {
      if (this.hover) {
        return "blue accent-4";
      } else {
        return "rgba(0, 0, 0, 0.6)";
      }
    },
    exitIconColor: function () {
      if (this.hover) {
        return "#F4697A";
      } else {
        return "rgba(0, 0, 0, 0.6)";
      }
    },
    gridIconColor: function () {
      if (this.hover || this.childGridState) {
        return "blue accent-4";
      } else {
        return "rgba(0, 0, 0, 0.6)";
      }
    },
    gridIcon: function () {
      if (this.childGridState) {
        return "mdi-grid-off";
      } else {
        return "mdi-grid";
      }
    },
    expandIcon: function () {
      if (this.childExpand || this.expandParent) {
        return "mdi-fullscreen-exit";
      } else {
        return "mdi-fullscreen";
      }
    },
    expandIconColor: function () {
      if (this.hover || this.childExpand || this.expandParent) {
        return "blue accent-4";
      } else {
        return "rgba(0, 0, 0, 0.6)";
      }
    },
    firstDrawerColor: function () {
      if (this.childDrawer == 1) {
        return "blue accent-4";
      } else {
        return "rgba(0, 0, 0, 0.6)";
      }
    },
    secondDrawerColor: function () {
      if (this.childDrawer == 2) {
        return "blue accent-4";
      } else {
        return "rgba(0, 0, 0, 0.6)";
      }
    },
    thirdDrawerColor: function () {
      if (this.childDrawer == 3) {
        return "blue accent-4";
      } else {
        return "rgba(0, 0, 0, 0.6)";
      }
    },
    Undoable: function () {
      if (this.canUndo == 1) {
        return false;
      } else {
        return true;
      }
    },
    Redoable: function () {
      if (this.canRedo == 1) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    emitDrawerToParent(value) {
      this.childDrawer = value;
      this.$emit("drawerToParent", this.childDrawer);
    },
    emitToParent() {
      this.childSavedState = true;
      this.$emit("childToParent", this.childSavedState);
    },
    emitGridToParent() {
      this.childGridState = !this.childGridState;
      this.$emit("gridToParent", this.childGridState);
    },
    emitExpandToParent() {
      this.childExpand = !this.childExpand;
      this.$emit("expandToParent", this.childExpand);
    },
    emitUnivToParent() {
      let payload;
      if (this.childUnivState) {
        payload = 1;
      } else {
        payload = 2;
      }
      this.loadingUniv = true;
      this.childUnivState = !this.childUnivState;
      this.$store.dispatch("loadingBlocks", true);
      this.startLoading();

      this.$store.dispatch("setBlocks", payload).then(() => {
        this.$store.dispatch("filterRows").then(() => {
          this.$emit("univToParent", this.childUnivState);
          setTimeout(() => {
            this.$store.dispatch("loadingBlocks", false);
            this.endLoading();
            this.loadingUniv = false;
          }, 1000);
        });
      });
    },
    loadPage(slug) {
      if (slug != this.$store.state.currentPage.slug) {
        this.$store.dispatch("saveChanges").then(() => {
          this.startLoading();
          this.$store.dispatch("setCurrentPage", slug).then(() => {
            this.$store.dispatch("setRows").then(() => {
              this.$store.dispatch("filterRows").then(() => {
                setTimeout(() => {
                  this.endLoading();
                }, 800);
              });
            });
          });
        });
      }
    },
    showPopUp() {
      this.popupType = 1;
      this.$store.commit("togglePopUp");
    },
    editPopUp(page) {
      this.page = page;
      this.$store.dispatch("setCurrentPage", page.slug).then(() => {
        this.popupType = 2;
        this.$store.commit("togglePopUp");
      });
    },
    deletePopUp(page) {
      this.page = page;
      this.$store.dispatch("setCurrentPage", page.slug).then(() => {
        this.popupType = 3;
        this.$store.commit("togglePopUp");
      });
    },
    saveChanges() {
      this.$store.dispatch("loadingChanges", true);
      this.$store.dispatch("saveChanges").then(() => {
        setTimeout(() => {
          this.emitToParent();
          this.$store.dispatch("loadingChanges", false);
        }, 1000);
      });
    },
    exitBuilder() {
      this.$store.dispatch("loadingChanges", true);
      this.$store.dispatch("saveChanges").then(() => {
        this.$store.dispatch("setEmptyRows").then(() => {
          setTimeout(() => {
            this.emitToParent();
            this.$store.dispatch("loadingChanges", false);
            this.$router.push({ name: "dashboard" });
          }, 1000);
        });
      });
    },
    changeNavColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.navColor = "white";
      } else {
        this.navColor = "transparent";
      }
    },
    setJsonContent() {
      this.loadingDownload = true;
      var ids = [];
      var metaJson = "";
      var fullMetaJson = "";

      this.rows.forEach((row) => {
        if (row.item) {
          ids.push(row.item.id);
        }
      });

      ids.forEach((id) => {
        metaJson += this.jsonBlocks[`${id}`];
      });

      fullMetaJson = this.mainJson.open + metaJson + this.mainJson.closed;

      var jsFile = {
        context: "et_builder",
        data: {
          232444: fullMetaJson,
        },
        defaults: {},
        images: [],
      };

      var content = JSON.stringify(jsFile);

      var filename = `${this.currentPage.slug}.json`;

      var blob = new Blob([content], {
        type: "application/json",
      });

      saveAs(blob, filename);

      this.loadingDownload = false;
    },
  },
  mounted() {
    this.$store.dispatch("setMainJson").then(() => {
      this.$store.dispatch("setJsonBlocks");
    });
    window.onscroll = () => {
      this.changeNavColor();
    };
  },
};
</script>

<style scoped>
button:focus {
  background-color: white !important;
}

.v-tooltip__content {
  font-size: 13px !important;
}
</style>