<template>
  <v-card class="fill-height">
    <v-stepper v-model="step" class="fill-height" alt-labels>
      <v-stepper-header>
        <v-stepper-step step="1" :complete="step > 1" color="blue accent-4" ic>
          Mot de passe
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" :complete="step > 2" color="blue accent-4">
          Mes informations
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="step > 3" color="blue accent-4">
          Mon projet
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" :complete="step >= 4" color="blue accent-4">
          Confirmation
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-12" height="100%" flat>
            <v-card-title
              >Bienvenue {{ user_data.profile.name.split(" ")[0] }} ! Commençons
              par modifier votre mot de passe provisoire 🔐</v-card-title
            >

            <v-form
              v-model="isFormValid"
              autocomplete="off"
              style="max-width: 60%"
              class="py-6"
            >
              <input
                autocomplete="off"
                name="hidden"
                type="text"
                style="display: none"
              />
              <v-text-field
                autocomplete="off"
                label="Nouveau mot de passe"
                outlined
                v-model="newPassword"
                :rules="[rules.required, rules.password]"
                :append-icon="iconPassword"
                @click:append="hideAndShow"
                :type="type"
                color="blue accent-4"
              >
              </v-text-field>
              <v-text-field
                autocomplete="off"
                label="Confirmer votre mot de passe"
                outlined
                :rules="[rules.same, rules.required]"
                :append-icon="iconPassword"
                @click:append="hideAndShow"
                :type="type"
                color="blue accent-4"
                v-model="confirmedPassword"
              >
              </v-text-field>
            </v-form>
          </v-card>

          <v-btn
            color="blue accent-4"
            dark
            @click="updatePassword"
            v-if="isFormValid"
          >
            Suivant
          </v-btn>
          <v-btn v-else disabled> Suivant </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-12" height="100%" flat>
            <v-card-title>Dites-nous en plus sur vous !</v-card-title>
            <v-card-subtitle
              >Ces informations personnelles ne seront utilisées par Webmyday
              que lors de l'établissement de vos documents administratifs et
              lors de votre examen à la certification</v-card-subtitle
            >

            <v-form
              v-model="isForm2Valid"
              autocomplete="off"
              style="max-width: 60%"
              class="pa-6"
            >
              <input
                autocomplete="off"
                name="hidden"
                type="text"
                style="display: none"
              />

              <v-row justify="start" class="d-flex flex-column">
                <v-text-field
                  label="Date de naissance"
                  type="date"
                  filled
                  v-model="profile.birthdate"
                  color="blue accent-4"
                  class="flex d-flex flex-column required"
                  :rules="[rules.required]"
                >
                </v-text-field>
                <v-text-field
                  label="Lieu de naissance (Ville + Pays)"
                  placeholder="Paris, France"
                  type="address"
                  filled
                  v-model="profile.birthLocation"
                  prepend-inner-icon="mdi-pin"
                  color="blue accent-4"
                  class="flex d-flex flex-column required"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-row>

              <v-row justify="start">
                <label for="text"
                  >Genre
                  <v-radio-group
                    row
                    v-model="profile.gender"
                    :rules="[rules.required]"
                  >
                    <v-radio
                      label="Homme"
                      value="male"
                      color="blue accent-4"
                    ></v-radio>
                    <v-radio
                      label="Femme"
                      value="female"
                      color="purple accent-4"
                    ></v-radio>
                    <v-radio
                      label="Autre"
                      value="other"
                      color="grey darken-1"
                    ></v-radio>
                  </v-radio-group>
                </label>
              </v-row>

              <v-row justify="start" class="disabled-email">
                <v-text-field
                  label="Adresse e-mail"
                  type="email"
                  filled
                  v-model="user_data.profile.email"
                  class="pl-2"
                  prepend-inner-icon="mdi-email"
                  color="blue accent-4"
                  disabled
                >
                </v-text-field>
              </v-row>

              <v-row>
                <v-text-field
                  label="Adresse postale"
                  type="tel"
                  filled
                  v-model="profile.adress"
                  class="pl-2"
                  prepend-inner-icon="mdi-pin"
                  color="blue accent-4"
                  placeholder="12 rue du test, 75002, Paris"
                >
                </v-text-field>
              </v-row>
            </v-form>
          </v-card>
          <v-btn text @click="previousStep"> Retour </v-btn>
          <v-btn
            color="blue accent-4"
            dark
            @click="nextStep"
            v-if="isForm2Valid"
          >
            Suivant
          </v-btn>
          <v-btn v-else disabled> Suivant </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-12" height="100%" flat>
            <v-card-title>Dites-nous en plus sur votre projet 🚀</v-card-title>

            <v-form
              v-model="isForm3Valid"
              autocomplete="off"
              style="max-width: 60%"
              class="pa-6"
            >
              <input
                autocomplete="off"
                name="hidden"
                type="text"
                style="display: none"
              />
              <v-row justify="start">
                <v-text-field
                  label="Nom du projet ou de l'entreprise"
                  type="text"
                  filled
                  v-model="projectName"
                  color="blue accent-4"
                  prepend-inner-icon="mdi-briefcase"
                  class="required"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-row>

              <v-row justify="start">
                <v-combobox
                  label="Mon secteur d'activité"
                  v-model="project.sector"
                  outlined
                  :items="sectors"
                  color="blue accent-4"
                  prepend-inner-icon="mdi-account-hard-hat"
                >
                </v-combobox>
              </v-row>

              <v-row justify="start">
                <v-text-field
                  label="L'objectif principal de ma formation"
                  type="text"
                  filled
                  v-model="project.mainGoal"
                  color="blue accent-4"
                  prepend-inner-icon="mdi-target"
                  placeholder="Créer la vitrine digitale de mon activité et la promouvoir"
                >
                </v-text-field>
              </v-row>
            </v-form>
          </v-card>
          <v-btn text @click="previousStep"> Retour </v-btn>
          <v-btn
            color="blue accent-4"
            dark
            @click="nextStep"
            v-if="isForm3Valid"
          >
            Suivant
          </v-btn>
          <v-btn v-else disabled> Suivant </v-btn>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card class="mb-12" height="100%" flat>
            <v-img
              src="https://c.tenor.com/NTwdVVeiOXcAAAAC/minion-cest-parti.gif"
              max-width="80%"
            ></v-img>
          </v-card>

          <v-btn text @click="previousStep"> Retour </v-btn>

          <v-btn
            color="blue accent-4"
            dark
            @click="createProject"
            :loading="loading"
          >
            C'est parti !
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Session expirée </v-card-title>

        <v-card-text>
          Votre session a expiré. Veuillez vous reconnecter avec vos
          identifiants et rééessayer, merci 🔐
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue accent-4" text :to="{ name: 'Home' }">
            Me re-connecter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import firebase from "firebase";
import { db } from "@/firebase/init";
import slugify from "slugify";
export default {
  name: "ProjectSetUp",

  data() {
    return {
      overlay: true,
      step: 1,
      isFormValid: false,
      isForm2Valid: false,
      isForm3Valid: false,
      loading: false,
      newPassword: null,
      confirmedPassword: null,
      rules: {
        required: (value) => !!value || "Veuillez remplir ce champ",
        password: (value) =>
          /^(?=.*\d).{8,}$/.test(value) ||
          "Veuillez utiliser un mot de passe à 8 caractères comprenant au moins un chiffre",
        same: () =>
          this.confirmedPassword === this.newPassword ||
          "Les mots de passes ne correspondent pas",
      },
      feedback: null,
      type: "password",
      iconPassword: "mdi-eye",
      projectName: null,
      projectSlug: null,
      skeletonLoader: true,
      dummyPages: [
        "Accueil",
        "Équipe",
        "Contact",
        "Nos offres",
        "Nos services",
        "Notre histoire",
      ],
      pages: ["Accueil", "Contact"],
      types: ["vitrine", "e-commerce", "blog"],
      dialog: false,
      error: null,
      profile: {
        name: null,
        birthdate: null,
        birthLocation: null,
        gender: "male",
        tel: null,
        adress: null,
      },
      project: {
        sector: null,
        mainGoal: null,
        deadline: null,
        type: null,
        availability: null,
      },
      sectors: [
        "Agriculture",
        "Agriculture raisonnée",
        "Elevage",
        "Ressources minières & hydrocarbures",
        "Agroalimentaire",
        "Métiers de bouche",
        "Alcools",
        "Boulangerie",
        "Boucherie",
        "Produits Marins / Pêche",
        "Primeur",
        "Epicerie",
        "Gastronomie",
        "Vin",
        "Boisson",
        "Restauration",
        "Food-tech",
        "Tabac",
        "Textile",
        "Mode",
        "Bois",
        "Menuiserie",
        "Mobilier",
        "Papier / Carton",
        "Imprimerie / Reproduction",
        "Raffinage / Cokéfaction",
        "Chimie",
        "Pharmaceutique",
        "Plastique",
        "Métallurgie",
        "Matériaux",
        "Artisanat",
        "Poterie",
        "Armement",
        "Outillage",
        "Electronique",
        "Télécommunication",
        "Horlogerie",
        "Joaillerie / Bijouterie",
        "Santé",
        "Electroménager",
        "Machines & Equipement",
        "Industrie",
        "Automobile",
        "Transports",
        "Musique",
        "Sport",
        "Jouets",
        "Papeterie",
        "Réparation",
        "Energies - Gaz - Electricité",
        "Production et distribution de l'eau",
        "Construction",
        "Immobilier",
        "BTP",
        "Génie civil / Travaux Publics",
        "Commerce de gros",
        "Commerce de détail / Distribution",
        "Grande distribution",
        "Multi-commerce",
        "Décoration",
        "Alimentaire",
        "Habillement",
        "Logistique",
        "Manutention",
        "Poste et courrier",
        "Hotellerie",
        "Tourisme",
        "Gites",
        "Hébergements touristiques",
        "Location saisonnière",
        "Débit de boissons",
        "Traiteur",
        "Information et communication",
        "Edition",
        "Presse",
        "Logiciels",
        "Jeux vidéos",
        "SAAS",
        "Audiovisuel",
        "Cinéma",
        "Télévision",
        "Publicité",
        "Radio / Podcast",
        "Médias",
        "Evénementiel",
        "Conseil IT",
        "Programmation / Développement informatique",
        "Data",
        "Services hébergement et données Web",
        "Relations Presses / Publiques",
        "Publicité internet",
        "Assurance",
        "Banque",
        "Finance",
        "Crédit",
        "Investissement",
        "Capital risque",
        "Retraite",
        "Silver économie",
        "Courtage",
        "Comptabilité",
        "Gestion de patrimoine",
        "Juridique",
        "Audit",
        "Conseil fiscal",
        "Conseil de gestion",
        "Conseil ressources humaines",
        "Ressources humaines",
        "Conseil en organisation",
        "Gestion de projet",
        "Architecture",
        "Urbanisme",
        "Paysagisme",
        "Ingénierie",
        "Etudes techniques",
        "R&D - Recherche et développement",
        "Biotechnologies",
        "Recherche académique",
        "Sciences humaines et sociales",
        "Régie publicitaire",
        "Agence publicitaire",
        "Emailing",
        "Marketing",
        "Etudes de marché et sondages",
        "Design",
        "Photographie",
        "Traduction - Interprétation",
        "Sécurité",
        "Activité vétérinaires",
        "Services administratifs",
        "Leasing",
        "Location de matériel",
        "Propriété intellectuelle",
        "Interim",
        "Recrutement",
        "Agence de voyage",
        "Nettoyage",
        "Centres d'appels",
        "Organisation salons professionnels",
        "Services aux entreprises",
        "Administration publique",
        "Défense",
        "Santé - Service publique",
        "Affaires étrangères",
        "Justice",
        "Enseignement / Education",
        "Formation professionnelle",
        "Enseignement des langues",
        "Activités hospitalières",
        "Médecins",
        "Hébergement médicalisé",
        "Action sociale",
        "Aide à domicile",
        "Arts",
        "Activités récréatives",
        "Spectacles",
        "Spectacle vivant",
        "Bibliothèques, archives, musées & autres services culturels",
        "Jeux de hasard et d'argent",
        "Club de sport",
        "Coach sportif",
        "Loisirs",
        "Associatif",
        "Syndicats",
        "Coiffure",
        "Soins de beauté - esthétique",
        "Services funéraires",
        "Cosmétique",
        "Coach en développement personnel",
        "Thérapeute",
        "Naturopathe",
        "Médecine alternative",
        "Yoga",
        "Bien-être",
        "Economie sociale et solidaire",
        "Humanitaire & Développement",
        "Solidarités",
        "Jeunesse",
        "DIY",
        "Couture",
        "Autre",
      ],
      availabilities: ["Moins de 2h", "Entre 2h à 5h", "Plus de 5h"],
    };
  },
  methods: {
    nextStep() {
      this.step++;
    },
    previousStep() {
      this.step--;
    },
    async updatePassword() {
      var user = firebase.auth().currentUser;
      user
        .updatePassword(this.newPassword)
        .then(() => {
          this.nextStep();
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
          this.loading = false;
          this.dialog = true;
        });
    },
    hideAndShow() {
      this.type = this.type === "password" ? "text" : "password";
      if (this.type === "text") {
        this.iconPassword = "mdi-eye-off";
      } else {
        this.iconPassword = "mdi-eye";
      }
    },
    createProject() {
      this.loading = true;
      let slug = slugify(this.projectName, {
        replacement: "-",
        remove: /[*+~.()'"!:@]/g,
        lower: true,
      });
      db.collection(`users`)
        .doc(this.user_data.profile.user_id)
        .update({
          "project.onboarding": true,
          "project.sector": this.project.sector,
          "project.mainGoal": this.project.mainGoal,
          "project.deadline": this.project.deadline,
          "project.availability": this.project.availability,
          "profile.birthdate": this.profile.birthdate,
          "profile.birthLocation": this.profile.birthLocation,
          "profile.gender": this.profile.gender,
          "profile.adress": this.profile.adress,
        })
        .then(() => {
          db.collection(`users/${this.user_data.profile.user_id}/project`)
            .doc(`${slug}`)
            .set({
              name: this.projectName,
              slug: slug,
              progress: {
                domain: {
                  address: "",
                  registered: false,
                  wordpressInstalled: false,
                },
                wordpress: {
                  password: null,
                  id: null,
                },
                diviKey: null,
              },
            })
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.$router.push({ name: "welcome" });
              }, 1000);
            });
        });
    },
  },
  computed: {
    ...mapState(["user_data", "user"]),
  },
};
</script>

<style scoped>
</style>