<template>
  <v-card
    flat
    width="100%"
    tile
    color="#f4f7fa"
    v-if="AllDataLoaded"
    class="mt-n5"
  >
    <v-app-bar
      color="white"
      height="auto"
      flat
      style="box-shadow: 0 12px 50px 2px #13507c24"
      :app="!$vuetify.breakpoint.xs"
    >
      <v-container class="pl-0 ml-10 mr-0 pt-1" fluid>
        <v-row justify="start" align="center" class="my-0 mb-0">
          <v-col align-self="center">
            <v-btn
              text
              small
              class="ml-n5"
              @click="goBackToModules"
              :loading="loading"
              ><v-icon left>mdi-chevron-left</v-icon> Retour</v-btn
            >
          </v-col>
        </v-row>

        <v-row justify="start" align="center" class="mb-0 mt-0">
          <v-col
            class="text-body-1 font-weight-medium"
            align-self="center"
            md="6"
            cols="12"
          >
            <v-row
              justify="start"
              align="center"
              class="mb-1 mt-0 pt-0"
              style="font-size: 23px"
            >
              <v-chip
                label
                color="#E9EDFE"
                text-color="blue accent-4"
                class="font-weight-medium mr-4 mb-1"
              >
                Module {{ currentModule2.index }}
              </v-chip>

              {{ currentModule2.title }}
            </v-row>

            <v-row
              justify="start"
              align="center"
              class="mt-3 caption new-grey--text"
            >
              <v-avatar size="30">
                <v-img :src="currentModule2.author.pic"></v-img>
              </v-avatar>
              <span class="pl-2">Par {{ currentModule2.author.name }}</span>

              <v-chip
                class="ml-2"
                small
                style="background: white; color: #88a6c9"
                color="#88a6c9"
                ><v-icon left small>mdi-timer</v-icon>
                {{ currentModule2.length }}</v-chip
              >

              <v-chip
                small
                style="background: white; color: #88a6c9"
                color="#88a6c9"
              >
                <v-icon left small>mdi-signal-cellular-3</v-icon>
                {{ moduleDifficulty(currentModule2.level) }}</v-chip
              >
            </v-row>
          </v-col>
          <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
          <v-col v-if="$vuetify.breakpoint.xs">
                  <v-progress-linear
                  color="blue accent-4"
                  :value="moduleProgress($route.params.module)"
                  class="font-weight-bold"
                  size="50"
                  height="20"
                  rounded
                  striped
                >
                {{
                      Math.round(moduleProgress($route.params.module))
                    }}%
            
                </v-progress-linear>
          </v-col>
          <v-col md="3" cols="3" align-self="start" v-if="!$vuetify.breakpoint.xs">
            <!-- <v-card
              style="border-radius: 8px"
              flat
              class="px-2 border-secondary"
              height="94"
            > -->
            <v-row
              class="px-2 border-secondary mt-0 pt-0"
              style="border-radius: 8px; min-height: 94px"
              justify="center"
            >
              <v-col md="4" cols="12" align-self="center">
                <v-progress-circular
                  color="blue accent-4"
                  :value="moduleProgress($route.params.module)"
                  class="caption"
                  size="50"
                >
                  <span class="black--text"
                    >{{
                      Math.round(moduleProgress($route.params.module))
                    }}%</span
                  >
                </v-progress-circular>
              </v-col>
              <v-col align-self="center" v-if="!$vuetify.breakpoint.xs">
                <v-row class="mb-1">
                  <span class="caption new-grey--text"
                    ><v-icon color="yellow darken-2" small>mdi-trophy</v-icon>
                    Progression dans ce module</span
                  >
                </v-row>
                <v-row class="black--text body-2 font-weight-regular mb-0">
                  {{ learningDone() }} visionné
                </v-row>
              </v-col>
            </v-row>
            <!-- </v-card> -->
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-container
      style="background: #f4f7fa; position: relative;"
      class="pt-2 pb-0 px-0 fill-height lesson-container"
      fluid
    >
      <v-row class="mb-0 pb-0 pt-3" no-gutters>
        <v-col md="4" cols="12" class="d-flex flex-column order-last order-md-first">
          <v-card class="flex d-flex flex-column pa-2" tile flat>
            <v-card-title class="text-body-1 font-weight-medium pt-0">
              <v-icon left color="black"
                >mdi-format-list-bulleted-square</v-icon
              >
              Contenu du Module
              <v-spacer></v-spacer>
              <v-btn
                small
                dark
                outlined
                color="red accent-4"
                @click="
                  pdfMode = true;
                  pdf = currentModule2.document;
                "
                v-if="currentModule2.document"
              >
                <v-icon left small>mdi-file-pdf-box</v-icon>
                Fiche du Module
              </v-btn>
            </v-card-title>

            <!-- <v-divider></v-divider>
            <v-btn
              small
              outlined
              @click="pdfMode = true"
              v-if="currentModule2.document"
            >
              <v-icon left small>mdi-file-eye</v-icon>
              Fiche récapitulative du Module
            </v-btn> -->

            <!-- <v-divider></v-divider> -->
            <v-expansion-panels flat class="mt-6">
              <v-expansion-panel
                style="background: #f7f7f7"
                v-for="(chapter, chapIndex) in currentModule2.chapters"
                :key="chapIndex"
              >
                <v-expansion-panel-header
                  class="body-2 font-weight-regular"
                  :id="`panel-${chapIndex}`"
                >
                  <template v-slot:actions>
                    <v-progress-circular
                      v-if="
                        chapterProgress(currentModule2.index, chapIndex) != 100
                      "
                      :value="chapterProgress(currentModule2.index, chapIndex)"
                      color="blue accent-4"
                      size="35"
                    >
                      <v-icon> $expand </v-icon>
                      <!-- <v-icon v-else> $expand </v-icon> -->
                    </v-progress-circular>
                    <v-avatar color="rgba(5, 245, 7, 0.25)" size="35" v-else>
                      <v-icon> $expand </v-icon>
                    </v-avatar>
                  </template>
                  <v-col cols="2" class="px-0">
                    <v-chip
                      v-if="
                        chapterProgress(currentModule2.index, chapIndex) != 100
                      "
                      color="blue accent-4"
                      class="font-weight-bold body-1"
                      dark
                      label
                    >
                      {{ chapIndex + 1 }}
                    </v-chip>
                    <v-chip
                      v-else
                      color="green accent-4"
                      class="font-weight-bold body-1"
                      dark
                      label
                    >
                      {{ chapIndex + 1 }}
                    </v-chip>
                  </v-col>
                  <v-col>
                    <v-row class="font-weight-bold mb-0 body-1">
                      {{ chapter.title }}
                    </v-row>
                    <v-row class="mb-0 caption" style="color: #88a6c9">
                      <v-icon small color="#88a6c9" class="mr-1"
                        >mdi-youtube</v-icon
                      >
                      {{
                        numberOfLessonsCompleted(
                          currentModule2.index,
                          chapIndex,
                          chapter.lessons
                        )
                      }}/{{ chapter.lessons.length }}
                    </v-row>
                  </v-col>
                  <!-- <v-icon color="blue accent-4" x-small left>mdi-circle</v-icon>
                  <span
                    ><span class="font-weight-medium">
                      {{ `Chapitre ${chapIndex + 1} : ` }}</span
                    >
                    {{ `${chapter.title}` }}</span
                  > -->
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-list
                    class="font-weight-regular"
                    flat
                    nav
                    style="background: #f7f7f7"
                  >
                    <v-list-item
                      v-for="(lesson, lessIndex) in chapter.lessons"
                      :key="lessIndex"
                      class="px-0 font-weight-regular"
                      color="blue accent-4"
                      link
                      :to="{
                        params: {
                          chapter: chapIndex + 1,
                          lesson: lessIndex + 1,
                        },
                      }"
                      @click="loadLesson"
                      :disabled="lesson.url === ''"
                      active-class="current-link"
                    >
                      <v-list-item-icon>
                        <v-btn
                          fab
                          depressed
                          x-small
                          color="rgb(206 215 254)"
                          v-if="
                            !completedLesson(
                              $route.params.module,
                              chapIndex,
                              lessIndex
                            )
                          "
                        >
                          <v-icon v-if="currentLesson(lesson) && !pdfMode"
                            >mdi-target</v-icon
                          >
                          <v-icon color="black" v-else>mdi-play</v-icon>
                        </v-btn>

                        <v-btn
                          fab
                          depressed
                          color="rgba(5, 245, 7, 0.25)"
                          x-small
                          v-else
                        >
                          <v-icon v-if="currentLesson(lesson)"
                            >mdi-target</v-icon
                          >
                          <v-icon v-else>mdi-check</v-icon>
                        </v-btn>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          v-if="
                            !completedLesson(
                              $route.params.module,
                              chapIndex,
                              lessIndex
                            )
                          "
                          class="text-wrap text-left font-weight-medium"
                          style="font-size: 14px"
                          :id="`${chapIndex + 1}-${lessIndex + 1}`"
                        >
                          {{ lesson.title }}
                          <v-chip
                            color="blue accent-4"
                            dark
                            v-if="lesson.url === ''"
                            style="position: absolute; right: 0"
                            small
                            >Soon !</v-chip
                          >
                          <!-- <v-spacer> </v-spacer>
                          <span class="caption grey--text">02:30</span> -->
                        </v-list-item-title>

                        <v-list-item-title
                          v-else
                          class="text-wrap text-left font-weight-medium"
                          style="font-size: 14px"
                          :id="`${chapIndex + 1}-${lessIndex + 1}`"
                        >
                          {{ lesson.title }}
                          <v-chip
                            color="blue accent-4"
                            dark
                            v-if="lesson.url === ''"
                            style="position: absolute; right: 0"
                            small
                            >Soon !</v-chip
                          >
                          <!-- <v-spacer> </v-spacer>
                          <span class="caption grey--text">02:30</span> -->
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="chapter.document"
                      class="px-0 font-weight-regular"
                      link
                      @click="
                        pdfMode = true;
                        pdf = chapter.document;
                      "
                    >
                      <v-list-item-icon>
                        <!-- <v-btn
                          fab
                          depressed
                          x-small
                          dark
                          color="red accent-4"
                          @click="
                            pdfMode = true;
                            pdf = chapter.document;
                          "
                        >
                          <v-icon small>mdi-file-pdf-box</v-icon>
                        </v-btn> -->

                        <v-icon color="red accent-4" large
                          >mdi-file-pdf-box</v-icon
                        >
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          class="text-wrap text-left font-weight-medium"
                          style="font-size: 14px"
                        >
                          Fiche récap' du chapitre
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>

        <v-divider vertical v-if="!$vuetify.breakpoint.xs"></v-divider>

        <v-col class="d-flex flex-column">
          <v-card
            class="px-6 pt-2 flex d-flex flex-column"
            tile
            flat
            v-if="!pdfMode"
          >
            <v-skeleton-loader
              :loading="loadingOverlay || loadingLesson"
              type="card-heading"
            >
              <v-card-title
                class="font-weight-medium px-0 mb-0 pl-1 pb-4 mt-0 pt-0"
                style="font-size: 18px"
              >
                <v-avatar color="rgb(206 215 254)" class="mr-2" size="34" v-if="!$vuetify.breakpoint.xs">
                  <v-icon color="black" small>mdi-target</v-icon>
                </v-avatar>
                Leçon {{ $route.params.lesson }} -
                {{ lesson.title }}
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  small
                  color="blue accent-4"
                  class="complete-lesson-btn"
                  :loading="completeLessonLoading"
                  @click="completeLesson"
                  :disabled="
                    completedLesson(
                      this.$route.params.module,
                      this.$route.params.chapter - 1,
                      this.$route.params.lesson - 1
                    ) && lesson.url != ''
                  "
                  ><v-icon left>mdi-check-circle</v-icon> J'ai vu la leçon
                </v-btn>
              </v-card-title>
            </v-skeleton-loader>

            <v-skeleton-loader
              :loading="loadingOverlay || loadingLesson"
              type="card"
            >
              <div class="video-responsive">
                <iframe
                  v-if="lesson.url != ''"
                  :src="lesson.url"
                  frameborder="0"
                  allow="autoplay; fullscreen"
                  width="760"
                  height="325"
                  allowfullscreen
                ></iframe>
                <v-card
                  v-else
                  color="#DDD"
                  width="90%"
                  height="480"
                  style="transition: background-color 0.2s ease-in-out"
                >
                  <v-row
                    justify="center"
                    style="
                      position: absolute;
                      top: 40%;
                      left: 20%;
                      display: inline-grid;
                    "
                  >
                    <v-icon>mdi-alert-circle</v-icon>
                    <p class="font-weight-bold title">
                      Oups ! Il n'y a pas encore de vidéo disponible pour cette
                      leçon !
                    </p>
                  </v-row>
                </v-card>
              </div>
            </v-skeleton-loader>

            <v-skeleton-loader
              :loading="loadingOverlay || loadingLesson"
              type="card"
              v-if="!pdfMode"
            >
              <v-tabs
                light
                color="blue accent-4"
                hide-slider
                class="mt-2"
                icons-and-text
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab class="pt-3" :disabled="linksNumber(lesson.files) === 0">
                  <v-badge
                    v-if="linksNumber(lesson.files) != 0"
                    color="red"
                    :content="linksNumber(lesson.files)"
                  >
                    <v-icon>mdi-file-multiple</v-icon>
                  </v-badge>
                  <v-icon v-else>mdi-file-multiple</v-icon>
                </v-tab>
                <v-tab class="pt-3" :disabled="linksNumber(lesson.links) === 0">
                  <v-badge
                    v-if="linksNumber(lesson.links) != 0"
                    color="red"
                    :content="linksNumber(lesson.links)"
                  >
                    <v-icon>mdi-link-variant</v-icon>
                  </v-badge>
                  <v-icon v-else>mdi-link-variant</v-icon>
                </v-tab>

                <v-tab-item>
                  <v-card flat class="px-2">
                    <v-card-text class="pl-2">
                      <p
                        class="
                          body-1
                          ma-2
                          grey--text
                          text-uppercase
                          font-weight-medium
                        "
                      >
                        Documents
                      </p>
                      <v-row
                        style="width: 100%"
                        v-if="lesson.files.length != 0"
                      >
                        <v-col
                          v-for="(file, fileIndex) in lesson.files"
                          :key="fileIndex"
                          cols="4"
                          class="py-2 black--text"
                        >
                          <v-hover v-slot="{ hover }">
                            <v-avatar
                              color="rgba(5, 245, 7, 0.25)"
                              class="mr-2"
                            >
                              <v-fade-transition>
                                <v-overlay v-if="hover" absolute>
                                  <v-btn
                                    fab
                                    color="green accent-4"
                                    :href="file.url"
                                    target="_blank"
                                    ><v-icon>mdi-download</v-icon></v-btn
                                  >
                                </v-overlay>
                              </v-fade-transition>
                              <v-icon color="green accent-4"
                                >mdi-file-document</v-icon
                              >
                            </v-avatar>
                          </v-hover>

                          {{ file.title }}
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <p class="caption text-center">
                          Il n'y a pas de documents spécifiques à cette leçon.
                          🤓
                        </p>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat class="px-2">
                    <v-card-text class="pl-2">
                      <p
                        class="
                          body-1
                          ma-2
                          grey--text
                          text-uppercase
                          font-weight-medium
                        "
                      >
                        Liens utiles
                      </p>
                      <v-row
                        style="width: 100%"
                        v-if="lesson.links.length != 0"
                      >
                        <v-col
                          v-for="(link, linkIndex) in lesson.links"
                          :key="linkIndex"
                          cols="4"
                          class="py-2 black--text"
                        >
                          <v-hover v-slot="{ hover }">
                            <v-avatar color="rgb(206 215 254)" class="mr-2">
                              <v-fade-transition>
                                <v-overlay v-if="hover" absolute>
                                  <v-btn
                                    fab
                                    color="blue accent-4"
                                    :href="link.url"
                                    target="_blank"
                                    ><v-icon>mdi-open-in-new</v-icon></v-btn
                                  >
                                </v-overlay>
                              </v-fade-transition>
                              <v-icon color="blue accent-4">mdi-link</v-icon>
                            </v-avatar>
                          </v-hover>

                          {{ link.title }}
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <p>
                          Il n'y a pas de liens utiles disponibles pour cette
                          leçon 🤓
                        </p>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-skeleton-loader>
          </v-card>
          <v-card class="pa-6 flex d-flex flex-column" tile flat v-else>
            <v-skeleton-loader
              :loading="loadingOverlay || loadingLesson"
              type="card-heading"
            >
              <iframe :src="pdf" style="height: 80vh; width: 100%"></iframe>
            </v-skeleton-loader>
          </v-card>
        </v-col>

        <v-snackbar v-model="lessonCompletedAlert" multi-line bottom>
          Félicitations ! Vous venez de terminer une leçon de plus 🎉

          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue accent-4"
              text
              v-bind="attrs"
              @click="lessonCompletedAlert = false"
            >
              Fermer
            </v-btn>
          </template>
        </v-snackbar>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Lesson",
  data() {
    return {
      hover: false,
      loading: false,
      navColor: "transparent",
      loadingOverlay: true,
      loadingLesson: false,
      tab: null,
      AllDataLoaded: false,
      completeLessonLoading: false,
      openedChapter: [],
      lessonCompletedAlert: false,
      panel: null,
      mini: false,
      pdfMode: false,
      pdf: null,
      pdfUrl:
        "https://firebasestorage.googleapis.com/v0/b/webmyday-ba4d9.appspot.com/o/tarifs-boxtal-2022-10-11.pdf?alt=media&token=1f943ac0-525d-4b77-8105-08e1801c32f8",
    };
  },
  computed: {
    ...mapState([
      "modules_v2",
      "currentModule2",
      "chapter",
      "progressModules",
      "lesson",
      "user_data",
    ]),
    breadcrumbs: function () {
      return [
        { text: this.currentModule2.title, disabled: false },
        { text: this.chapter.title, disabled: false },
        { text: this.lesson.title, disabled: false },
      ];
    },
  },
  methods: {
    currentLesson(less) {
      return less.title === this.lesson.title;
    },
    // currentLesson(modul, chap, less) {
    //   return (
    //     this.$route.params.module === modul &&
    //     this.$route.params.chapter === chap + 1 &&
    //     this.$route.params.lesson === less + 1
    //   );
    // },
    numberOfLessonsCompleted(modul, chap, lessons) {
      let arr = [];

      lessons.forEach((less, index) => {
        if (this.completedLesson(modul, chap, index)) {
          arr.push(less);
        }
      });

      return arr.length;
    },
    goBackToModules() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$router.push({
          name: "academie",
          params: { course: this.$route.params.course },
        });
      }, 1000);
    },
    learningDone: function () {
      let pad = function (num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
      };
      let time = 0;

      time +=
        (this.moduleProgress(this.currentModule2.index) / 100) *
        this.currentModule2.minutes;

      if (Math.round(time) < 60) {
        return `${Math.round(time)} min`;
      } else {
        let num = Math.round(time);
        let hours = num / 60;
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);
        return rhours + "h" + pad(rminutes, 2);
      }
    },
    changeNavColor() {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        this.navColor = "white";
      } else {
        this.navColor = "transparent";
      }
    },
    linksNumber(arr) {
      return arr.length;
    },
    loadLesson() {
      this.pdfMode = false;
      this.pdf = null;
      this.loadingLesson = true;
      this.$store
        .dispatch("setModuleChapterLesson", {
          module: this.$route.params.module,
          chapter: this.$route.params.chapter,
          lesson: this.$route.params.lesson,
        })
        .then(() => {
          this.loadingLesson = false;
        });
    },
    moduleDifficulty(level) {
      if (level >= 1 && level < 3) {
        return "Facile";
      } else if (level >= 3 && level < 5) {
        return "Intermédiaire";
      } else {
        return "Difficile";
      }
    },
    completedLesson: function (modul, chap, less) {
      return this.progressModules[modul - 1][`chapter_${chap + 1}`][
        `lesson_${less + 1}`
      ];
    },
    chapterProgress(modul, chap) {
      let chapterLength =
        this.modules_v2[modul - 1].chapters[chap].lessons.length;
      let lessonsCompleted = 0;

      // Object.keys(
      //   this.progressModules[modul - 1][`chapter_${chap + 1}`]
      // ).forEach((lesson) => {
      //   let lessonIndex = parseInt(lesson.slice(-1)) - 1;

      //   if (this.completedLesson(modul, chap, lessonIndex)) {
      //     lessonsCompleted++;
      //   }
      // });

      lessonsCompleted = this.numberOfLessonsCompleted(modul, chap, this.modules_v2[modul - 1].chapters[chap].lessons)

      return parseFloat((lessonsCompleted / chapterLength) * 100);
    },
    moduleProgress(index) {
      if (this.AllDataLoaded) {
        let moduleLength = this.modules_v2[index - 1].chapters.length;
        let averageProgress = 0;

        this.modules_v2[index - 1].chapters.forEach((chapter, chapIndex) => {
          averageProgress += this.chapterProgress(index, chapIndex);
        });

        return averageProgress / moduleLength;
      }
    },
    completeLesson() {
      this.completeLessonLoading = true;

      this.$store
        .dispatch("completeLesson", {
          course: this.$route.params.course,
          module: this.$route.params.module,
          chapter: this.$route.params.chapter,
          lesson: this.$route.params.lesson,
        })
        .then(() => {
          this.$store
            .dispatch("setProgressModules", this.$route.params.course)
            .then(() => {
              setTimeout(() => {
                this.completeLessonLoading = false;
                this.lessonCompletedAlert = true;
              }, 1000);
            });
        });
    },
  },
  mounted() {
    this.$store.dispatch("setUserData").then(() => {
      this.$store.dispatch("setProject").then(() => {
        this.$store
          .dispatch("setModulesV2", this.$route.params.course)
          .then(() => {
            this.$store
              .dispatch("setModuleChapterLesson", {
                module: this.$route.params.module,
                chapter: this.$route.params.chapter,
                lesson: this.$route.params.lesson,
              })
              .then(() => {
                this.$store
                  .dispatch("setProgressModules", this.$route.params.course)
                  .then(() => {
                    this.AllDataLoaded = true;
                  });
              });
          });
      });
    });
  },
  created() {
    window.onscroll = () => {
      this.changeNavColor();
    };
    setTimeout(() => {
      this.loadingOverlay = false;
    }, 1700);
    setTimeout(() => {
      document
        .querySelector(`#panel-${this.$route.params.chapter - 1}`)
        .click();
    }, 1900);
  },
};
</script>

<style>
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive > iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 8px;
}

@media only screen and (max-width: 1300px) {
  .mobile-headline {
    font-size: 10px !important;
  }
}

.vp-title {
  display: none !important;
}

.current-lesson {
  font-weight: 500 !important;
}

.lesson-container {
  top: 175px;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 768px) {
.lesson-container {
  top: 0;
}
.complete-lesson-btn {
  margin-right: 0;
    margin-left: auto;
}
}
</style>