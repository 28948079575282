<template>
    <div></div>
</template>


<script>
import { mapState } from "vuex";

export default {
  name: "Quizzes",
  data() {
    return {
      loading: true,
      testQuizzes : [
        { 
          title: "Quizz test",
          id: "wDsDs06OUXGo7j06vqEk",
          cat: "Bases du marketing",
          img: "https://webmyday.io/wp-content/uploads/2024/11/quizz-webmarketing-m3c4.png"
        }
      ]
    };
  },
  computed: {
    ...mapState(["webinar"]),
  },
  methods: {
   
  },
};
</script>


<style>

</style>