<template>
  <v-app style="background: #f4f7fa">
    <!-- <v-system-bar  :height="60" fixed app class="px-0" dark  v-if="
        ![
          'builder',
          'Home',
          'onboarding',
          'project-setup',
          'installation',
          'form-free-training',
          'form-end-of-training',
          'lesson',
          'welcome',
        ].includes($route.name)
      ">
    <v-img src="https://cdn.cosmicjs.com/871c4040-3396-11ed-8a37-f1952c73e588-Group91.png" height="60" class="px-0" width="100%">
      <v-btn icon absolute right small style="top:15px">
      <v-icon class="mr-0">mdi-close-circle</v-icon>
    </v-btn>
    </v-img>

    </v-system-bar> -->
    <mainNav
      v-if="
        ![
          'builder',
          'Home',
          'onboarding',
          'project-setup',
          'installation',
          'form-free-training',
          'form-end-of-training',
          'lesson',
          'welcome',
        ].includes($route.name)
      "
    />
    <mainSide
      v-if="
        ![
          'builder',
          'Home',
          'onboarding',
          'project-setup',
          'installation',
          'form-free-training',
          'form-end-of-training',
          'lesson',
          'welcome',
        ].includes($route.name)
      "
    />
    <v-main :class="{ blurBack: sessionExpired }" style="padding-top: 20px">
      <!-- <v-overlay :value="overlay" z-index="100001">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay> -->
      <!-- <v-speed-dial
        v-model="fab"
        class="mx-2"
        fab
        dark
        large
        fixed
        bottom
        right
        open-on-hover
        v-if="
          this.$route.name != 'Home' &&
          this.$route.name != 'project-setup' &&
          this.$route.name != 'welcome'
        "
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue accent-4" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-help-circle </v-icon>
          </v-btn>
        </template>
        <v-tooltip left color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="orange"
              v-bind="attrs"
              v-on="on"
              :to="{ name: 'tickets' }"
            >
              <v-icon>mdi-lifebuoy</v-icon>
            </v-btn>
          </template>
          <span>Ouvrir un ticket au support technique</span>
        </v-tooltip>

        <v-tooltip left color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="indigo"
              v-bind="attrs"
              v-on="on"
              :to="{ name: 'academie' }"
            >
              <v-icon>mdi-youtube</v-icon>
            </v-btn>
          </template>
          <span>Reprendre la formation</span>
        </v-tooltip>

        <v-tooltip left color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              small
              color="cyan"
              v-bind="attrs"
              v-on="on"
              href="https://communaute-webmyday.slack.com/"
              target="_blank"
            >
              <v-icon>mdi-account-group</v-icon>
            </v-btn>
          </template>
          <span>Poser une question à la communauté</span>
        </v-tooltip>
      </v-speed-dial> -->

      <v-dialog v-model="sessionExpired" width="500" persistent>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Session expirée
          </v-card-title>

          <v-card-text class="pa-6">
            Votre session a expiré. Veuillez vous reconnecter pour des raisons
            de sécurité.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue accent-4" text @click="goToLogin">
              Me reconnecter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase";
import mainNav from "./components/navigation/mainNavbar";
import mainSide from "./components/navigation/mainSidebar";

export default {
  name: "App",
  components: {
    mainNav,
    mainSide,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    overlay() {
      return this.$store.state.overlay;
    },
  },
  data() {
    return {
      fab: null,
      sessionExpired: false,
    };
  },
  methods: {
    goToSupport() {
      this.$router.push({ name: "tickets" });
    },
    goToLogin() {
      this.sessionExpired = false;
      this.$router.push({ name: "Home" });
    },
  },

  async beforeCreate() {
    firebase.auth().onAuthStateChanged((user) => {
      // initially user = null, after auth it will be either <fb_user> or false
      this.$store.commit("setUser", user || false);

      if (user) {
        this.$store.state.user = user;
        this.$store.dispatch("setUserData").then(() => {
          this.$store.dispatch("setProject");
          // .then(() => {
          //   this.$store.dispatch("setModulesV2").then(() => {
          //     this.$store.dispatch("setProgressModules").then(() => {
          //       this.$store.dispatch("setTasks").then(() => {
          //         this.$store.dispatch("setTickets");
          //       });
          //     });
          //   });
          // });
        });
      }
    });
  },
  async mounted() {
    firebase.auth().onAuthStateChanged((user) => {
      let userSessionTimeout = null;

      if (user === null && userSessionTimeout) {
        clearTimeout(userSessionTimeout);
        userSessionTimeout = null;
      } else {
        user.getIdTokenResult().then((idTokenResult) => {
          const authTime = idTokenResult.claims.auth_time * 1000;
          const sessionDurationInMilliseconds = 60 * 60 * 1000 * 5; // 5h session
          const expirationInMilliseconds =
            sessionDurationInMilliseconds - (Date.now() - authTime);
          userSessionTimeout = setTimeout(
            () => (this.sessionExpired = true),
            expirationInMilliseconds
          );
        });
      }
    });
  },
};
</script>

<style>
input {
  border-bottom: none !important;
}

input:focus {
  box-shadow: none !important;
}

.grey-wmd {
  color: #33495d !important;
}

.blue-wmd {
  color: #6483ed !important;
}

.green-wmd {
  color: #74d9c4 !important;
}

.red-wmd {
  color: #f4697a !important;
}

.new-blue-wmd {
  color: #6483ed !important;
}

.blurBack {
  filter: blur(3px) !important;
}

/* .v-application {
  font-family: "Montserrat", sans-serif;
} */
</style>

