<template>
  <div>
    <v-navigation-drawer permanent fixed class="elevation-2" width="400">
      <div class="container py-0 my-1">
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              :to="{ name: 'dashboard' }"
              text
              color="blue accent-4"
            >
              <v-icon left>mdi-arrow-left</v-icon>
              Retour
            </v-btn>
          </template>
          <span>Retour au Tableau de bord</span>
        </v-tooltip>

        <div class="row justify-center">
          <img src="logo_seul.svg" alt="Webmyday.io logo" width="65" />
        </div>
      </div>

      <div class="container center-align">
        <h4>Je lance l'installation de mon site</h4>
        <span class="caption font-italic"
          >(sur un nom de domaine provisoire)</span
        >
      </div>

      <div class="container center-align">
        <v-timeline dense v-if="!user_data.configuration.isTemporaryDomain">
          <v-timeline-item :color="item1" small style="min-height: 100px"
            >Mon nom de domaine</v-timeline-item
          >
          <v-timeline-item :color="item2" small style="min-height: 100px"
            >Mes identifiants WordPress</v-timeline-item
          >
          <v-timeline-item :color="item3" small style="min-height: 100px"
            >Mes maquettes à installer</v-timeline-item
          >
          <v-timeline-item :color="item4" small style="min-height: 100px"
            >Confirmation</v-timeline-item
          >
        </v-timeline>
        <v-timeline dense v-else>
          <v-timeline-item :color="item3" small style="min-height: 100px"
            >Mes maquettes à installer</v-timeline-item
          >
          <v-timeline-item :color="item4" small style="min-height: 100px"
            >Confirmation</v-timeline-item
          >
        </v-timeline>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "leftSideBar",
  data() {
    return {};
  },
  props: ["step", "progress", "user_data"],
  computed: {
    item1: function () {
      return "blue accent-4";
    },
    item2: function () {
      if (this.step < 2 && this.user_data.configuration.status == "no") {
        return "grey lighten-2";
      } else {
        return "blue accent-4";
      }
    },
    item3: function () {
      if (this.step < 3 && this.user_data.configuration.status == "no") {
        return "grey lighten-2";
      } else {
        return "blue accent-4";
      }
    },
    item4: function () {
      if (this.step < 4 && this.user_data.configuration.status == "no") {
        return "grey lighten-2";
      } else {
        return "blue accent-4";
      }
    },
  },
};
</script>

<style>
.v-timeline-item__body {
  padding-top: 25px !important;
  text-align: left !important;
  font-size: 20px;
}

/* .v-timeline-item__dot {
  cursor: pointer;
}

.v-timeline-item__dot:hover {
  box-shadow: 2px 2px 2px grey;
} */
</style>