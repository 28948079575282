<template>
  <div v-if="progress && progress.domain">
    <leftSideBar :step="step" :progress="progress" :user_data="user_data" />

    <div
      v-if="step == 1 && user_data.configuration.status == 'no'"
      style="
        margin-left: 30%;
        margin-right: 0;
        height: 800px;
        max-width: 65%;
        display: block;
        padding-top: 15%;
      "
      class="px-6 container center-align"
    >
      <div class="row justify-center">
        <h4 class="font-weight-bold">Quel-est votre nom de domaine ?</h4>
      </div>

      <div class="row justify-center">
        <v-col cols="6">
          <v-text-field
            label="Nom de domaine"
            v-model="progress.domain.address"
            rounded
            filled
            dense
            :rules="domainCheck"
            @keyup.enter="updateDomain"
          ></v-text-field>
        </v-col>
      </div>

      <v-btn
        color="blue accent-4"
        dark
        @click="updateDomain"
        :disabled="!isValidDomain(progress.domain.address)"
        :loading="loading"
        >Continuer</v-btn
      >
    </div>

    <div
      v-else-if="step == 2 && user_data.configuration.status == 'no'"
      style="
        margin-left: 30%;
        margin-right: 0;
        height: 800px;
        max-width: 60%;
        padding-top: 12%;
      "
      class="px-6 container center-align"
    >
      <div>
        <v-btn
          fab
          absolute
          right
          x-large
          dark
          color="blue accent-4"
          style="top: 50%"
          @click="confirmWordPressIds"
          :loading="loading"
          class="arrow-btn"
        >
          <v-icon>mdi-arrow-right-bold</v-icon>
        </v-btn>
        <v-btn
          fab
          absolute
          left
          x-large
          dark
          color="grey"
          style="top: 50%; left: 29%"
          @click="previousStep"
        >
          <v-icon>mdi-arrow-left-bold</v-icon>
        </v-btn>
      </div>
      <v-row align="center" justify="center" style="margin-top: 2%">
        <h4 class="font-weight-bold">
          Quels sont vos identifiants WordPress ?
        </h4>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="6">
          <v-text-field
            label="Identifiant"
            v-model="progress.wordpress.id"
            dense
            rounded
            filled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="6">
          <v-text-field
            label="Mot de passe"
            v-model="progress.wordpress.password"
            dense
            rounded
            filled
            :append-icon="iconPassword"
            @click:append="hideAndShow"
            :type="type"
            @keyup.enter="confirmWordPressIds"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center" align="center" class="mb-0 pb-0">
        <p>
          Je préfère vous créer des identifiants provisoires
          <br />
          <a
            href="https://webmyday.io/comment-creer-un-utilisateur-wordpress"
            target="blank"
            title="Tutoriel pour créer des identifiants WordPress"
            style="color: blue accent-4"
            class="font-weight-bold"
            >Comment faire ?</a
          >
        </p>
      </v-row>

      <div class="container center-align">
        <p class="red--text caption" v-if="feedback">{{ feedback }}</p>
      </div>
    </div>

    <div
      v-else-if="step == 3 && user_data.configuration.status == 'no'"
      style="margin-left: 30%; margin-right: 0; height: 800px; max-width: 60%"
      class="px-6 container center-align pt-0 mt-0"
    >
      <div>
        <v-btn
          fab
          absolute
          right
          x-large
          dark
          color="blue accent-4"
          style="top: 50%"
          @click="launchSetup"
          :loading="loading"
          class="arrow-btn"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn
          fab
          absolute
          left
          x-large
          dark
          color="grey"
          style="top: 50%; left: 29%"
          @click="previousStep"
        >
          <v-icon>mdi-arrow-left-bold</v-icon>
        </v-btn>
      </div>

      <v-row align="center" justify="center" class="mb-0 pb-0">
        <h4 class="font-weight-bold">Confirmation</h4>
      </v-row>
      <v-row align="center" justify="center" class="pt-0 mt-0">
        <h5 v-if="!user_data.configuration.isTemporaryDomain">
          {{ pagesToBeInstalled.length }} maquettes vont être installées sur
          <span class="font-weight-bold" style="color: #2862ff">{{
            progress.domain.address
          }}</span>
        </h5>
        <h5 v-else>
          {{ pagesToBeInstalled.length }} maquettes sur
          {{ numberOfPages }} seront installées sur votre domaine provisoire
          <span style="color: #2862ff" class="font-weight-bold">{{
            progress.domain.address
          }}</span>
        </h5>
      </v-row>

      <v-card
        width="800"
        max-height="650"
        style="overflow: auto"
        min-height="650"
        class="ml-12"
      >
        <firstMenu
          :logo="project.logo"
          :pages="pagesToBeInstalled"
          :currentPage="currentPage"
          :loadPage="loadPage"
          class="mb-12"
        />
        <v-skeleton-loader
          :loading="loadingRows"
          height="300"
          width="auto"
          type="card"
          min-width="600"
          v-for="(row, index) in rows"
          :key="index"
        >
          <v-card max-width="780" flat min-height="150">
            <img :src="row.item.src" style="max-width: 780px" />
          </v-card>
        </v-skeleton-loader>

        <v-row style="min-height: 400px" v-if="rows.length == 0"></v-row>
        <v-row style="min-height: 200px" v-else></v-row>

        <secondFooter
          :project="project"
          :pages="pages"
          :step="step"
          :currentPage="currentPage"
          :startLoading="startLoading"
          :endLoading="endLoading"
          :loadPage="loadPage"
        />
      </v-card>
    </div>

    <div
      v-else-if="step == 5 || user_data.configuration.status == 'pending'"
      style="
        margin-left: 30%;
        margin-right: 0;
        height: 800px;
        max-width: 60%;
        padding-top: 12%;
      "
      class="px-6 container center-align"
    >
      <v-row align="center" justify="center" style="margin-top: 2%">
        <h4 class="font-weight-bold">Félicitations !</h4>
      </v-row>

      <v-row align="center" justify="center">
        <p class="headline font-weight-bold">
          Votre site
          <span style="color: blue accent-4">{{ project.name }}</span>
          est en cours d'installation sur le domaine
          {{ progress.domain.address }} !
        </p>

        <br />
        <p class="headline">
          Vous serez notifié une fois l'installation finie 🥳
        </p>
      </v-row>

      <div class="container center-align">
        <v-btn
          color="blue accent-4"
          dark
          title="Retour au dashboard"
          :to="{ name: 'dashboard' }"
          >Retour à l'académie</v-btn
        >
      </div>
    </div>

    <div
      v-if="user_data.configuration.status == 'complete'"
      style="
        margin-left: 30%;
        margin-right: 0;
        height: 800px;
        max-width: 60%;
        padding-top: 12%;
      "
      class="px-6 container center-align"
    >
      <v-row align="center" justify="center" style="margin-top: 2%">
        <h4 class="font-weight-bold">Félicitations !</h4>
      </v-row>

      <v-row align="center" justify="center">
        <p class="headline font-weight-bold">
          Votre site
          <span style="color: blue accent-4">{{ project.name }}</span>
          a bien été configuré sur le domaine
          {{ progress.domain.address }} !
        </p>

        <br />
        <p class="headline">
          Vous pouvez maintenant le découvrir avec votre coach 🥳
        </p>
      </v-row>

      <div class="container center-align">
        <v-btn
          color="blue accent-4"
          dark
          title="Retour au dashboard"
          :to="{ name: 'dashboard' }"
          >Retour à l'académie</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import { db } from "@/firebase/init";
import { mapState } from "vuex";
import leftSideBar from "./components/leftSidebar";
import firstMenu from "./components/firstMenu";
import secondFooter from "./components/secondFooter";

export default {
  name: "WebsiteSetUp",
  components: {
    leftSideBar,
    firstMenu,
    secondFooter,
  },
  data() {
    return {
      step: 1,
      domainCheck: [
        (v) =>
          /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi.test(
            v
          ) || "Veuillez rentrer un nom de domaine valide !",
      ],
      loading: false,
      feedback: null,
      iconPassword: "mdi-eye",
      type: "password",
      loadingRows: false,
      realRows: [],
    };
  },
  computed: {
    ...mapState([
      "progress",
      "pages",
      "project",
      "rows",
      "currentPage",
      "menu",
      "footer",
      "user_data",
      "mainJson",
      "jsonBlocks",
      "coaches",
    ]),
    pagesToBeInstalled: function () {
      let pagesToBeInstalled = [];
      this.pages.forEach((page) => {
        if (page.toBeInstalled) {
          pagesToBeInstalled.push(page);
        }
      });
      return pagesToBeInstalled;
    },
    numberOfPages: function () {
      return this.pages.length;
    },
  },
  methods: {
    nextStep() {
      this.step++;
    },
    previousStep() {
      this.step--;
    },
    updateDomain() {
      if (this.isValidDomain(this.progress.domain.address)) {
        this.loading = true;
        this.$store
          .dispatch("updateDomainRegistration", {
            address: this.progress.domain.address,
            value: true,
          })
          .then(() => {
            this.nextStep();
            this.loading = false;
          });
      }
    },
    isValidDomain(domain) {
      return /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi.test(
        domain
      );
    },
    onChangeUpdate(page) {
      this.$store.dispatch("setCurrentPage", page.slug).then(() => {
        this.$store.dispatch("setPageToBeInstalled", page.toBeInstalled);
      });
    },
    confirmWordPressIds() {
      if (this.progress.wordpress.password && this.progress.wordpress.id) {
        this.feedback = null;
        this.loading = true;
        this.$store
          .dispatch("updateWordpressCredentials", {
            id: this.progress.wordpress.id,
            password: this.progress.wordpress.password,
          })
          .then(() => {
            this.loading = false;
            this.nextStep();
          });
      } else {
        this.feedback = "Veuillez renseigner tous les champs !";
      }
    },
    hideAndShow() {
      this.type = this.type === "password" ? "text" : "password";
      if (this.type === "text") {
        this.iconPassword = "mdi-eye-off";
      } else {
        this.iconPassword = "mdi-eye";
      }
    },
    loadPage(page) {
      this.startLoading();
      this.$store.dispatch("setCurrentPage", page.slug).then(() => {
        this.$store.dispatch("setRows").then(() => {
          this.$store.dispatch("setBlocks", 2).then(() => {
            this.$store.dispatch("filterRows").then(() => {
              console.log(this.rows);
              this.endLoading();
            });
          });
        });
      });
    },
    startLoading() {
      this.loadingRows = true;
    },
    endLoading() {
      this.loadingRows = false;
    },
    setPageJsonContent(page) {
      var ids = [];
      var metaJson = "";
      var fullMetaJson = "";

      page.rows.forEach((row) => {
        if (row.item) {
          ids.push(row.item.id);
        }
      });

      ids.forEach((id) => {
        metaJson += this.jsonBlocks[`${id}`];
      });

      fullMetaJson = this.mainJson.open + metaJson + this.mainJson.closed;

      let refPages = db.collection(
        `users/${this.user_data.profile.user_id}/project/${this.project.slug}/pages`
      );

      refPages
        .where("slug", "==", page.slug)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref.update({
              content: fullMetaJson,
            });
          });
        });

      var jsFile = {
        context: "et_builder",
        data: {
          232444: fullMetaJson,
        },
        defaults: {},
        images: [],
      };

      var content = JSON.stringify(jsFile);

      var filename = `${page.slug}.json`;

      var blob = new Blob([content], {
        type: "application/json",
      });

      var storageRef = firebase.storage().ref();

      var metadata = {
        contentType: "application/json",
      };

      storageRef
        .child(
          `user_${this.user_data.profile.user_id}/jsonTemplates/${filename}`
        )
        .put(blob, metadata);
    },
    saveTemplatesToBeInstalled() {
      this.pagesToBeInstalled.forEach((page) => {
        this.setPageJsonContent(page);
      });
    },
    launchSetup() {
      let agenda = this.coaches.filter(
        (coach) => coach.id === this.user_data.project.coach
      )[0].agenda;
      this.loading = true;
      if (this.user_data.configuration.isTemporaryDomain) {
        this.$store
          .dispatch("updateDomainRegistration", {
            address: this.user_data.configuration.url,
            value: true,
          })
          .then(() => {
            this.$store.dispatch("updateWordpressCredentials", {
              id: this.user_data.configuration.username,
              password: this.user_data.configuration.password,
            });
          })
          .then(() => {
            this.$store
              .dispatch("updateWebsiteInstallationStatus", "pending")
              .then(() => {
                axios({
                  method: "post",
                  url: `https://us-central1-webmyday-ba4d9.cloudfunctions.net/configurationRequest?name=${this.user_data.profile.name}&email=${this.user_data.profile.email}&id=${this.user_data.profile.user_id}&agenda=${agenda}`,
                  data: {
                    name: this.user_data.profile.name,
                    email: this.user_data.profile.email,
                    id: this.user_data.profile.user_id,
                    agenda: agenda,
                  },
                  headers: { "Content-Type": "multipart/form-data" },
                }).catch((err) => console.log(err));
              })
              .then(() => {
                this.$store.dispatch("sendAdminNotification").then(() => {
                  this.saveTemplatesToBeInstalled();
                  this.$store.dispatch("setUserData").then(() => {
                    setTimeout(() => {
                      this.loading = false;
                      this.nextStep();
                    }, 2000);
                  });
                });
              });
          });
      } else {
        this.$store
          .dispatch("updateWebsiteInstallationStatus", "pending")
          .then(() => {
            axios({
              method: "post",
              url: `https://us-central1-webmyday-ba4d9.cloudfunctions.net/configurationRequest?name=${this.user_data.profile.name}&email=${this.user_data.profile.email}&id=${this.user_data.profile.user_id}&agenda=${agenda}`,
              data: {
                name: this.user_data.profile.name,
                email: this.user_data.profile.email,
                id: this.user_data.profile.user_id,
                agenda: agenda,
              },
              headers: { "Content-Type": "multipart/form-data" },
            }).catch((err) => console.log(err));
          })
          .then(() => {
            this.$store.dispatch("sendAdminNotification").then(() => {
              this.saveTemplatesToBeInstalled();
              this.$store.dispatch("setUserData").then(() => {
                setTimeout(() => {
                  this.loading = false;
                  this.nextStep();
                }, 2000);
              });
            });
          });
      }
    },
  },
  mounted() {
    this.user_data.configuration.isTemporaryDomain
      ? (this.step = 3)
      : (this.step = 1);
  },
  created() {
    this.$store.dispatch("setProject").then(() => {
      this.$store.dispatch("setPages", true).then(() => {
        this.$store.dispatch("setMenu").then(() => {
          this.$store.dispatch("setFooter").then(() => {
            this.$store.dispatch("setRows").then(() => {
              this.$store.dispatch("setJsonBlocks").then(() => {
                this.$store.dispatch("setMainJson").then(() => {
                  this.$store.dispatch("setCoaches");
                });
              });
            });
          });
        });
      });
    });
  },
};
</script>

<style>
.arrow-btn:focus {
  background: blue accent-4 !important;
}
</style>