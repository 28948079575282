<template>
  <v-card height="100%" width="100%" color="white">
    <v-app-bar elevation="0" light color="transparent">
      <v-app-bar-nav-icon>
        <img src="/logo_seul.svg" style="margin-left: 20px; max-width: 50px" />
      </v-app-bar-nav-icon>
    </v-app-bar>
    <div
      class="container left-align hide-on-small-only"
      style="padding-top: 5%"
      v-if="step == 1"
    >
      <p class="font-weight-bold big-text">
        <vue-typer text="Je m'appelle" :repeat="0"></vue-typer>
        <span
          style="color: #4d92f7"
          class="big-text ml-2"
          :style="[
            name == ''
              ? { borderBottom: borderEmpty }
              : { borderBottom: border },
          ]"
        >
          <input
            v-model="name"
            required
            type="text"
            class="big-text font-weight-bold"
            style="max-width: 40%; font-weight: 600; font-size: 35px"
          />
        </span>
      </p>

      <p class="font-weight-bold big-text" v-show="name != ''">
        <vue-typer
          text="J'ai besoin d'un site"
          :repeat="0"
          v-if="name != ''"
          @typed="showSelect"
        ></vue-typer>
        <v-select
          :items="websiteType"
          v-model="type"
          color="#4D92F7"
          style="width: 25%; padding-left: 10px; padding-top: 4px"
          class="font-weight-bold big-text postuler"
          v-show="select"
        ></v-select>
      </p>

      <p class="font-weight-bold big-text" v-show="type.length != 0">
        <vue-typer text="pour" :repeat="0" v-if="type.length != 0"></vue-typer>
        <v-select
          :items="reasons"
          v-model="reason"
          color="#4D92F7"
          style="width: 35%; padding-left: 10px; padding-top: 4px"
          class="font-weight-bold big-text postuler"
        ></v-select>
      </p>
      <p
        class="font-weight-bold big-text"
        v-if="type.length != 0 && reason.length != 0"
      >
        <vue-typer
          text="Je suis joignable par téléphone au"
          :repeat="0"
          @typed="showTel"
        ></vue-typer>
        <span
          style="color: #4d92f7"
          class="big-text ml-2"
          :style="[
            tel == ''
              ? { borderBottom: borderEmpty }
              : { borderBottom: border },
          ]"
        >
          <input
            v-model="tel"
            v-show="showTel"
            required
            type="tel"
            placeholder="0675342514"
            maxlength="10"
            pattern="\d*"
            class="big-text font-weight-bold"
            style="font-size: 35px"
          />
        </span>
      </p>
      <div
        class="container center-align"
        v-show="name != '' && select.length != 0 && tel != ''"
      >
        <p class="red--text caption" v-if="feedback">{{ feedback }}</p>
        <v-btn
          dark
          class="font-weight-bold"
          color="#4D92F7"
          large
          @click="sendEmailToTeam"
          :loading="loading"
          >Postuler</v-btn
        >
      </div>
    </div>

    <div
      class="container left-align show-on-small px-4 hide-on-med-and-up"
      style="padding-top: 15%"
      v-if="step == 1"
    >
      <p class="font-weight-bold big-text">
        <vue-typer text="Je m'appelle" :repeat="0"></vue-typer>
        <span
          style="color: #4d92f7"
          class="big-text ml-2"
          :style="[
            name == ''
              ? { borderBottom: borderEmpty }
              : { borderBottom: border },
          ]"
        >
          <input
            v-model="name"
            required
            type="text"
            class="big-text font-weight-bold"
            style="
              max-width: 42%;
              font-weight: 600;
              font-size: 25px;
              margin-top: -5px;
            "
          />
        </span>
      </p>

      <div class="font-weight-bold big-text my-1" v-show="name != ''">
        <vue-typer
          text="J'ai besoin d'un site"
          :repeat="0"
          v-if="name != ''"
          @typed="showSelect"
        ></vue-typer>
        <v-select
          :items="websiteType"
          v-model="type"
          color="#4D92F7"
          style="width: 70%; margin-top: -4px"
          class="font-weight-bold big-text postuler"
          v-show="select"
        ></v-select>
      </div>

      <div class="font-weight-bold big-text my-1" v-show="type.length != 0">
        <vue-typer text="pour" :repeat="0" v-if="type.length != 0"></vue-typer>
        <v-select
          :items="reasons"
          v-model="reason"
          color="#4D92F7"
          style="width: 92%; margin-top: -4px"
          class="font-weight-bold big-text postuler"
        ></v-select>
      </div>

      <div
        class="font-weight-bold big-text my-1"
        v-if="type.length != 0 && reason.length != 0"
      >
        <vue-typer
          text="Je suis joignable par par téléphone au"
          :repeat="0"
          @typed="showTel"
        ></vue-typer>
        <span
          style="color: #4d92f7"
          class="big-text ml-2"
          :style="[
            tel == ''
              ? { borderBottom: borderEmpty }
              : { borderBottom: border },
          ]"
        >
          <input
            v-model="tel"
            v-show="inputTel"
            placeholder="0675342514"
            maxlength="10"
            required
            type="tel"
            pattern="\d*"
            class="big-text font-weight-bold"
            style="font-size: 25px; width: 70%"
          />
        </span>
      </div>
      <div
        class="container center-align"
        v-show="name != '' && select.length != 0 && tel != ''"
      >
        <p class="red--text caption" v-if="feedback">{{ feedback }}</p>
        <v-btn
          dark
          class="font-weight-bold"
          color="#4D92F7"
          large
          @click="sendEmailToTeam"
          :loading="loading"
          >Postuler</v-btn
        >
      </div>
    </div>

    <div v-show="step == 2">
      <div class="container center-align">
        <p class="font-weight-bold headline" style="color: #4d92f7">
          Super, votre demande a bien été transmise !
        </p>
        <p>
          Vous allez prochainement être rappelé par un membre de notre équipe.
          Cependant si vous avez un emploi du temps chargé, n’hésitez pas à
          choisir le créneau et le jour de votre choix
        </p>
      </div>
      <div
        class="calendly-inline-widget"
        data-url="https://calendly.com/wmd-contact/parler-de-mon-projet"
        style="min-width: 320px; height: 630px"
      ></div>
    </div>
  </v-card>
</template>

<script>
import { db } from "@/firebase/init";
import uniqid from "uniqid";

export default {
  name: "Postuler",
  data() {
    return {
      name: "",
      email: null,
      websiteType: ["vitrine", "blog", "e-commerce"],
      type: [],
      motivation: [],
      tel: "",
      borderEmpty: "2px solid #4D92F7",
      border: "none",
      select: false,
      inputTel: false,
      inputEmail: false,
      showReasons: false,
      reasons: ["mon entreprise", "un projet personnel", "moi"],
      reason: [],
      step: 1,
      loading: false,
      feedback: null,
    };
  },
  methods: {
    showSelect() {
      this.select = true;
    },
    showReason() {
      this.showReasons = true;
    },
    showEmail() {
      this.inputEmail = true;
    },
    showTel() {
      this.inputTel = true;
    },
    nextStep() {
      this.step++;
    },
    previousStep() {
      this.step--;
    },
    sendEmailToTeam() {
      if (this.isValidPhone) {
        this.loading = true;
        let today = new Date().toLocaleString();
        const randomID = uniqid();
        db.collection("prospects").doc(randomID).set({
          name: this.name,
          reason: this.reason,
          websiteType: this.type,
          tel: this.tel,
          email: this.email,
          date: today,
          prospect_id: randomID,
        });

        setTimeout(() => {
          this.loading = false;
          this.nextStep();
        }, 1000);
      } else {
        this.feedback =
          "Veuillez renseigner un numéro de téléphone valide pour postuler";
      }
    },
  },
  computed: {
    isValidPhone: function () {
      return /^\d{10}$/.test(this.tel);
    },
    randomId: function () {
      return Math.random().toString(36).substr(2, 9);
    },
    formatTel: function (tel) {
      return `${tel.substr(0, 2)}. ${tel.substr(2, 4)}. ${tel.substr(
        4,
        6
      )}. ${tel.substr(6, 8)}. ${tel.substr(8, 10)}`;
    },
    sm: function () {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    document.head.appendChild(externalScript);
  },
};
</script>

<style>
.big-text {
  font-size: 35px;
  color: #4d92f7 !important;
}

@media only screen and (max-width: 600px) {
  .big-text {
    font-size: 25px;
  }
}
.postuler .v-select__selection--comma {
  overflow: visible !important;
  color: #4d92f7 !important;
}
</style>