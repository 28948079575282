<template>
  <v-container class="pa-0 ma-0" style="max-width:100%;">
        <v-card flat>
            <v-img src="/form-free-training.png" class="wavy"></v-img>
        </v-card>
        <v-container style="max-width:50%" class="pa-2" v-if="step === 1">
             <v-card class="px-4">
                <v-card-title>
                    Bravo et merci !
                </v-card-title>
                <v-card-text class="body-1 text-justify">
                    Nous espérons que cette formation vous a aidé à lancer ce projet qui vous tient à coeur ! En répondant à ces quelques questions, vous nous aiderez à améliorer notre Académie en ligne 🤗
                </v-card-text>
            </v-card>

             <v-container class="my-4 py-4 px-12">
                <v-row justify="start" class="pb-0 mb-0">
                   <p class="chap"> <v-icon large class="grey-wmd">mdi-numeric-1-box</v-icon> LES MOYENS 🛠</p>
                </v-row>
                <v-divider></v-divider>
                <v-row justify="start" class="mt-10">
                    <p class="question">
                        Sur une échelle de 1 à 5, comment noteriez-vous la facilité d’utilisation de l’outil de création de maquettes ?
                    </p>
                </v-row>
                <v-slider
          v-model="satisfaction_1"
          thumb-label="always"
          step="1"
          ticks="always"
          tick-size="4"
          max="5"
          thumb-size="32"
          min="1"
        >
          <template v-slot:thumb-label="{ value }">
            {{ satisfactionEmojis[value - 1] }}
          </template>
        </v-slider>

        <v-row justify="start">
                    <p class="question">
                        Sur une échelle de 1 à 5, comment noteriez-vous la facilité d’utilisation de l’Académie en Ligne (les vidéos de la formation) ?
                    </p>
                </v-row>
                <v-slider
          v-model="satisfaction_2"
          thumb-label="always"
          step="1"
          ticks="always"
          tick-size="4"
          max="5"
          thumb-size="32"
          min="1"
        >
          <template v-slot:thumb-label="{ value }">
            {{ satisfactionEmojis[value - 1] }}
          </template>
        </v-slider>

        <v-row justify="start">
                    <p class="question">
                        Sur une échelle de 1 à 5, comment noteriez-vous le format de la formation (plateforme e-learning) ?
                    </p>
                </v-row>
                 <v-slider
          v-model="satisfaction_3"
          thumb-label="always"
          step="1"
          ticks="always"
          tick-size="4"
          max="5"
          thumb-size="32"
          min="1"
        >
          <template v-slot:thumb-label="{ value }">
            {{ satisfactionEmojis[value - 1] }}
          </template>
        </v-slider>
             </v-container>

              <v-container class="my-4 py-4 px-12">
                <v-row justify="start" class="pb-0 mb-0">
                    <p class="chap"><v-icon large class="grey-wmd">mdi-numeric-2-box</v-icon> LA PÉDAGOGIE 🎓</p>
                </v-row>
                <v-divider></v-divider>
                <v-row justify="start" class="mt-10">
                    <p class="question">
                        Globalement, qu’avez-vous pensé de votre formation WebMyDay ? 
                    </p>
                    <v-textarea solo v-model="generalComment" label="Mon avis" height="150"></v-textarea>
                </v-row>
                <v-row justify="start">
                    <p class="question">
                        Comment évaluez-vous le niveau de difficulté de la formation ? 
                    </p>
                </v-row>
                <v-radio-group v-model="difficulty">
                    <v-radio label="Facile" color="green" value="Facile"></v-radio>
                    <v-radio label="Accessible" color="blue" value="Accessible"></v-radio>
                    <v-radio label="Difficile" color="red" value="Difficile"></v-radio>
                </v-radio-group>
         </v-container>

           <v-container class="my-4 py-4 px-12">
                <v-row justify="start" class="pb-0 mb-0">
                    <p class="chap"><v-icon large class="grey-wmd">mdi-numeric-3-box</v-icon> LES ACTEURS 👬</p>
                </v-row>
                <v-divider></v-divider>
                <v-row justify="start" class="mt-10">
                    <p class="question">
                        Sur une échelle de 1 à 3, comment noteriez-vous la disponibilité de votre coach ? <span class="caption grey--text">(1 - pas assez présent, 2 - comme il le fallait, 3 - sa présence m’a permis de performer )</span> 
                    </p>
                </v-row>
                 <v-slider
          v-model="coach_disponibility"
          thumb-label="always"
          step="1"
          ticks="always"
          tick-size="4"
          max="3"
          thumb-size="32"
          min="1"
        >
          <template v-slot:thumb-label="{ value }">
            {{ satisfactionEmojisLight[value - 1] }}
          </template>
        </v-slider>

        <v-row justify="start">
                    <p class="question">
                        Pensez-vous que la présence de votre coach était essentielle à votre progression ?
                    </p>
                    <v-radio-group v-model="coach_presence">
                        <v-radio label="Oui" color="green" value="Oui"></v-radio>
                         <v-radio label="Non" color="red" value="Non"></v-radio>
                    </v-radio-group>
                </v-row>

                 <v-row justify="start">
                    <p class="question">
                        Sur une échelle de 1 à 5, comment noteriez-vous la qualité de vos coachings ?
                    </p>
                </v-row>
                 <v-slider
          v-model="coach_satisfaction"
          thumb-label="always"
          step="1"
          ticks="always"
          tick-size="4"
          max="5"
          thumb-size="32"
          min="1"
        >
          <template v-slot:thumb-label="{ value }">
            {{ satisfactionEmojis[value - 1] }}
          </template>
        </v-slider>
           </v-container>

           <v-container class="my-4 py-4 px-12">
                <v-row justify="start" class="pb-0 mb-0">
                    <p class="chap"><v-icon large class="grey-wmd">mdi-numeric-4-box</v-icon> L'ORGANISATION &amp; LA LOGISTIQUE</p>
                </v-row>
                <v-divider></v-divider>
                <v-row justify="start" class="mt-10">
                    <p class="question">
                        Qu’avez-vous pensé du rythme de la formation ? <span class="caption grey--text">(Trop lent, parfait, trop rapide)</span> 
                    </p>
                </v-row>
                       <v-radio-group v-model="intensity_level">
                    <v-radio label="Parfait" color="green" value="Parfait"></v-radio>
                    <v-radio label="Trop lent" color="orange" value="Trop lent"></v-radio>
                    <v-radio label="Trop rapide" color="red" value="Trop rapide"></v-radio>
                </v-radio-group>

        <v-row justify="start">
                    <p class="question">
                        Selon-vous les objectifs de la formation ont-ils bien été formulés avant de débuter ?
                    </p>
                </v-row>
                  <v-radio-group v-model="training_objectives">
                        <v-radio label="Oui" color="green" value="Oui"></v-radio>
                         <v-radio label="Non" color="red" value="Non"></v-radio>
                    </v-radio-group>

                     <v-row justify="start">
                    <p class="question">
                        Sur une échelle de 1 à 5, comment noteriez-vous le processus de formation ?
                    </p>
                </v-row>
                 <v-slider
          v-model="training_process"
          thumb-label="always"
          step="1"
          ticks="always"
          tick-size="4"
          max="5"
          thumb-size="32"
          min="1"
        >
          <template v-slot:thumb-label="{ value }">
            {{ satisfactionEmojis[value - 1] }}
          </template>
        </v-slider>
           </v-container>


           <v-container class="my-4 py-4 px-12">
                <v-row justify="start" class="pb-0 mb-0">
                    <p class="chap"><v-icon large class="grey-wmd">mdi-numeric-5-box</v-icon> LES BÉNÉFICES 🌟</p>
                </v-row>
                <v-divider></v-divider>
                <v-row justify="start" class="mt-10">
                    <p class="question">
                        La formation a-t-elle répondu à vos attentes ?
                    </p>
                    <span class="caption grey--text"> (Pas du tout, partiellement, tout à fait, plus encore ! )</span>
                </v-row>
                 <v-slider
          v-model="general_satisfaction"
          thumb-label="always"
          step="1"
          ticks="always"
          tick-size="4"
          max="5"
          thumb-size="32"
          min="1"
        >
          <template v-slot:thumb-label="{ value }">
            {{ satisfactionEmojis[value - 1] }}
          </template>
        </v-slider>

         <v-row justify="start">
                    <p class="question">
                        Recommanderiez-vous cette formation à une personne ?
                    </p>
                </v-row>
                 <v-radio-group v-model="reco">
                        <v-radio label="Oui" color="green" value="Oui"></v-radio>
                         <v-radio label="Non" color="red" value="Non"></v-radio>
                    </v-radio-group>
                    <v-text-field label="Son adresse e-mail ? 🙈 (optionnel)" solo v-if="reco === 'Oui'" v-model="recoEmail"></v-text-field>

                     <v-row justify="start">
                    <p class="question">
                        Selon vous, avez-vous atteint vos objectifs de formation ?
                    </p>
                </v-row>
                  <v-radio-group v-model="training_objectives_met">
                        <v-radio label="Oui" color="green" value="Oui"></v-radio>
                         <v-radio label="Non" color="red" value="Non"></v-radio>
                    </v-radio-group>

                     <v-row justify="start">
                    <p class="question">
                        Votre formation avait-elle un lien avec votre métier ?
                    </p>
                </v-row>
                  <v-radio-group v-model="work_related">
                        <v-radio label="Oui" color="green" value="Oui"></v-radio>
                         <v-radio label="Non" color="red" value="Non"></v-radio>
                    </v-radio-group>

                     <v-row justify="start">
                    <p class="question">
                        Votre formation s'inscrit-elle dans un objectif professionnel ou personnel ?
                    </p>
                </v-row>
                  <v-radio-group v-model="objective_nature">
                        <v-radio label="Professionnel" color="blue" value="Professionnel"></v-radio>
                         <v-radio label="Personnel" color="green" value="Personnel"></v-radio>
                    </v-radio-group>

                      <v-row class="mt-12">
                    <v-btn color="#5192FA" dark @click="saveResults" :loading="loading">
                        Valider
                    </v-btn>
                </v-row>
           </v-container>


        </v-container>
        
        <v-container v-else style="max-width:50%" class="pa-2">
            <v-card class="px-4">
                <v-card-title>
                    Merci pour vos réponses !
                </v-card-title>
                <v-card-text class="body-1 text-justify">
                    Merci beaucoup pour toutes vos réponses ! Elles nous aideront à améliorer notre Académie en ligne et à réaliser la solution la plus complète possible !
                </v-card-text>
            </v-card>

            <v-row justify="start" class="mt-12">
                <p class="headline font-weight-bold">Vous avez apprécié votre formation WebMyDay ? <v-icon color="yellow">mdi-star</v-icon><v-icon color="yellow">mdi-star</v-icon><v-icon color="yellow">mdi-star</v-icon><v-icon color="yellow">mdi-star</v-icon><v-icon color="yellow">mdi-star</v-icon></p>
            </v-row>
            <v-row>
                <p class="body-1">Un petit commentaire sur l'une de ces plateformes nous aiderait grandement ! Merci de votre soutien 🤗</p>
            </v-row>
            <v-row justify="center">
                <v-col>
                    <a href="https://fr.trustpilot.com/evaluate/webmyday.io" target="blank" title="Lien vers Trustpilot">
                    <v-img src="/trustpilot.jpeg" class="hover-img"></v-img>
                    </a>
                </v-col>
                 <v-col>
                     <a href="https://g.page/webmyday_io/review?gm" target="blank" title="Lien vers Google Reviews">
                    <v-img src="/google_reviews.png" class="hover-img"></v-img>
                     </a>
                </v-col>
            </v-row>
        </v-container>

         
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    name: "FormEndOfTraining",
    data(){
    return {
    step: 1,
    loading: false,
    satisfactionEmojis: ['😡', '🙁', '😐', '😊', '😍'],
    satisfactionEmojisLight: ['😡', '😊', '😍'],
    satisfaction_1: 3,
    satisfaction_2: 3,
    satisfaction_3: 3,
    generalComment: "",
    difficulty: "Facile",
    coach_disponibility: 2,
    coach_presence: "Oui",
    coach_satisfaction: 3,
    intensity_level: "Parfait",
    training_objectives: "Oui",
    training_process: 3,
    general_satisfaction: 3,
    reco: "Oui",
    recoEmail: "",
    training_objectives_met: "Oui",
    work_related: "Oui",
    objective_nature: "Personnel"
    }
    },
    methods: {
        saveResults(){
           this.loading = true;
           axios({
              method: "post",
              url: `https://us-central1-webmyday-ba4d9.cloudfunctions.net/formEndOfTraining?satisfaction_1=${this.satisfaction_1}&satisfaction_2=${this.satisfaction_2}&satisfaction_3=${this.satisfaction_3}&generalComment=${this.generalComment}&difficulty=${this.difficulty}&coach_disponibility=${this.coach_disponibility}&coach_presence=${this.coach_presence}&coach_satisfaction=${this.coach_satisfaction}&intensity_level=${this.intensity_level}&training_objectives=${this.training_objectives}&training_process=${this.training_process}&general_satisfaction=${this.general_satisfaction}&reco=${this.reco}&recoEmail=${this.recoEmail}&training_objectives_met=${this.training_objectives_met}&work_related=${this.work_related}&objective_nature=${this.objective_nature}`,
              data: {
                satisfaction_1: this.satisfaction_1,
                satisfaction_2: this.satisfaction_2,
                satisfaction_3: this.satisfaction_3,
                generalComment: this.generalComment,
                difficulty: this.difficulty,
                coach_disponibility: this.coach_disponibility,
                coach_presence: this.coach_presence,
                coach_satisfaction: this.coach_satisfaction,
                intensity_level: this.intensity_level,
                training_objectives: this.training_objectives,
                training_process: this.training_process,
                general_satisfaction: this.general_satisfaction,
                reco: this.reco,
                recoEmail: this.recoEmail,
                training_objectives_met: this.training_objectives_met,
                work_related: this.work_related,
                objective_nature: this.objective_nature
              },
              headers: { "Content-Type": "multipart/form-data" },
            })
            .catch((err) => console.log(err))
                setTimeout(() => {
                this.loading = false;
                this.step += 1;  
                }, 2200);
       }
    },
}
</script>

<style>
.input-field {
    background: white;
    border: 2px  #bfbfbfaa solid;
    width: 100%;
}

.input-field:hover {
    border: 2px #BFBFBF solid;
}

.chap {
    font-size: 22px;
    font-weight: 900;
    color: #5192fa;
}

.question {
    font-size: 16px;
    font-weight: 600;
    color: #464646;
    word-break: keep-all;
}

.chip-test label {
    border-radius: 9999px;
    font-size: 14px;
    padding-left: 5px;
}

.hover-img:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.hover-img {
    transition: transform .5s ease;
}
</style>