<template>
  <v-container fluid class="pa-6">
    <v-row class="pl-6" style="display: block">
      <p class="headline font-weight-bold black--text mb-0">
        Bons plans Webmyday
        <span class="grey--text font-weight-regular"
          >({{ filteredPartnerships.length }})</span
        >
      </p>
      <v-subheader class="pl-0"
        >Retrouvez-ici l'ensemble des bons plans négociés pour vous aider à
        faire grandir votre activité 🚀
      </v-subheader>
         <span class="pl-0 caption my-0 py-0"
        ><v-icon small left>mdi-information</v-icon> Certains des liens présents dans notre catalogue sont des liens d'affiliation - nous ne recommandons aucun sercice sans l'avoir testé et approuvé nous-mêmes !
      </span>
    </v-row>
    <v-row class="px-6">
      <v-chip-group
        :show-arrows="$vuetify.breakpoint.xs"
        v-model="categories"
        active-class="blue--text text--accent-4"
        class="font-weight-medium"
        @change="lazyLoad"
      >
        <v-chip
          class="mx-1"
          :value="[
            `Création & gestion d'entreprise`,
            `WordPress`,
            `SEO`,
            `Graphisme`,
          ]"
          :class="allCatClass"
        >
          <v-icon small left :color="allColor">mdi-eye</v-icon>
          Tous
        </v-chip>
        <!-- <v-chip class="mx-1" value="Marketing et Communication">
          Marketing et Communication
        </v-chip> -->
        <!-- <v-chip class="mx-1" value="SEO"> SEO </v-chip>
        <v-chip class="mx-1" value="Juridique"> Juridique </v-chip>
        <v-chip class="mx-1" value="Assurance"> Assurance </v-chip>
        <v-chip class="mx-1" value="Comptabilité"> Comptabilité </v-chip>
        <v-chip class="mx-1" value="Plugin"> Plugin </v-chip> -->

        <v-chip class="mx-1" value="Création & gestion d'entreprise"> Création & gestion d'entreprise </v-chip>
        <v-chip class="mx-1" value="WordPress"> WordPress </v-chip>
        <v-chip class="mx-1" value="SEO"> SEO </v-chip>
        <!-- <v-chip class="mx-1" value="Graphisme"> Graphisme </v-chip> -->
      </v-chip-group>
    </v-row>
    <v-row justify="start">
      <v-col
        cols="12"
        md="4"
        v-for="(partnership, index) in filteredPartnerships"
        :key="index"
        class="d-flex flex-column my-2"
      >
        <v-skeleton-loader
          type="card"
          :loading="loading"
          class="flex d-flex flex-column"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card class="flex d-flex flex-column dashboard-card pa-2" flat>
                <v-img :src="partnership.cover" max-height="150">
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute>
                      <v-btn
                        color="blue accent-4"
                        dark
                        v-if="partnership.link"
                        :href="partnership.link"
                        target="_blank"
                        >profiter de l'offre
                        <v-icon right>mdi-open-in-new</v-icon></v-btn
                      >
                      <v-btn
                        v-else
                        color="blue accent-4"
                        dark
                        href="https://airtable.com/shrXBsCw2N8Ehs8if"
                        target="_blank"
                        ><v-icon left>mdi-email</v-icon> Nous contacter</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
                <v-card-subtitle class="font-weight-medium black--text pl-1">
                  {{ partnership.headline }}
                </v-card-subtitle>

                <v-card-text>
                  <v-chip dark color="blue accent-4" label small>
                    <v-icon left small>mdi-gift</v-icon>
                    {{ partnership.offer }}
                  </v-chip>

                  <v-chip
                    dark
                    color="amber darken-2"
                    label
                    small
                    class="ml-1"
                    v-if="partnership.hasPromoCoupon"
                  >
                    <v-icon left small>mdi-ticket-percent</v-icon>
                    {{ partnership.coupon }}
                  </v-chip>

                  <v-row
                    justify="center"
                    no-gutters
                    class="px-2 mb-0 mx-0"
                    style="width: 100%"
                  >
                    <v-col cols="4" align-self="center">
                      <img
                        :src="partnership.picture"
                        style="max-width: 60px; max-height: 60px"
                      />
                    </v-col>
                    <v-col>
                      <v-row
                        class="mb-0 pb-0 pt-4"
                        style="display: inline-block"
                      >
                        <p
                          class="font-weight-bold black--text pb-0 mb-0"
                          style="font-size: 15px"
                        >
                          {{ partnership.brand }}
                        </p>
                        <p style="font-size: 12px">
                          {{ partnership.title }}
                        </p>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Partnerships",
  data() {
    return {
      loading: true,
      category: "",
      categories: [
      `Création & gestion d'entreprise`,
            `WordPress`,
            `SEO`,
            `Graphisme`,
      ],
    };
  },
  computed: {
    ...mapState(["partnerships"]),
    filteredPartnerships: function () {
      return this.partnerships.filter((p) => this.categories.includes(p.type));
    },
    allColor: function () {
      if (this.categories.length === 4) {
        return "blue accent-4";
      } else {
        return "";
      }
    },
    allCatClass: function () {
      if (this.categories.length === 4) {
        return "blue--text text--accent-4 v-chip--active";
      } else {
        return "";
      }
    },
  },
  methods: {
    lazyLoad() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 600);
    },
    allCat() {
      if (this.categories.length < 5) {
        this.categories.push(
          `Création & gestion d'entreprise`,
            `WordPress`,
            `SEO`,
            `Graphisme`,
        );
      } else {
        this.categories = [];
      }
    },
    log() {
      console.log(this.categories);
    },
  },
  async mounted() {
   await this.$store.dispatch("setPartnerships");
      setTimeout(() => {
        this.loading = false;
      }, 1200);

  },
};
</script>

<style>
</style>