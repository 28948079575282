<template>
  <v-container fluid class="pa-6">
    <v-row class="pl-6" style="display: block">
      <p class="headline font-weight-bold black--text mb-0">
        Vidéos de formation
      </p>
      <v-subheader class="pl-0">
        Suivez les différents modules vidéos pour atteindre vos objectifs de
        formation 🚀
      </v-subheader>
    </v-row>
    <v-tabs color="#2862FF" hide-slider :show-arrows="$vuetify.breakpoint.xs">
      <v-tab class="main-tab">
        <v-icon left>mdi-information</v-icon>
        Vue d'ensemble
      </v-tab>

      <v-tab class="main-tab">
        <v-icon left>mdi-format-list-bulleted-square</v-icon>
        Sommaire détaillé
      </v-tab>

      <v-tab-item class="py-4 mt-4">
        <v-row>
          <v-col
            cols="12"
            md="4"
            v-for="(modul, index) in modules_v2"
            :key="index"
            class="my-2"
          >
            <v-skeleton-loader type="card" :loading="loadingModules">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="dashboard-card pa-2"
                  :disabled="
                    modul.disabled ||
                    isDisabled(index) ||
                    modul.chapters.length === 0 ||
                    someVideosEmpty(index)
                  "
                  flat
                  ><v-img :src="modul.pic" class="rounded">
                    <v-fade-transition>
                      <v-overlay v-if="hover" absolute>
                        <v-btn
                          fab
                          color="blue accent-4"
                          @click="resumeModule(index)"
                          ><v-icon>mdi-play</v-icon></v-btn
                        >
                      </v-overlay>
                    </v-fade-transition>
                  </v-img>
                  <v-row class="mt-2 mb-1 py-0">
                    <v-col>
                      <v-chip
                        label
                        color="#E9EDFE"
                        text-color="blue accent-4"
                        class="font-weight-medium"
                      >
                        Module {{ modul.index }}
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-card-subtitle
                    class="font-weight-medium black--text pb-1 pl-1 pt-0 mt-0"
                  >
                    {{ modul.title }}
                  </v-card-subtitle>

                  <v-row class="my-1" justify="start" align="start">
                    <v-col cols="3" class="pr-0">
                      <v-chip
                        small
                        style="background: white; color: #88a6c9"
                        color="#88a6c9"
                        ><v-icon left small>mdi-timer</v-icon>
                        {{ modul.length }}</v-chip
                      >
                    </v-col>

                    <v-col cols="3" class="px-0">
                      <v-chip
                        small
                        style="background: white; color: #88a6c9"
                        color="#88a6c9"
                      >
                        <v-icon left small>mdi-youtube</v-icon
                        >{{ numberOfLessons(modul) }} cours</v-chip
                      >
                    </v-col>

                    <v-col cols="4" class="pl-0 pr-0">
                      <v-chip
                        small
                        style="background: white; color: #88a6c9"
                        color="#88a6c9"
                      >
                        <v-icon left small>mdi-signal-cellular-3</v-icon>
                        {{ moduleDifficulty(modul.level) }}</v-chip
                      >
                    </v-col>
                  </v-row>

                  <v-row v-if="modul.author" class="pt-4 pl-2">
                    <v-col class="caption new-grey--text">
                      <v-avatar size="30">
                        <v-img :src="modul.author.pic"></v-img>
                      </v-avatar>
                      <span class="pl-2">{{ modul.author.name }}</span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row class="mb-0 caption pt-2">
                    <v-col align-self="center" cols="5">
                      <v-progress-linear
                        rounded
                        :value="Math.round(moduleProgress(index))"
                        color="blue accent-4"
                        height="8"
                      >
                      </v-progress-linear>
                    </v-col>
                    <v-col
                      cols="2"
                      class="caption new-grey--text"
                      align-self="center"
                    >
                      {{ Math.round(moduleProgress(index)) }}%
                    </v-col>
                    <v-col align-self="center" cols="4">
                      <v-btn
                        small
                        text
                        color="blue accent-4"
                        v-if="
                          Math.round(moduleProgress(index)) > 0 &&
                          !someVideosEmpty(index)
                        "
                        @click="resumeModule(index)"
                        >Reprendre
                        <v-icon right>mdi-chevron-right</v-icon></v-btn
                      >
                      <v-btn
                        small
                        text
                        v-else-if="
                          Math.round(moduleProgress(index)) === 0 &&
                          !someVideosEmpty(index)
                        "
                        @click="resumeModule(index)"
                        >Commencer<v-icon right
                          >mdi-chevron-right</v-icon
                        ></v-btn
                      >
                      <v-btn text light v-else disabled small
                        >Commencer
                        <v-icon right>mdi-chevron-right</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-hover>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item class="py-4 mt-4">
        <v-card
          v-for="(modul, index) in modules_v2"
          :key="index"
          flat
          class="mb-4 mt-2 dashboard-card"
          color="transparent"
          :disabled="
            modul.disabled ||
            isDisabled(index) ||
            modul.chapters.length === 0 ||
            someVideosEmpty(index)
          "
        >
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-col cols="1" v-if="isDisabled(modul.id)">
                  <v-icon>mdi-lock</v-icon>
                </v-col>
                <v-col cols="12" md="8">
                  <v-row style="display: inline-grid">
                    <v-chip
                      label
                      color="#E9EDFE"
                      text-color="blue accent-4"
                      class="font-weight-medium mb-1"
                      style="max-width: 85px"
                    >
                      Module {{ modul.index }}
                    </v-chip>
                    <span
                      class="font-weight-medium black--text pt-2"
                      style="font-size: 16px"
                      >{{ modul.title }}</span
                    >
                  </v-row>
                </v-col>

                <v-col cols="3" v-if="!$vuetify.breakpoint.xs">
                  <v-chip
                    v-if="modul.chapters.length === 0 || someVideosEmpty(index)"
                    label
                    >Arrive bientôt !</v-chip
                  >
                  <v-progress-linear
                    v-else
                    color="#2862FF"
                    height="8"
                    style="max-width: 200px"
                    rounded
                    :value="moduleProgress(index)"
                  ></v-progress-linear>
                </v-col>
                <v-col cols="1" class="new-grey--text" v-if="!$vuetify.breakpoint.xs">
                  {{ Math.round(moduleProgress(index)) }}%
                </v-col>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <v-expansion-panels  flat focusable>
                  <v-expansion-panel
                    v-for="(chapter, i) in modul.chapters"
                    :key="i"
                  >
                    <v-expansion-panel-header class="py-1">
                      <v-row justify="center" align="center" class="my-0">
                        <v-col md="1" cols="2" align-self="center">
                          <v-progress-circular
                            :value="chapterProgress(index, i)"
                            color="#2862FF"
                            size="30"
                            v-if="dataLoaded"
                          >
                            <v-icon
                              small
                              v-if="chapterProgress(index, i) == 100"
                              color="#2862FF"
                              >mdi-check-bold</v-icon
                            >
                          </v-progress-circular>
                        </v-col>
                        <v-col align-self="center">
                          {{ `Chapitre ${i + 1} : ${chapter.title}` }}
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list dense>
                        <v-list-item
                          v-for="(lesson, num) in chapter.lessons"
                          :key="num"
                          link
                          :to="{
                            name: 'lesson',
                            params: {
                              module: index + 1,
                              chapter: i + 1,
                              lesson: num + 1,
                            },
                          }"
                          :disabled="lesson.url === '' || isDisabled(modul.id)"
                        >
                          <v-list-item-title>
                            <v-list-item-icon
                              ><v-icon
                                size="20"
                                color="#2862FF"
                                :disabled="uncompleteLesson(index, i, num)"
                                v-if="!isDisabled(modul.id)"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                size="20"
                                color="#2862FF"
                                :disabled="uncompleteLesson(index, i, num)"
                                v-else
                                >mdi-lock</v-icon
                              >
                            </v-list-item-icon>

                            <span class="lesson-title">{{
                              `${num + 1}. ${lesson.title}`
                            }}</span>
                            <v-chip
                              color="#2862FF"
                              dark
                              v-if="lesson.url === ''"
                              style="position: absolute; right: 0"
                              >Arrive bientôt !</v-chip
                            >
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <!-- <v-overlay :value="loadingOverlay" z-index="10" absolute opacity="0.98">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->
    <!-- <v-overlay
      :value="loadingOverlay"
      z-index="10"
      opacity="0.98"
      color="white"
    >
      <img src="/logo-loading.gif" />
    </v-overlay> -->
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Academy",
  data() {
    return {
      dataLoaded: false,
      loadingOverlay: true,
      search: "",
      moduleCards: [],
      loadingModules: true,
      hover: false,
    };
  },
  computed: {
    ...mapState(["modules_v2", "chapter", "progressModules", "user_data"]),
    filteredModules: function () {
      return this.modules_v2.filter((modul) => {
        return (
          modul.title.toLowerCase().includes(this.search.toLowerCase()) ||
          modul.chapters.some(
            (chap) =>
              chap.title.toLowerCase().includes(this.search.toLowerCase()) ||
              chap.lessons.some((lesson) =>
                lesson.title.toLowerCase().includes(this.search.toLowerCase())
              )
          )
        );
      });
    },
    filteredChapters: function (index) {
      return this.filteredModules[index].chapters.filter((chapter) =>
        chapter.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    averageProgress() {
      // let modules = this.modules_v2;
      // this.user_data.project.selectedPackage < 3 ? modules.pop() : modules;
      let numberOfModules = this.modules_v2.length;

      let averageModulesProgress = 0;

      this.modules_v2.forEach((modul, modulIndex) => {
        averageModulesProgress += this.moduleProgress(modulIndex);
      });

      return averageModulesProgress / numberOfModules;
    },
  },
  methods: {
    numberOfLessons(modul) {
      let lessons = 0;
      modul.chapters.forEach((chap) => {
        lessons += chap.lessons.length;
      });
      return lessons;
    },
    someVideosEmpty(index) {
      return this.modules_v2[index].chapters.some((chapter) =>
        chapter.lessons.some((lesson) => lesson.url === "")
      );
    },
    isDisabled(id) {
      let bool;
      if (
        id == "CQ0HIBSCA5MmioCroZ2k" &&
        this.user_data.project.selectedPackage < 3
      ) {
        bool = true;
      } else {
        bool = false;
      }

      return bool;
    },
    moduleDifficulty(level) {
      if (level >= 1 && level < 3) {
        return "Facile";
      } else if (level >= 3 && level < 5) {
        return "Intermédiaire";
      } else {
        return "Difficile";
      }
    },
    resumeModule(moduleIndex) {
      let unSeenLessons = [];
      if (
        this.moduleProgress(moduleIndex) === 0 ||
        this.moduleProgress(moduleIndex) === 100
      ) {
        this.$router.push({
          path: `/academie/course/${this.$route.params.course}/${
            moduleIndex + 1
          }/1/1`,
        });
      } else {
        this.modules_v2[moduleIndex].chapters.forEach((chapter, chapIndex) => {
          chapter.lessons.forEach((less, lessIndex) => {
            if (
              !this.progressModules[moduleIndex][`chapter_${chapIndex + 1}`][
                `lesson_${lessIndex + 1}`
              ]
            ) {
              unSeenLessons.push({
                chapPath: chapIndex + 1,
                lessPath: lessIndex + 1,
              });
            }
          });
        });

        this.$router.push({
          path: `/academie/course/${this.$route.params.course}/${
            moduleIndex + 1
          }/${unSeenLessons[0].chapPath}/${unSeenLessons[0].lessPath}`,
        });
      }
    },
    uncompleteLesson(modul, chap, less) {
      return !this.progressModules[modul][`chapter_${chap + 1}`][
        `lesson_${less + 1}`
      ];
    },
        numberOfLessonsCompleted(modul, chap, lessons) {
      let arr = [];

      lessons.forEach((less, index) => {
        if (this.completedLesson(modul, chap, index)) {
          arr.push(less);
        }
      });

      return arr.length;
    },
      completedLesson: function (modul, chap, less) {
      return this.progressModules[modul][`chapter_${chap + 1}`][
        `lesson_${less + 1}`
      ];
    },
    chapterProgress(modul, chap) {
      if(this.modules_v2.length > 0){

     
      let chapterLength = this.modules_v2[modul].chapters[chap].lessons.length;
      let lessonsCompleted = 0;

      if (this.progressModules[modul]) {
        // Object.keys(this.progressModules[modul][`chapter_${chap + 1}`]).forEach(
        //   (lesson) => {
        //     let lessonIndex = parseInt(lesson.slice(-1)) - 1;

        //     if (!this.uncompleteLesson(modul, chap, lessonIndex)) {
        //       lessonsCompleted++;
        //     }
        //   }
        // );

         lessonsCompleted = this.numberOfLessonsCompleted(modul, chap, this.modules_v2[modul].chapters[chap].lessons)

        return parseFloat((lessonsCompleted / chapterLength) * 100);
      }
       }
    },
    moduleProgress(index) {
      let moduleLength = this.modules_v2[index].chapters.length;
      let averageProgress = 0;

      this.modules_v2[index].chapters.forEach((chapter, chapIndex) => {
        averageProgress += this.chapterProgress(index, chapIndex);
      });

      return averageProgress / moduleLength;
    },
  },
  async mounted() {
    await this.$store.dispatch("setUserData");
    await this.$store.dispatch("setModulesV2", this.$route.params.course);
    await this.$store.dispatch("setProgressModules", this.$route.params.course);
    await this.$store.dispatch("updateStaticGlobalProgress", {
      course: this.$route.params.course,
      progress: Math.round(this.averageProgress),
    });

    this.dataLoaded = true;

    setTimeout(() => {
      this.loadingModules = false;
    }, 1200);
  },
};
</script>

<style>
.module-title {
  font-size: 25px;
}

.chapter-title {
  color: #34485d;
  font-size: 18px;
  cursor: pointer;
}

.lesson-title:hover {
  color: #2862ff;
}

.chapter-title:hover {
  color: #2862ff;
  font-weight: 600;
}

.new-blue-wmd {
  color: #2862ff !important;
}

.v-expansion-panel-header--active.chapter-title {
  background: #d2dbfa;
  color: #2862ff;
  font-weight: 600;
}

/* .v-list-item__title:hover {
  color: #2862FF !important;
  font-weight: 600;
} */

.theme--light.v-tabs > .v-tabs-bar,
.theme--light.v-tabs-items {
  background: transparent !important;
}

.main-tab {
  font-size: 16px;
}

.hover-img:hover {
  transform: scale(1.04);
  cursor: pointer;
}

.hover-img {
  transition: transform 0.5s ease;
}

.hover-img-2:hover {
  transform: scale(1.02);
  cursor: pointer;
}

.hover-img-2 {
  transition: transform 0.5s ease;
}
</style>