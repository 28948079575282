<template>
  <v-dialog v-model="popupSecurity" max-width="600" persistent>
    <v-card v-if="type == 1">
      <v-card-title class="headline" style="background-color: #edf4ff">
        Mettre à jour votre addresse e-mail
        <v-icon
          @click="emitSecurityDialog"
          style="position: absolute; right: 17px"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-container class="pa-4">
        <v-row justify="center" align="center" class="pa-4">
          <p>
            Vous êtes sur le point de modifier votre addresse e-mail. Attention,
            cette action sera <strong>irréversible</strong>, et vous ne pourrez
            plus accéder à votre compte avec votre ancienne addresse e-mail !
          </p>
        </v-row>
        <v-row justify="center" align="center" class="px-4">
          <v-text-field
            label="Addresse e-mail"
            filled
            color="blue accent-4"
            v-model="user_data.email"
            :rules="emailRules"
          >
          </v-text-field>
        </v-row>
      </v-container>
      <p class="red--text caption" v-if="feedback">{{ feedback }}</p>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue accent-4"
          text
          @click="emitUpdateEmail(user_data.email)"
          :loading="loadingEmail"
          >Mettre à jour</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-card v-if="type == 2">
      <v-card-title class="headline" style="background-color: #edf4ff">
        Mettre à jour votre mot de passe
        <v-icon
          @click="emitSecurityDialog"
          style="position: absolute; right: 17px"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-container class="pa-4">
        <v-row justify="center" align="center" class="pa-4">
          <p>
            Vous êtes sur le point de modifier votre mot de passe. Cette action
            sera <strong>irréversible</strong>, et vous ne pourrez plus accéder
            à votre compte avec votre ancien mot de passe !
          </p>
        </v-row>
        <v-row justify="center" align="center" class="px-4">
          <v-text-field
            label="Nouveau mot de passe"
            filled
            color="blue accent-4"
            v-model="newPassword"
            :rules="passwordRules"
            :type="passwordType"
            @click:append="hideAndShow"
            :append-icon="iconPassword"
          >
          </v-text-field>
        </v-row>
      </v-container>
      <p class="red--text caption" v-if="feedback">{{ feedback }}</p>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue accent-4" text @click="emitUpdatePassword()"
          >Mettre à jour</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PopupSecurity",
  props: [
    "user_data",
    "popupSecurity",
    "type",
    "emailRules",
    "feedback",
    "loadingEmail",
  ],
  data() {
    return {
      newPassword: null,
      passwordType: "password",
      iconPassword: "mdi-eye",
      passwordRules: [
        (v) => !!v || "Veuillez rentrer un mot de passe valide !",
      ],
    };
  },
  methods: {
    emitSecurityDialog() {
      this.$emit("securityDialogToParent");
    },
    emitUpdateEmail(email) {
      this.$emit("updateEmailToParent", email);
    },
    emitUpdatePassword() {
      this.$emit("updatePasswordToParent", this.newPassword);
    },
    hideAndShow() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
      if (this.passwordType === "text") {
        this.iconPassword = "mdi-eye-off";
      } else {
        this.iconPassword = "mdi-eye";
      }
    },
  },
};
</script>

<style>
</style>