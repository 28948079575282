<template>
  <v-container fluid class="pa-6">
    <v-row class="pl-6" style="display: block">
      <p class="headline font-weight-bold black--text mb-0">
        Ressources utiles
        <span class="grey--text font-weight-regular"
          >({{ filteredRessources.length }})</span
        >
      </p>
      <v-subheader class="pl-0">
        Retrouvez ici en un coup d'oeil tous les supports présents dans les
        modules de formation et bien plus encore 🗂
      </v-subheader>
    </v-row>

    <v-row class="px-6" v-if="$route.params.course === 'a0d4QvJlvYY3KufEnk2a'">
      <v-chip-group
         :show-arrows="$vuetify.breakpoint.xs"
        v-model="categories"
        active-class="blue--text text--accent-4"
        class="font-weight-medium"
        @change="lazyLoad"
      >
        <v-chip
          class="mx-1"
          :value="['Fiches', 'Documents', 'Modèles', 'Thèmes', 'Plugins', 'Exercices']"
          :class="allCatClass"
        >
          <v-icon small left :color="allColor">mdi-eye</v-icon>
          Tous
        </v-chip>
        <v-chip class="mx-1" value="Fiches"> Fiches </v-chip>
        <v-chip class="mx-1" value="Documents"> Documents utiles</v-chip>
        <v-chip class="mx-1" value="Modèles"> Modèles </v-chip>
        <v-chip class="mx-1" value="Thèmes">Thèmes</v-chip>
        <v-chip class="mx-1" value="Plugins">Plugins</v-chip>
      </v-chip-group>
    </v-row>

      <v-row class="px-6" v-else>
      <v-chip-group
       :show-arrows="$vuetify.breakpoint.xs"
        v-model="categories"
        active-class="blue--text text--accent-4"
        class="font-weight-medium"
        @change="lazyLoad"
      >
        <v-chip
          class="mx-1"
          :value="['Fiches', 'Documents', 'Modèles', 'Thèmes', 'Plugins', 'Exercices']"
          :class="allCatClass"
        >
          <v-icon small left :color="allColor">mdi-eye</v-icon>
          Tous
        </v-chip>
        <v-chip class="mx-1" value="Fiches"> Fiches </v-chip>
        <v-chip class="mx-1" value="Documents"> Documents utiles</v-chip>
        <v-chip class="mx-1" value="Exercices"> Exercices </v-chip>
        <v-chip class="mx-1" value="Modèles"> Modèles </v-chip>
      </v-chip-group>
    </v-row>


    <v-skeleton-loader type="table" :loading="loading">
      <v-card flat class="py-6 dashboard-card">
        <v-data-table
          :items="filteredRessources"
          :headers="headers"
          hide-default-footer
          hide-default-header
          no-data-text="Aucune ressource à afficher"
          id="ressources-table"
         sort-by="cat"
          :items-per-page="40"
        >
          <template v-slot:[`item.picture`]="{ item }">
            <v-card max-width="160"  v-if="!$vuetify.breakpoint.xs">
              <v-img :src="item.picture"></v-img>
            </v-card>
             <v-card v-else>
              <v-img :src="item.picture" max-width="60"></v-img>
            </v-card>
          </template>

          <template v-slot:[`item.title`]="{ item }">
            <span class="body-1 font-weight-medium">{{ item.title }}</span>
          </template>

          <template v-slot:[`item.cat`]="{ item }">
            <v-chip label outlined>
              <v-icon left small>{{ catIcon(item.cat) }}</v-icon> {{ item.cat }}
            </v-chip>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              :href="item.link"
              target="_blank"
              text
              color="blue accent-4"
              v-if="isDownloadable(item.cat)"
              small
              :disabled="user_data.project.selectedPackage === 0"
            >
              Télécharger
              <v-icon right>mdi-download</v-icon>
            </v-btn>
            <v-btn
              :href="item.link"
              target="_blank"
              text
              color="blue accent-4"
              :disabled="user_data.project.selectedPackage === 0"
              small
              v-else
            >
              Ouvrir
              <v-icon right>mdi-open-in-new</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Ressources",
  data() {
    return {
      loading: true,
      headers: [
        {
          text: "Picture",
          align: "start",
          sortable: false,
          value: "picture",
        },
        {
          text: "Titre",
          align: "start",
          sortable: false,
          value: "title",
        },

        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "cat",
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      category: "",
      categories: ["Fiches", "Documents", "Modèles", "Thèmes", "Plugins", "Exercices"],
    };
  },
  computed: {
    ...mapState(["ressources", "user_data"]),
    filteredRessources: function () {
      return this.ressources.filter((r) => this.categories.includes(r.cat));
    },
    allColor: function () {
      if (this.checkCategories(this.categories)) {
        return "blue accent-4";
      } else {
        return "";
      }
    },
    allCatClass: function () {
      if (this.checkCategories(this.categories)) {
        return "blue--text text--accent-4 v-chip--active";
      } else {
        return "";
      }
    },
  },
  methods: {
  checkCategories(categories) {
  const requiredCategories = ['Fiches', 'Documents', 'Exercices', 'Modèles', 'Thèmes', 'Plugins'];

  for (const category of requiredCategories) {
    if (!categories.includes(category)) {
      return false;
    }
  }

  return true; 
},
    isDownloadable(type){
     if (type === "Documents" || type === "Fiches" || type === "Modèles") {
        return false;
      }

      else {
        return true
      }
    },

    catIcon(cat) {
      if (cat === "Documents") {
        return "mdi-file-document";
      } else if (cat === "Fiches") {
        return "mdi-file-pdf-box";
      } else if (cat === "Modèles") {
        return "mdi-file-chart";
      } else if (cat === "Plugins") {
        return "mdi-power-plug";
      } else if (cat === "Thèmes") {
        return "mdi-brush";
      }
      else if (cat === "Exercices") {
        return "mdi-dumbbell";
      }
    },
    lazyLoad() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 600);
    },
    allCat() {
      if (this.categories.length < 4) {
        this.categories.push(
          "Fiches",
          "Documents",
          "Modèles",
          "Thèmes",
          "Plugins"
        );
      } else {
        this.categories = [];
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("setRessources", this.$route.params.course);
    setTimeout(() => {
      this.loading = false;
    }, 1200);
  },
};
</script>

<style>
#ressources-table > div > table > tbody > tr:hover {
  background: white !important;
}

#ressources-table > div > table > tbody > tr {
  border-bottom: 8px solid white !important;
  margin-bottom: 10px;
}
</style>