<template>
  <v-app-bar flat color="transparent">
    <img :src="logo" style="max-width:60px;" />
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-row>
        <v-list v-for="(page, index) in pages" :key="index">
          <v-list-item link @click="loadPage(page)" v-if="page.showInMenu">
            <v-list-item-title
              :style="[page.slug == currentPage.slug ? {color: currentPageColor} : {color: pagesColor}]"
              class="font-weight-bold body-2"
            >{{page.name}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-row>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
export default {
  name: "firstMenu",
  props: ["logo", "pages", "currentPage", "loadPage"],
  data() {
    return {
      pagesColor: "rgba(0, 0, 0, 0.6)",
      currentPageColor: "#4d92f7",
    };
  },
};
</script>

<style>
</style>