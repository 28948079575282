<template>
  <div>
    <v-card color="#f4f7fa">
      <v-navigation-drawer
        permanent
        fixed
        hide-overlay
        color="white"
        light
        left
        height="100%"
        width="45%"
        style="margin-top: 47px"
      >
        <v-list nav class="left-align py-0" dense>
          <v-row justify="start" align-content="start" align="start">
            <v-card
              height="auto"
              class="container"
              color="#f4f7fa"
              flat
              min-height="900"
            >
              <v-row style="height: 100%" class="py-0 my-0">
                <v-col style="height: 100%; background-color: #f4f7fa">
                  <div class="container center-align">
                    <h4 class="headline font-weight-black">Mes maquettes</h4>
                  </div>
                  <draggable :list="pages">
                    <v-row
                      align="center"
                      justify="center"
                      v-for="(page, index) in pages"
                      :key="index"
                    >
                      <v-skeleton-loader
                        type="card"
                        width="400"
                        height="80"
                        :loading="loadingPages"
                      >
                        <v-card
                          color="white"
                          width="auto"
                          min-width="400"
                          max-width="400"
                          class="mb-2"
                          style="border-radius: 20px; cursor: move"
                          height="80"
                        >
                          <v-row>
                            <v-col>
                              <v-card-title style="font-size: 16px">{{
                                page.name
                              }}</v-card-title>
                            </v-col>

                            <v-col align-self="end" class="py-4">
                              <v-card-text>
                                <v-chip
                                  small
                                  class="px-2"
                                  v-if="page.showInMenu"
                                  >menu</v-chip
                                >
                                <v-chip
                                  small
                                  class="px-2 ml-1"
                                  v-if="page.showInFooter"
                                  >pied de page</v-chip
                                >
                              </v-card-text>
                            </v-col>
                          </v-row>

                          <v-btn
                            icon
                            fab
                            absolute
                            style="top: 0px; right: 40px"
                            x-small
                            @click="editPopUp(page)"
                          >
                            <v-icon size="18">mdi-pencil</v-icon>
                          </v-btn>

                          <v-btn
                            icon
                            fab
                            absolute
                            style="top: 0px; right: 20px"
                            x-small
                            @click="deletePopUp(page)"
                          >
                            <v-icon size="18">mdi-delete</v-icon>
                          </v-btn>
                        </v-card>
                      </v-skeleton-loader>
                    </v-row>
                  </draggable>
                  <v-row align="center" justify="center">
                    <v-btn
                      fab
                      color="blue accent-4"
                      large
                      @click="newPagePopUp"
                    >
                      <v-icon color="white">mdi-plus</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-list>
      </v-navigation-drawer>
    </v-card>

    <PopupNewPage
      :pages="pages"
      :user="user"
      :project="project"
      :popup="popup"
      :page="page"
      :type="popupType"
      :currentPage="currentPage"
      v-on:loadingPagesToParent="loadingPagesfromChild"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import PopupNewPage from "./PopupNewPage";

export default {
  name: "LabSideArchi",
  components: {
    PopupNewPage,
    draggable,
  },
  data() {
    return {
      type: 3,
      popupArchi: false,
      page: null,
      popupType: null,
      loadingPages: false,
    };
  },
  props: ["pages", "user", "project", "popup", "currentPage"],
  methods: {
    openPopup(value) {
      this.type = value;
      this.popupArchi = true;
    },
    popupFromChild(value) {
      this.popupArchi = value;
    },
    newPagePopUp() {
      this.popupType = 1;
      this.$store.commit("togglePopUp");
    },
    editPopUp(page) {
      this.page = page;
      this.$store.dispatch("setCurrentPage", page.slug).then(() => {
        this.popupType = 2;
        this.$store.commit("togglePopUp");
      });
    },
    deletePopUp(page) {
      this.page = page;
      this.$store.dispatch("setCurrentPage", page.slug).then(() => {
        this.popupType = 3;
        this.$store.commit("togglePopUp");
      });
    },
    loadingPagesfromChild(value) {
      this.loadingPages = value;
    },
  },
};
</script>

<style>
button:focus {
  background-color: white !important;
}
</style>