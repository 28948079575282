<template>
  <v-container
    class="mb-0 mx-0 pa-0 px-0 home-container"
    style="width: 100%; height: 100%"
    fluid
  >
    <v-snackbar v-model="emailRecoverySent" multi-line bottom :timeout="6500">
      Nous venons de vous envoyer le lien de réinitialisation de mot de passe à
      l'adresse e-mail <strong>{{ email }}</strong
      >, vérifiez votre boîte de réception (et vos spams) !

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue accent-4"
          text
          v-bind="attrs"
          @click="emailRecoverySent = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-row
      no-gutters
      style="width: 100%; height: 100%"
      class="home-row my-0"
    >
      <v-col v-if="!$vuetify.breakpoint.xs">
        <v-card height="100%" class="pa-6" flat tile >
          <img src="/logo-webmyday-black.webp" style="max-width: 180px" />
          <v-row
            style="height: 100%"
            align="center"
            justify="center"
            class="my-0"
          >
            <v-img src="/login_gif.gif" max-width="500" width="auto"></v-img>
          </v-row>
        </v-card>
      </v-col>

      <v-col class="main-col">
        <v-card color="white" height="100%" flat tile>
                 <v-row  v-if="$vuetify.breakpoint.xs" class="px-6 mb-0 pb-0 pt-6" align="center">
             <img src="/logo-webmyday-black.webp" style="max-width: 180px" />
 
          </v-row>
          <v-row
            style="height: 100%"
            align="center"
            justify="start"
            class="my-0 home-row"
          >
            <v-col>
              <v-form
                @submit.prevent="login"
                style="max-width: 600px"
                class="px-4"
                v-if="!isRecovery"
              >
                <h5 class="font-weight-bold home-title">
                  Bienvenue dans votre académie 🎓
                </h5>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                  outlined
                  class="pt-6"
                  color="blue accent-4"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  :rules="passwordlRules"
                  label="Mot de passe"
                  required
                  :type="type"
                  autocomplete="on"
                  @click:append="hideAndShow"
                  @keyup.enter="login"
                  outlined
                  color="blue accent-4"
                  :append-icon="iconPassword"
                ></v-text-field>

                <p class="center-align red--text caption" v-if="feedback">
                  {{ feedback }}
                </p>
                <v-btn
                  color="blue accent-4"
                  dark
                  :loading="loading"
                  type="submit"
                  block
                  >Connexion</v-btn
                >
              </v-form>
              

              <v-form
                @submit.prevent="resetPassword"
                style="max-width: 600px"
                class="px-4"
                v-else
              >
                <h5 class="font-weight-bold">
                  Confirmez votre adresse e-mail 📬
                </h5>
                <p>
                  Vous recevrez un e-mail contenant un
                  <strong>lien de réinitialisation</strong> de votre mot de
                  passe. Vérifiez vos spams 🤓
                </p>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                  outlined
                  class="pt-6"
                  color="blue accent-4"
                ></v-text-field>

                <p class="center-align red--text caption" v-if="feedback">
                  {{ feedback }}
                </p>

                <v-card-actions>
                  <v-btn
                    text
                    @click="
                      isRecovery = false;
                      feedback = null;
                    "
                    ><v-icon left>mdi-chevron-left</v-icon> Retour</v-btn
                  >

                  <v-spacer></v-spacer>

                  <v-btn
                    color="blue accent-4"
                    dark
                    :loading="loadingRecovery"
                    type="submit"
                    @click="resetPassword"
                    >Confirmer</v-btn
                  >
                </v-card-actions>
              </v-form>
              <v-row class="px-6 pt-4 mb-0 pb-0" v-if="!isRecovery">
                <v-btn
                  text
                  @click="
                    isRecovery = true;
                    feedback = null;
                  "
                  >Mot de passe oublié ?</v-btn
                >
              </v-row>

              <v-row class="px-6 pt-4 mb-0 pb-0">
                <p>- ou -</p>
                <v-btn class="ml-2"
                  @click="loginWithGoogle"
                  ><v-icon left>mdi-google</v-icon>Se connecter avec Google</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <!-- <v-container
    v-else
    style="background: #33495d; width: 100%; height: 100%"
    class="white--text px-6 center-align"
    :mobile-breakpoint="1024"
  >
    <v-row justify="center" align="center" class="pt-5" style="display: block">
      <img
        src="/logo_blanc.svg"
        alt="Académie WebMyDay"
        title="Académie WebMyDay"
        width="120"
      />
      <h1 class="headline font-weight-bold">
        Bienvenue dans votre Académie WordPress WebMyDay !
      </h1>
    </v-row>
    <v-row justify="center" align="center" class="px-3">
      <h2 class="text-justify body-2">
        Afin de profiter de
        <strong>toutes les fonctionnalités</strong> de votre Académie, merci de
        vous reconnecter sur votre <strong>ordinateur</strong> !
      </h2>
    </v-row>
  </v-container> -->
</template>

<script>
import firebase from "firebase";
import { mapState } from "vuex";


export default {
  name: "Home",
  data() {
    return {
      loading: false,
      loadingRecovery: false,
      email: "",
      type: "password",
      emailRules: [
        (v) => !!v || "Veuillez rentrer une adresse e-mail valide !",
        (v) =>
          /.+@.+\..+/.test(v) || "Veuillez rentrer une adresse e-mail valide !",
      ],
      passwordlRules: [
        (v) => !!v || "Veuillez rentrer un mot de passe valide !",
      ],
      password: "",
      iconPassword: "mdi-eye",
      feedback: null,
      slug: null,
      user: null,
      checkbox: false,
      resetPswd: false,
      emailRecovery: "",
      loading1: false,
      iconRecovery: false,
      isRecovery: false,
      emailRecoverySent: false,
    };
  },
  computed: {
    sm: function () {
      return this.$vuetify.breakpoint.xs;
    },
    ...mapState(["user_data"]),
  },
  methods: {
    resetPassword() {
      this.loadingRecovery = true;
      this.feedback = null;

      var actionCodeSettings = {
        url: "https://academie.webmyday.io",
        handleCodeInApp: false,
      };

      firebase
        .auth()
        .sendPasswordResetEmail(this.email, actionCodeSettings)
        .then(() => {
          this.loadingRecovery = false;
          this.emailRecoverySent = true;
        })
        .catch((error) => {
          console.log(error);
          this.feedback = error;
          this.loadingRecovery = false;
        });
    },
    hideAndShow() {
      this.type = this.type === "password" ? "text" : "password";
      if (this.type === "text") {
        this.iconPassword = "mdi-eye-off";
      } else {
        this.iconPassword = "mdi-eye";
      }
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    loginWithGoogle(){

      let provider = new firebase.auth.GoogleAuthProvider();

        

        firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          var isNewUser = result.additionalUserInfo.isNewUser;
          let token = result.credential.accessToken;
          let user = result.user;
            console.log(token) // Token
            console.log(user) // User that was authenticated
            if(isNewUser){
                result.user.delete();
               this.feedback = `Aucun compte utilisateur ne correspond à l'adresse e-mail ${this.email}. Veuillez rééssayer ou vous rapprocher de l'équipe support@webmyday.io si le problème persiste 🤯`;
            }
            else {
            this.$store.dispatch("setUserData").then(() => {
                   setTimeout(() => {
              if (this.user_data.project.onboarding) {
                this.$router.push({ name: "welcome" });
              } else {
                this.$router.push({ name: "project-setup" });
              }
            }, 350);
            })
        
          }
        })
        .catch((err) => {
          console.log(err); // This will give you all the information needed to further debug any errors
        });
    },
    async login() {
      if (this.email && this.password) {
        this.feedback = null;
        this.loading = true;

        try {
          const user = firebase
            .auth()
            .signInWithEmailAndPassword(this.email, this.password);
          await user;
          if (user) {
            await this.$store.dispatch("setUserData");
            setTimeout(() => {
              if (this.user_data.project.onboarding) {
                this.$router.push({ name: "welcome" });
              } else {
                this.$router.push({ name: "project-setup" });
              }
            }, 350);
          }
        } catch (err) {
          console.log(err);
          if (err.code === "auth/wrong-password") {
            this.feedback =
              "Le mot de passe ne correspond pas à cette adresse e-mail.";
          } else if (err.code === "auth/user-not-found") {
            this.feedback = `Aucun compte utilisateur ne correspond à l'adresse e-mail ${this.email}. Veuillez rééssayer ou vous rapprocher de l'équipe support@webmyday.io si le problème persiste 🤯`;
          } else {
            this.feedback =
              "Connexion interrompue. Veuillez rééssayer ou vous rapprocher de l'équipe support@webmyday.io si le problème persiste 🤯";
          }

          // this.feedback =
          //   "Connexion interrompue. Veuillez rééssayer et vous rapprocher de l'équipe support@webmyday.io si le problème persiste 🤯";
          this.loading = false;
        }
      } else {
        this.feedback = "Merci de renseigner tous les champs";
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.title-card {
  text-align: center !important;
  margin-top: 10%;
  font-size: 50px;
  width: 75%;
  line-height: 1em;
}

@media only screen and (max-width: 938px) {
  .title-card {
    font-size: 21px;
  }
  .mobile-btn {
    max-width: 48% !important;
  }
  .v-content__wrap {
    overflow-x: hidden !important;
  }
  .home-title {
    font-size: 22px;
  }
  .v-main {
  padding: 0px !important;
}
.home-container {
  margin-top: -20px !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  height: 104% !important;
}
.home-row {
  align-content: start !important;
}

.main-col {
  height:100% !important;
}
}

.btn-test {
  background-color: #33495d !important;
  color: white !important;
}

.form-title {
  color: #33495d;
}

.mdi-eye::before {
  cursor: pointer;
}
</style>