var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user_data)?_c('div',{staticClass:"pa-12"},[_c('v-navigation-drawer',{staticClass:"elevation-0",attrs:{"light":"","app":"","color":"white","value":_vm.mainSidebar},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"px-2 pb-0"},[_c('v-btn',{attrs:{"block":"","color":"error","dark":"","loading":_vm.loading,"text":""},on:{"click":_vm.logout}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-exit-to-app")]),_vm._v(" Déconnexion ")],1)],1)]},proxy:true}],null,false,3163791916)},[_c('div',{staticClass:"container center-align pb-0 mb-0"},[_c('v-row',{staticClass:"my-0",attrs:{"justify":"center"}},[_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":"/logo-webmyday-black.webp"}})]),_c('v-row',{attrs:{"justify":"center"}},[_c('img',{staticStyle:{"max-width":"50px"},attrs:{"src":_vm.course.badge}})])],1),_c('div',{staticClass:"container center-align my-0 py-0"},[(_vm.$vuetify.breakpoint.xs)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","open-on-hover":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"dashboard-card black--text",staticStyle:{"background":"white"},attrs:{"small":""}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"size":"30","left":""}},[_c('v-img',{attrs:{"src":_vm.course.pic}})],1),_vm._v(" Formation "+_vm._s(_vm.course.name)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2282564253)},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item',{staticClass:"navbar-item",attrs:{"link":"","to":{ name: 'welcome' },"color":"#2962ff","active-class":"selected-menu","title":"Vers les autres formations"},on:{"mouseover":function($event){_vm.hover_1 = '#2962ff'},"mouseleave":function($event){_vm.hover_1 = ''}}},[_c('v-list-item-content',[_c('v-list-item-title',{style:({ color: _vm.hover_1 })},[_vm._v(" Vers les autres formations ")])],1),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.hover_1}},[_vm._v("mdi-chevron-right")])],1)],1)],1)],1):_vm._e()],1),_c('v-list',{staticClass:"pt-0 mt-0",attrs:{"nav":""}},[_c('v-subheader',{staticClass:"pl-2 black--text mt-0",staticStyle:{"font-size":"12px","font-weight":"700","text-transform":"uppercase","height":"38px"}},[_vm._v(" Les essentiels ")]),_c('v-list-item',{staticStyle:{"font-size":"14px"},attrs:{"link":"","to":{ name: 'dashboard', params: { course: _vm.$route.params.course } },"color":"blue accent-4"}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"height":"auto"}},[_vm._v("mdi-apps")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium",staticStyle:{"font-size":"14px"}},[_vm._v("Tableau de bord")])],1)],1),_c('v-list-item',{staticStyle:{"font-size":"14px"},attrs:{"link":"","to":{
          name: 'project-management',
          params: { course: _vm.$route.params.course },
        },"color":"blue accent-4","active-class":"current-link"}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"height":"auto"}},[_vm._v("mdi-format-list-checkbox")])],1),_c('v-list-item-content',{staticStyle:{"overflow":"visible !important"}},[_c('v-list-item-title',{staticClass:"font-weight-medium",staticStyle:{"font-size":"14px","overflow":"visible !important"}},[_c('v-badge',{staticClass:"mt-0",attrs:{"color":"error","value":_vm.tasksToDo.length + _vm.tasksDoing.length,"content":_vm.tasksToDo.length + _vm.tasksDoing.length,"offset-x":"-5","offset-y":"8"}},[_vm._v(" Tâches à réaliser ")])],1)],1)],1),_c('v-subheader',{staticClass:"pl-2 black--text",staticStyle:{"font-size":"12px","font-weight":"700","text-transform":"uppercase","height":"38px"}},[_vm._v(" Ma formation ")]),_c('v-list-item',{staticStyle:{"font-size":"14px"},attrs:{"link":"","to":{ name: 'academie', params: { course: _vm.$route.params.course } },"color":"blue accent-4"}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"height":"auto"}},[_vm._v("mdi-youtube")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium",staticStyle:{"font-size":"14px"}},[_vm._v("Vidéos de formation")])],1)],1),(_vm.quizzes.length > 0)?_c('v-list-item',{staticStyle:{"font-size":"14px"},attrs:{"link":"","to":{
          name: 'quizzes',
          params: { course: _vm.$route.params.course },
        },"color":"blue accent-4"}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"height":"auto"}},[_vm._v("mdi-help-box-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium",staticStyle:{"font-size":"14px"}},[_vm._v("Quizz")])],1)],1):_vm._e(),_c('v-list-item',{staticStyle:{"font-size":"14px"},attrs:{"link":"","to":{
          name: 'webinars',
          params: { course: _vm.$route.params.course },
        },"color":"blue accent-4"}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"height":"auto"}},[_vm._v("mdi-laptop-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium",staticStyle:{"font-size":"14px"}},[_vm._v("Cours collectifs")])],1)],1),_c('v-list-item',{staticStyle:{"font-size":"14px"},attrs:{"link":"","to":{
          name: 'ressources',
          params: { course: _vm.$route.params.course },
        },"color":"blue accent-4"}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"height":"auto"}},[_vm._v("mdi-book-open-page-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium",staticStyle:{"font-size":"14px"}},[_vm._v("Ressources utiles")])],1)],1),_c('v-subheader',{staticClass:"pl-2 black--text",staticStyle:{"font-size":"12px","font-weight":"700","text-transform":"uppercase","height":"38px"}},[_vm._v(" Aller plus loin ")]),_c('v-list-item',{staticStyle:{"font-size":"14px"},attrs:{"link":"","href":"https://communaute-webmyday.slack.com/","target":"_blank","color":"blue accent-4"}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"height":"auto"}},[_vm._v("mdi-chat-question-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium",staticStyle:{"font-size":"14px"}},[_vm._v(" La communauté ")])],1)],1),_c('v-list-item',{attrs:{"link":"","to":{
          name: 'partnerships',
          params: { course: _vm.$route.params.course },
        },"color":"blue accent-4"}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"height":"auto"}},[_vm._v("mdi-ticket-percent")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium",staticStyle:{"font-size":"14px"}},[_vm._v(" Bons plans ")])],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loadingOverlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }