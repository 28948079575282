import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

var firebaseConfig = {
    apiKey: "AIzaSyCkJb4j5Mw6DZXoKdzjW8Pw7ET6qfTjB3Y",
    authDomain: "webmyday-ba4d9.firebaseapp.com",
    databaseURL: "https://webmyday-ba4d9.firebaseio.com",
    projectId: "webmyday-ba4d9",
    storageBucket: "webmyday-ba4d9.appspot.com",
    messagingSenderId: "629894395567",
    appId: "1:629894395567:web:d092244496dc2f8e41c65a",
    measurementId: "G-K5HXFNZG9Z"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const db = firebase.firestore();
const auth = firebase.auth();

export {
    db,
    auth
}

// firebase.getCurrentUser = () => {
//     return new Promise((resolve, reject) => {
//         const unsubscribe = firebase.auth().onAuthStateChanged(user => {
//             unsubscribe();
//             resolve(user);
//         }, reject);
//     })
// };




