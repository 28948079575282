<template>
  <v-app-bar flat color="transparent">
    <v-icon x-large v-if="!pictureUrl">mdi-alpha-l-circle</v-icon>
    <v-img v-else :src="pictureUrl" max-width="60"></v-img>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-row class="pt-3" v-if="step < 7">
        <v-col>
          <v-skeleton-loader type="button"></v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader type="button"></v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader type="button"></v-skeleton-loader>
        </v-col>
        <v-col>
          <v-skeleton-loader type="button"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row v-else-if="step == 7">
        <v-list v-for="(page, index) in pages" :key="index">
          <v-list-item link class="font-weight-bold">{{ page }}</v-list-item>
        </v-list>
      </v-row>

      <v-row v-else>
        <v-list v-for="(page, index) in pages" :key="index">
          <v-list-item link @click="loadPage(page.slug)" v-if="page.showInMenu">
            <v-list-item-title
              :style="[
                page.slug == currentPage.slug
                  ? { color: currentPageColor }
                  : { color: pagesColor },
              ]"
              class="font-weight-bold body-2"
              >{{ page.name }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-row>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
export default {
  name: "firstMenu",
  props: [
    "pictureUrl",
    "pages",
    "step",
    "currentPage",
    "startLoading",
    "endLoading",
    "loadPage",
  ],
  data() {
    return {
      pagesColor: "rgba(0, 0, 0, 0.6)",
      currentPageColor: "#255cee",
    };
  },
};
</script>

<style>
</style>